import React from 'react'
import { BiPhone } from 'react-icons/bi';
import { MdOutlineEmail, MdOutlineSchool } from 'react-icons/md';

const EtablissementListItem = ({ etablissement }) => {

    return (

        <div
            className='col-sm-6 col-12 align-items-center p-2'
        >
            <div
                style={{ backgroundColor: 'white', boxShadow: '0 4px 8px rgba(118,118,120,0.2)' }} className='align-items-center gap-2 p-3'
            >
                <div className='col-sm-1 col-12 pe-sm-2'>
                    <div
                        className='d-flex justify-content-center align-items-center'
                        style={{
                            width: '55px',
                            height: '55px',
                            backgroundColor: 'rgba(15,20,25,0.3)',
                            color: 'white',
                            borderRadius: '50px',
                            boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2)'
                        }}
                    >
                        {/* <img
                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBmaWxsPSIjRjZGNkY5IiBkPSJNMCAwaDEyOHYxMjhIMHoiLz48cGF0aCBmaWxsPSIjQkRCREMwIiBkPSJNMTYgMTE2YzAtMTYuNTY5IDEzLjQzMS0zMCAzMC0zMGgzNmMxNi41NjkgMCAzMCAxMy40MzEgMzAgMzB2MjQuODM3YzAgMTYuNTY5LTEzLjQzMSAzMC0zMCAzMEg0NmMtMTYuNTY5IDAtMzAtMTMuNDMxLTMwLTMwVjExNlptNzYtNjRjMCAxNS40NjQtMTIuNTM2IDI4LTI4IDI4UzM2IDY3LjQ2NCAzNiA1MnMxMi41MzYtMjggMjgtMjggMjggMTIuNTM2IDI4IDI4WiIvPjwvc3ZnPgo="
                            alt="Candidat"
                            className=" w-100 rounded-circle"
                        /> */}
                        <MdOutlineSchool size={40} />
                    </div>
                </div>
                <div className='col-sm-10 col-12 ps-sm-0 mt-2'>
                    <h2
                        style={{ lineHeight: '26px', fontSize: '16px' }}
                        className='mb-1'>
                        {etablissement.user.name}
                        <span
                            style={{ borderRadius: '3px', backgroundColor: '#FF5D00', fontSize: '11px', lineHeight: '14px' }}
                            className='p-1 text-light ms-3'
                        >
                            {etablissement.raison_sociale}
                        </span></h2>
                    <p
                        style={{ color: '#717173', fontSize: '13px', lineHeight: '24px' }}
                        className='m-0'><BiPhone />
                        <span className='ms-1'>{etablissement.user.phone}</span>
                    </p>
                    <p
                        style={{ color: '#717173', fontSize: '13px', lineHeight: '24px' }}
                        className='fs-6 m-0'>
                        <MdOutlineEmail /><span className='ms-1'>
                            {etablissement.user.email}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EtablissementListItem