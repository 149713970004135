import { createSlice } from "@reduxjs/toolkit";

const projectReducer = createSlice({
    name: 'projects',
    initialState: { selectedProject: {} },
    reducers: {
        setSelectedProject: (state, { payload }) => {
            state.selectedProject = payload
        },
        resetselectedProject: (state) => {
            state.selectedProject = {}
        }
    }
})

export const { setSelectedProject, resetSelectedProject } = projectReducer.actions

export default projectReducer.reducer