import { CheckCircle } from "feather-icons-react/build/IconComponents";
import { useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineLocalOffer, MdOutlinePeopleOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import ButtonLoading from "../../../components/ButtonLoading";
import NavigateBack from "../../../components/shared/NavigateBack";
import { useUser } from "../../../hooks/useUser";
import { useUserId } from "../../../hooks/useUserId";
import { useGetEtudiantCvQuery } from "../../../redux/slices/etudiants/etudiantsSlice";
import {
    useApplyToOfferMutation,
    useGetAppliedOffresQuery,
} from "../../../redux/slices/offres/offresSlice";
import { dayJsFormat } from "../../../utils/dayJsFormat";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";
import CvModal from "../../Profile/CvModal";
const dayjs = require("dayjs");

dayJsFormat();

const DetailsOffreEtudiantPage = () => {
  // Initial States
  const [showCvModal, setShowCvModal] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [cvFile, setCvFile] = useState(null);

  const isAuthenticated = localStorage.getItem("access_token");

  const { selectedOffreCandidature } = useSelector(
    (state) => state.offresCandidatures
  );

  const [applyToOffer, { isLoading: isApplying }] = useApplyToOfferMutation();

  const { userId } = useUserId();

  const { data: appliedJobs, refetch: refetchOffres } =
    useGetAppliedOffresQuery(userId, {
      skip: !isAuthenticated,
    });

  const user = useUser();
  const {
    data: userCv,
    // isLoading: loadingCv,
    refetch,
  } = useGetEtudiantCvQuery(userId);

  const applyToJob = async (e) => {
    e.preventDefault();

    // Check if user has CV if not show modal
    userCv?.length === 0
      ? setShowCvModal(true)
      : await applyToOffer({
          user_id: userId,
          offre_id: selectedOffreCandidature.payload.id,
        })
          .unwrap()
          .then((res) => {
            res?.success === true && toastAlertSuccess(res?.message);
            refetchOffres();
          })
          .catch((err) => {
            toastAlertError(err.message);
          });
  };

  const statusCandidature = appliedJobs?.candidatures?.filter(
    (item) =>
      item?.offre_id === selectedOffreCandidature.payload.id &&
      item.user_id === userId
  ).length;

  function handleRefetchOne() {
    // force re-fetches the data
    refetch();
  }

  return (
    <div>
      <NavigateBack />

      <CvModal
        title="Veuillez d'abord choisir votre cv"
        cvFile={cvFile}
        setCvFile={setCvFile}
        cvId={userCv?.id}
        show={showCvModal}
        close={() => {
          setShowCvModal(false);
          setCvFile(null);
          setIsEditing(false);
        }}
        isEditing={isEditing}
        accessToken={isAuthenticated}
        user={user}
        refetch={() => handleRefetchOne()}
        setIsEditing={() => setIsEditing(false)}
      />

      <h3 className="mt-2 fs-4">Details Offre</h3>

      <div className="bg-white vh-100 p-2 p-sm-3 pt-4 shadow-sm">
        <div className="row">
          <div className="col-12">
            <h4 className="fs-5 fw-bold">
              {selectedOffreCandidature?.payload?.titre}
            </h4>
            <h6 className="fs-5 text-decoration-underline mt-2">
              Description de l'offre
            </h6>
            <p className="fs-6">
              {selectedOffreCandidature?.payload?.description}
            </p>
            <div className="d-flex gap-3 mt-3">
              <p>
                <MdOutlineLocalOffer />{" "}
                <span className="fs-6">
                  {selectedOffreCandidature?.payload?.offre_type}
                </span>
              </p>
              <p>
                <AiOutlineClockCircle />{" "}
                <span className="fs-6">
                  il y a{" "}
                  {dayjs(selectedOffreCandidature?.payload?.created_at).fromNow(
                    true
                  )}
                </span>
              </p>
              <p>
                <MdOutlinePeopleOutline />
                <span className="fs-6">
                  {selectedOffreCandidature?.payload?.candidats.length}{" "}
                  candidats
                </span>
              </p>
            </div>
            {user.type !== "etablissement" && (
              <div style={{ width: statusCandidature ? "250px" : "100px" }}>
                {statusCandidature ? (
                  <p className="text-success d-flex align-items-center gap-1">
                    <CheckCircle size={18} color="green" />
                    Déja postulé
                  </p>
                ) : (
                  <ButtonLoading
                    onClick={applyToJob}
                    loading={isApplying}
                    text="Postuler"
                    className="btn-apply"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsOffreEtudiantPage;
