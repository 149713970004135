import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Button from "../../components/shared/Button";
import InputForm from "../../components/shared/InputForm";
import TextArea from "../../components/shared/TextArea";
import TitleH2 from "../../components/shared/TitleH2";
import { LOGIN_PATH } from "../../constants/routes_path";
import logo from "../../image/logo.png";
import { useRegisterEtablissementMutation } from "../../redux/slices/authSlice/registerApiSlice";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";
import "./register.css";

const RegisterEtablissement = () => {
  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    email: Yup.string().required("Obligatoire"),
    phone: Yup.string().required("Obligatoire"),
    password: Yup.string().required("Obligatoire"),
    location: Yup.string().required("Obligatoire"),
    raison_sociale: Yup.string().required("Obligatoire"),
    description: Yup.string().required("Obligatoire"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm(formOptions);

  // Use register etudiant mutation
  const [registerEtablissement, { isLoading }] =
    useRegisterEtablissementMutation();

  const navigate = useNavigate();
  const location = useLocation();

  // Register function
  const handleRegister = (data) => {
    registerEtablissement(data)
      .unwrap()
      .then((res) => {
        navigate(LOGIN_PATH);
        toastAlertSuccess(res.message);
      })
      .catch((err) => {
        toastAlertError(err?.message);
      });
  };

  return (
    <div className="auth-form p-2">
      {/* <div> */}
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className="text-center mt-2">
          <img src={logo} alt="" className="taille-img mb-4" />
          <TitleH2>Inscription établissement</TitleH2>
        </div>
        {/* <div className="col-md-6"> */}
        <InputForm
          placeholder="Nom de l'établissement"
          type="text"
          register={{ ...register("name") }}
          htmlFor="name"
          // required
          error={errors.name?.message}
        />
        <InputForm
          placeholder="Email"
          type="email"
          register={{ ...register("email") }}
          htmlFor="email"
          // required
          error={errors.email?.message}
        />
        <InputForm
          placeholder="Téléphone"
          type="phone"
          register={{ ...register("phone") }}
          htmlFor="phone"
          // required
          error={errors.phone?.message}
        />

        <InputForm
          placeholder="Adresse"
          type="text"
          register={{ ...register("location") }}
          htmlFor="location"
          // required
          error={errors.location?.message}
        />
        <InputForm
          placeholder="Raison sociale"
          type="text"
          register={{ ...register("raison_sociale") }}
          htmlFor="raison_sociale"
          // required
          error={errors.raison_sociale?.message}
        />
        <InputForm
          placeholder="Mot de passe"
          type="password"
          register={{ ...register("password") }}
          htmlFor="password"
          // required
          error={errors.password?.message}
        />

        <TextArea
          minLength="10"
          maxLength="500"
          placeholder="Ecrivez quelque chose..."
          label="Description"
          htmlFor="description"
          register={{ ...register("description") }}
          error={errors.description?.message}
        />

        <div className=" mt-3">
          <Button
            type="submit"
            className="btn-add w-100 py-2"
            // disabled={email?.length === 0 || password?.length === 0}
          >
            {isLoading ? "Inscription en cours..." : "Valider"}
          </Button>
        </div>
        <p className="mt-3 text-center">
          Vous avez deja un compte{" "}
          <span
            onClick={() =>
              navigate(LOGIN_PATH, { state: { from: location?.state?.from } })
            }
            className="link"
          >
            connectez-vous ici
          </span>{" "}
        </p>
      </form>
    </div>
  );
};

export default RegisterEtablissement;
