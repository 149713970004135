import React from 'react'
import { styled } from 'styled-components'

const ProgressPane = styled.div`
  height: 5px;
  width: 130px;
  border-radius: 8px;
  --c:no-repeat linear-gradient(#000088 0 0);
  background: var(--c),var(--c),rgba(200, 245, 255, .5);
  background-size: 60% 100%;
  animation: l16 3s infinite;

@keyframes l16 {
  0%   {background-position:-150% 0,-150% 0}
  66%  {background-position: 250% 0,-150% 0}
  100% {background-position: 250% 0, 250% 0}
}
`

const ProgressLoading = ({ mt }) => {
  return (
    <ProgressPane className={`loader ${mt}`}></ProgressPane>
  )
}

export default ProgressLoading