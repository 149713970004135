import React, { useState } from "react";
import ReactPaginate from "react-paginate";

export default function PaginatedItems({
    itemsPerPage,
    ressourceData = [],
    children
}) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = ressourceData?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(ressourceData?.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % ressourceData.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            {
                React.Children.map(children, (child) => {
                    if (React.isValidElement) {
                        // !on recuperre currentItems au niveau du composant qui doit mapper
                        return React.cloneElement(child, { currentItems })
                    }
                    return child
                })
            }
            {
                ressourceData?.length > itemsPerPage ? <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                /> : null
            }

        </>
    );
}