import React from 'react'
import { dateFormatter } from '../utils/timeFormatters'
import { onError } from '../utils/onError';

const DernieresOffresCardItem = ({
    offre
}) => {
    const { titre,
        offre_type } = offre || { titre: '', offre_type: '' }
    return (
        <div style={{ boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2)' }} className="row flex-row card mb-4 bg-white rounded offresCard bg-white py-2 px-2">
            <div className="col-12 col-md-1 col-lg-12">
                <img
                    style={{ width: '65px', height: '65px' }}
                    className="card-img-lef " src={`${process.env.REACT_APP_BASE_URL}entreprisesLogos/${offre?.entreprise?.logo}`} alt=""
                    lazy
                    onError={onError}
                />
            </div>
            <div className="col-12 col-md-11 col-lg-12">
                <p style={{ fontSize: '18px' }} className="card-title">{titre}</p>
                <h2 className="card-text d-flex align-items-center fw-bold">{offre?.entreprise?.user?.name}</h2>
                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 mb-2">
                        <span className="fs-6">{`${offre_type ?? ''} | ${offre?.entreprise?.user?.location ?? ''} | Publiée le ${dateFormatter(offre?.created_at)}`}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DernieresOffresCardItem