import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import CustomModal from "../../components/CustomModal";
import { useAjoutExperienceEtudiantMutation } from "../../redux/slices/etudiants/etudiantsSlice";
import { yearMonthDayFormatter } from "../../utils/timeFormatters";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";
import ButtonLoading from "../../components/ButtonLoading";

const AddExperienceModal = ({ show, close, userId, refetch }) => {
  // Use the mutation
  const [addNewExperience] = useAjoutExperienceEtudiantMutation();
  const [startDate, setStartDate] = React.useState("");
  // const [endDate, setEndDate] = React.useState("");
  const currentDate = new Date();
  const [isAdding, setIsAdding] = useState(false)

  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    nom_entreprise: Yup.string().required("Obligatoire"),
    poste_occupe: Yup.string().required("Obligatoire"),
    date_debut: Yup.string().required("Obligatoire"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  // Function to reset the input field after submit
  const resetInputFields = () => {
    reset({
      name: "",
      nom_entreprise: "",
      poste_occupe: "",
      date_debut: "",
      date_fin: "",
      description: "",
    });
    setStartDate("");
    // setEndDate("");
  };

  const addExperience = (data) => {
    setIsAdding(true)
    addNewExperience(data)
      .unwrap()
      .then((res) => {
        close();
        resetInputFields();
        toastAlertSuccess(res.message);
        refetch();
      })
      .catch((err) => {
        toastAlertError(err.message);
      }).finally(() => {
        setIsAdding(false)
      })
  };

  return (
    <CustomModal
      modalTitle="Ajoutez une expérience"
      show={show}
      handleClose={() => {
        close();
        resetInputFields();
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          data.user_id = userId;
          addExperience(data);
        })}
      >
        <div className="mb-1">
          <label htmlFor="titrePoste" className="form-label">
            Titre du poste
          </label>
          <input
            type="text"
            className={`form-control ${errors.name && "is-invalid"}`}
            id="name"
            {...register("name")}
          />
        </div>

        <div className="mb-1">
          <label htmlFor="nomEntreprise" className="form-label">
            Nom de l'entreprise
          </label>
          <input
            type="text"
            className={`form-control ${errors.nom_entreprise && "is-invalid"}`}
            id="nomEntreprise"
            {...register("nom_entreprise")}
          />
        </div>

        <div className="mb-1">
          <label htmlFor="typeContrat" className="form-label">
            Type de contrat
          </label>
          {/* <input type="text" className="form-control" id="posteOccupe" /> */}
          <select
            className={`form-select ${errors.poste_occupe && "is-invalid"}`}
            aria-label="Default select example"
            id="posteOccupe"
            {...register("poste_occupe")}
          >
            <option value="" disabled selected hidden>
              Stage, CDD, CDI...
            </option>
            <option value="Stage">Stage</option>
            <option value="CDD">CDD</option>
            <option value="CDI">CDI</option>
          </select>
        </div>

        <div className="row mb-1">
          <div className="col">
            <label htmlFor="dateDebut" className="form-label">
              Date debut
            </label>
            <input
              type="date"
              className={`form-control ${errors.date_debut && "is-invalid"}`}
              id="dateDebut"
              max={yearMonthDayFormatter(currentDate)}
              {...register("date_debut", {
                onChange: (e) => setStartDate(e.target.value),
              })}
            />
          </div>
          <div className="col">
            <label htmlFor="dateFin" className="form-label">
              Date fin
            </label>
            <input
              type="date"
              className="form-control"
              id="dateFin"
              min={startDate}
              {...register("date_fin")}
              disabled={startDate === "" || (startDate === undefined && true)}
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            placeholder="Description de vos missions"
            id="description"
            {...register("description")}
          ></textarea>
        </div>
        <div className="text-end">
          {/* <button type="submit" className="btn btn-apply">
            Enregistrer et continuer
          </button> */}
          <ButtonLoading
            loading={isAdding}
            text={"Enregistrer"}
            className="btn-apply"
          />
        </div>
      </form>
    </CustomModal>
  );
};

export default AddExperienceModal;
