import { createSlice } from "@reduxjs/toolkit";

const certificationReducer = createSlice({
  name: "certifications",
  initialState: { selectedCertification: {} },
  reducers: {
    setSelectedCertification: (state, { payload }) => {
      state.selectedCertification = payload;
    },
    resetselectedCertification: (state) => {
      state.selectedCertification = {};
    },
  },
});

export const { setSelectedCertification, resetSelectedCertification } =
  certificationReducer.actions;

export default certificationReducer.reducer;
