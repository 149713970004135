import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ETUDIANT_HOME_PAGE_PATH,
  MY_PROFILE_PAGE,
} from "../constants/routes_path";
import CvModal from "../pages/Profile/CvModal";
import FadeTransition from "../pages/landingPage/components/Fadetransition";
import {
  useGetEtudiantCvQuery,
  useGetProfileCompletionRateQuery,
} from "../redux/slices/etudiants/etudiantsSlice";
import {
  useApplyToOfferMutation,
  useGetAppliedOffresQuery,
} from "../redux/slices/offres/offresSlice";
import { dateFormatter } from "../utils/timeFormatters";
import {
  toastAlertError,
  toastAlertInfo,
  toastAlertSuccess,
} from "../utils/toastAlerts";
import OffresCard from "./OffresCard";
import OffresCardEtablissement from "./OffresCardEtablissement";

const OffresResourceDataMap = ({ currentItems }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  // Cv File state
  const [cvFile, setCvFile] = useState(null);
  const [showCvModal, setShowCvModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const accessToken = localStorage.getItem("access_token");
  const [ShowWarningModal, setShowWarningModal] = useState(false);

  const ProfileIncomplete = (message) => (
    <div>
      <h5>{message}</h5>
      <Link
        className="btnCompleteProfile"
        to={ETUDIANT_HOME_PAGE_PATH + MY_PROFILE_PAGE}
      >
        Le Complétez ici
      </Link>{" "}
    </div>
  );

  const {
    data: userCv,
    // isLoading: loadingCv,
    refetch,
  } = useGetEtudiantCvQuery(user?.id);

  const { data: completionRate } = useGetProfileCompletionRateQuery(user?.id);

  // Apply to offer mutation
  const [applyToOffer, { isLoading: isApplying }] = useApplyToOfferMutation();
  const [currentElement, setCurrentElement] = useState(null);
  const isAuthenticated = localStorage.getItem("access_token");

  function handleRefetchOne() {
    // force re-fetches the data
    refetch();
  }

  // Async function for the apply offer mutation
  const applyToJob = async (e, user_id, offre_id, entreprise_id) => {
    e.preventDefault();
    setCurrentElement(offre_id);

    await applyToOffer({
      user_id: user_id,
      offre_id: offre_id,
      entreprise_id,
    })
      .unwrap()
      .then((res) => {
        res?.status === 200 && toastAlertSuccess(res?.message);
        res?.status === 201 &&
          toast.warning(ProfileIncomplete(res?.message), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
          });
      })
      .catch((err) => {
        toastAlertError(err.message);
      });
  };

  //   Authenticated user applied offers query
  const { data: appliedJobs } = useGetAppliedOffresQuery(user?.id, {
    skip: !isAuthenticated,
  });

  return (
    <FadeTransition in={true}>
      <CvModal
        title="Veuillez d'abord choisir votre cv"
        cvFile={cvFile}
        setCvFile={setCvFile}
        cvId={userCv?.id}
        show={showCvModal}
        close={() => {
          setShowCvModal(false);
          setCvFile(null);
          setIsEditing(false);
        }}
        isEditing={isEditing}
        accessToken={accessToken}
        user={user}
        refetch={() => handleRefetchOne()}
        setIsEditing={() => setIsEditing(false)}
      />
      {currentItems?.map((offre, index) =>
        user.type === "etudiant" ? (
          <OffresCard
            id={offre.id}
            key={index}
            titre={offre?.titre}
            type={offre.offre_type}
            description={offre.description}
            created_at={offre.created_at}
            image={`${process.env.REACT_APP_BASE_URL}entreprisesLogos/${offre?.entreprise?.logo}`}
            entreprise={offre?.entreprise?.user?.name}
            date_expiration={offre?.date_expiration}
            location={offre?.entreprise?.user?.location}
            // datePublication={dateFormatter(offre?.created_at)}
            applyFunction={(e) => {
              e.preventDefault();
              e.stopPropagation();
              !isAuthenticated
                ? toastAlertInfo("Veuillez vous connectez d'abbord.")
                : applyToJob(e, user?.id, offre?.id, offre.entreprise_id);
            }}
            isLoading={currentElement === offre.id && isApplying}
            statusCandidature={appliedJobs?.candidatures?.map(
              (item) => item?.offre_id === offre?.id && item?.status_candidature
            )}
            isAbleToApply={
              appliedJobs?.candidatures?.some(
                (item) => item?.offre_id === offre?.id
              )
                ? false
                : user.type === "etablissement" || user.type === "entreprise"
                ? false
                : true
            }
            isNotEtudiant={
              user.type === "etablissement" || user.type === "entreprise"
                ? true
                : false
            }
          />
        ) : (
          // </Link>
          <OffresCardEtablissement
            key={index}
            titre={offre?.titre}
            image={`${process.env.REACT_APP_BASE_URL}entreprisesLogos/${offre?.entreprise?.logo}`}
            entreprise={offre?.entreprise?.user?.name}
            type={offre.offre_type}
            location={offre?.entreprise?.user?.location}
            datePublication={dateFormatter(offre?.created_at)}
            isNotEtudiant={
              user.type === "etablissement" || user.type === "entreprise"
                ? true
                : false
            }
          />
        )
      )}
    </FadeTransition>
  );
};

export default OffresResourceDataMap;
