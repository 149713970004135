import React from "react";
import LandingBtn from "./LandingBtn";

const TabItemImgLeft = ({
  title,
  paragrapheText,
  btnText,
  imgSrc,
  alt,
  onClick,
  avantage1,
  avantage2,
  avantage3,
  avantage4,
  avantage_titre,
}) => {
  return (
    <div className="row">
      <div className="col-md-6 col-12">
        <img src={imgSrc} alt={alt} className="img-fluid rounded" />
      </div>
      <div className="col-md-6 col-12 d-flex flex-column justify-content-between mt-3 mt-sm-0">
        <div>
          <h4 className="tab_item_title">{title}</h4>
          <p className="tab_item_text">{paragrapheText}</p>
         
        </div>
          <h4 className="tab_item_title">{avantage_titre}</h4>
            <ul className="advantage">
        <li class="">{avantage1} </li>
        <li> {avantage2}</li>
        <li>{avantage3}</li>
        <li>{avantage4}</li>
      </ul>
        <div>
          <LandingBtn className="bg-color-green rounded" onClick={onClick}>
            {btnText}
          </LandingBtn>
        </div>
      </div>
    </div>
  );
};

export default TabItemImgLeft;
