import {
    ADD_EXPERIENCE_API_ROUTE,
    DELETE_CV_API_ROUTE,
    DELETE_EXPERIENCE_API_ROUTE,
    EDIT_EXPERIENCE_API_ROUTE,
    EDIT_USER_INFOS_API_ROUTE,
    GET_ETUDIANT_CV_API_ROUTE,
    GET_ETUDIANT_EXPERIENCES_API_ROUTE,
    GET_PROFILE_COMPLETION_RATE,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const etudiantsSlice = apiSlice.injectEndpoints({
  tagTypes: ["Etudiants"],
  endpoints: (builder) => ({
    getEtudiantCv: builder.query({
      query: (user_id) => `${GET_ETUDIANT_CV_API_ROUTE}${user_id}`,
      transformResponse: (response) => response.data,
    }),

    loadCv: builder.query({
      query: (fileName) => ({
        url: `${process.env.REACT_APP_BASE_URL}resume/${fileName}`,
        method: "GET",
        credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }),
    }),

    deleteEtudiantCv: builder.mutation({
      query: (cv_id) => ({
        url: `${DELETE_CV_API_ROUTE}${cv_id}`,
        method: "POST",
      }),
    }),

    ajoutExperienceEtudiant: builder.mutation({
      query: (payload) => ({
        url: ADD_EXPERIENCE_API_ROUTE,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),

    modifierExperienceEtudiant: builder.mutation({
      query: (args) => {
        const { experience_id, payload } = args;
        return {
          url: `${EDIT_EXPERIENCE_API_ROUTE}${experience_id}`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),

    supprimerExperienceEtudiant: builder.mutation({
      query: (experience_id) => ({
        url: `${DELETE_EXPERIENCE_API_ROUTE}${experience_id}`,
        method: "DELETE",
      }),
    }),

    getEtudiantExperiences: builder.query({
      query: (user_id) => `${GET_ETUDIANT_EXPERIENCES_API_ROUTE}${user_id}`,
    }),

    editUserInfos: builder.mutation({
      query: (args) => {
        const { user_id, payload } = args;
        return {
          url: `${EDIT_USER_INFOS_API_ROUTE}${user_id}`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Etudiants"],
    }),

    getProfileCompletionRate: builder.query({
      query: (user_id) => `${GET_PROFILE_COMPLETION_RATE}${user_id}`,
    }),
  }),
});

export const {
  useGetEtudiantCvQuery,
  useLazyLoadCvQuery,
  useDeleteEtudiantCvMutation,
  useAjoutExperienceEtudiantMutation,
  useModifierExperienceEtudiantMutation,
  useSupprimerExperienceEtudiantMutation,
  useGetEtudiantExperiencesQuery,
  useEditUserInfosMutation,
  useGetProfileCompletionRateQuery,
} = etudiantsSlice;
