import React, { cloneElement, useEffect } from 'react'
import SectionH2 from '../components/SectionH2';
import { GridList } from 'react-flexible-list';
import OffreCardItem from '../components/OffreCardItem';
import Section from '../components/Section';
import LandingPageContainer from '../components/LandingPageContainer';
import { Link, useNavigate } from 'react-router-dom';
import { LANDINGPAGE_OFFRES_PATH } from '../../../constants/routes_path';
import { useGetLatestOffresQuery } from '../../../redux/slices/offres/offresSlice';
import { useDispatch } from 'react-redux';
import { resetOffreId, setOffreId } from '../../../redux/reducers/offreReducer';
import FadeTransition from '../components/Fadetransition';
import LoaderSpinner from '../../../components/LoaderSpinner';

const OffresSection = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { data = { count: 0, data: [] }, isLoading } = useGetLatestOffresQuery()
    // const { data: offres } = useGetAllOffresQuery();

    const navigateToDetails = ({ offre_id }) => {
        dispatch(setOffreId(offre_id))
        return navigate(LANDINGPAGE_OFFRES_PATH + '/' + offre_id)
    }

    useEffect(() => {
        dispatch(resetOffreId())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ClonedOffreCardItem = ({ offre }) => cloneElement(<OffreCardItem offre={offre} />, {
        navigateToDetails
    })

 

    return (
        <Section>
            <FadeTransition in={true}>
            <LandingPageContainer maxWidth={1300}>
                {/* <button onClick={() => refetch()}>refetch</button> */}
                    <SectionH2>Les derniéres offres sur le marché</SectionH2>
                    {
                        isLoading ? <LoaderSpinner /> : <GridList
                            resourceData={data.data}
                            resourceItem={ClonedOffreCardItem}
                            resourceName="offre"
                            cardWidth={300}
                            emptyComponent={() => <span>Pas d'offres pour le moment</span>}
                        />
                    }
                <Link style={{
                    textDecoration: 'underline',
                    display: 'block',
                    marginTop: '20px',
                    color: 'blueviolet'
                    }} to={LANDINGPAGE_OFFRES_PATH}>Voir plus</Link>
            </LandingPageContainer>
            </FadeTransition>
        </Section>
    )
}

export default OffresSection