import dayjs from "dayjs";
import "dayjs/locale/fr";

export const dateFormatter = (date) => {
  dayjs.locale("fr");
  return dayjs(date).format("DD/MM/YYYY");
};

export const monthYearFormatter = (date) => {
  dayjs.locale("fr");
  return dayjs(date).format("MMMM YYYY");
};

export const yearMonthDayFormatter = (date) => {
  dayjs.locale("fr");
  return dayjs(date).format("YYYY-MM-DD");
};

export const today = () => {
  dayjs.locale("fr");
  return dayjs().format("YYYY-MM-DD");
};
