import React, { useEffect, useReducer } from "react";
import { FaGraduationCap } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoShieldCheckmark } from "react-icons/io5";
import Select from "react-select";
import useQueryParams from "../../../hooks/useQueryParams";

const ProfilesFilters = () => {
  // QueryParams hooks
  const { queryParams, getCurrentParams, setQueryParams } = useQueryParams();

  // Selects options
  const niveauOptions = [
    { value: "bac", label: "Bac" },
    { value: "licence 1", label: "Licence 1" },
    { value: "licence 2", label: "Licence 2" },
    { value: "licence 3", label: "Licence 3" },
    { value: "master 1", label: "Master 1" },
    { value: "master 2", label: "Master 2" },
  ];
  const experienceOptions = [
    { value: "0,1", label: "0 à 1 an" },
    { value: "1,3", label: "1 à 3 ans" },
    { value: "3,5", label: "3 à 5 ans" },
  ];
  const profileCompletionOptions = [
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "100", label: "100%" },
  ];

  // Initial states
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      niveau:
        niveauOptions?.find(
          (option) => option?.value === queryParams.get("niveau_etude")
        ) || null,

      experience:
        experienceOptions?.find(
          (option) =>
            option?.value === queryParams.get("profile_completion_rate")
        ) || null,

      completionRate:
        profileCompletionOptions?.find(
          (option) =>
            option?.value === queryParams.get("profile_completion_rate")
        ) || null,
      hasCv: queryParams.get("has_cv") || false,
    }
  );

  useEffect(() => {
    setQueryParams({
      ...currentParams,
      has_cv: state?.hasCv,
    });
  }, [state?.hasCv]);

  const currentParams = getCurrentParams();

  // Hadle filter
  const handleFilter = (e, filter) => {
    if (filter === 1) {
      setQueryParams({
        ...currentParams,
        titre_offre: e ? e.value : "",
      });
    } else if (filter === 2) {
      setQueryParams({
        ...currentParams,
        niveau_etude: e ? e.value : "",
      });
    } else if (filter === 3) {
      setQueryParams({
        ...currentParams,
        nb_an_experiences: e ? e.value : "",
      });
    } else if (filter === 4) {
      setQueryParams({
        ...currentParams,
        profile_completion_rate: e ? e.value : "",
      });
    } else if (filter === 5) {
      setState({ hasCv: !state?.hasCv });
      //   setQueryParams({
      //     ...currentParams,
      //     has_cv: state?.hasCv,
      //   });
    }
  };

  // Reset filters
  const reset = () => {
    setState({
      offreName: null,
      niveau: null,
      experience: null,
      completionRate: null,
      hasCv: false,
    });
    setQueryParams();
  };

  return (
    <>
      <div className="row py-0">
        <div className="col-12 col-xl col-lg col-md col-sm my-1 my-xl-0 my-lg-0 my-md-0 my-sm-0 d-flex align-items-center ">
          <FaGraduationCap className="mx-1 search_filter_icon" icon="search" />
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
                height: "50px",
              }),
            }}
            options={niveauOptions}
            onChange={(e) => {
              setState({ niveau: e });
              handleFilter(e, 2);
            }}
            value={state?.niveau}
            className="w-100 border-none"
            isSearchable
            noOptionsMessage={() => "Pas d'options"}
            placeholder="Filtrer par niveau d'étude"
            isClearable
          />
        </div>

        <div className="col-12 col-xl col-lg col-md col-sm my-1 my-xl-0 my-lg-0 my-md-0 my-sm-0 d-flex align-items-center">
          <IoShieldCheckmark
            className="mx-1 search_filter_icon"
            icon="search"
          />
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
                height: "50px",
              }),
            }}
            options={experienceOptions}
            onChange={(e) => {
              setState({ experience: e });
              handleFilter(e, 3);
            }}
            value={state?.experience}
            className="w-100 border-none"
            isSearchable
            noOptionsMessage={() => "Pas d'options"}
            placeholder="Filtrer par expérience"
            isClearable
          />
        </div>

        <div className="col-12 col-xl col-lg col-md col-sm my-1 my-xl-0 my-lg-0 my-md-0 my-sm-0 d-flex align-items-center">
          <IoShieldCheckmark
            className="mx-1 search_filter_icon"
            icon="search"
          />
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
                height: "50px",
              }),
            }}
            options={profileCompletionOptions}
            onChange={(e) => {
              setState({ completionRate: e });
              handleFilter(e, 4);
            }}
            value={state?.completionRate}
            className="w-100 border-none"
            isSearchable
            noOptionsMessage={() => "Pas d'options"}
            placeholder="Taux de complétion profil"
            isClearable
          />
        </div>
      </div>

      <div className="row py-0 mt-2">
        <div className="col d-flex align-items-center justify-content-between">
          {/* <button
            className="btn btn-import fs-6 border-0"
            onClick={() => setState({ show: true })}
          >
            Tout les filtres <HiOutlineFilter className="mx-1" size={18} />
          </button> */}
          <div className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name=""
              id="hasCv"
              checked={state?.hasCv}
              onChange={(e) => handleFilter(e, 5)}
            />
            <label htmlFor="hasCv" className="pointer-event">
              Profiles avec CV
            </label>
          </div>
          <button className="btn btn-red fs-6 border-0 mt-2 " onClick={reset}>
            <FaRegTrashCan className="me-1" size={18} />
            Effacer filtres
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfilesFilters;
