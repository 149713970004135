import React, { useContext } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { TabContext } from "../context";

export default function TabsNavbar({ items, style, navItemStyle }) {
    const { activePanel, switchPanel } = useContext(TabContext);

    return (
        <S.Nav style={style}>
            {items.map((item, index) => (
                <S.Button
                    key={String(index)}
                    isActive={activePanel === item}
                    style={navItemStyle}
                    onClick={() => switchPanel(item)}
                >
                    {item}
                </S.Button>
            ))}
        </S.Nav>
    );
}

TabsNavbar.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    style: PropTypes.objectOf(PropTypes.string),
    navItemStyle: PropTypes.objectOf(PropTypes.string)
};
