import React from "react";

const TitleH2 = ({ children }) => {
  return (
    <h2 style={{ fontSize: "18px", fontWeight: 700 }} className="m-0">
      {children}
    </h2>
  );
};

export default TitleH2;
