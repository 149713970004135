import React from 'react'
// import { useForm } from 'react-hook-form';

const TextArea = ({ register, label, htmlFor, required, error, ...props }) => {
    // const { register, formState: errors } = useForm();
    return (
        <div className="mb-1">
            <label htmlFor={htmlFor} className="form-label">
                {label}
            </label>
            <textarea
                rows={10}
                className={`form-control ${error && "is-invalid"}`}
                id={htmlFor}
                {...register}
                required={required}
                {...props}
            ></textarea>
            {
                error && <span className='text-danger fs-6'>{error}</span>
            }
        </div>
    )
}

export default TextArea