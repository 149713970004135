import React, { cloneElement } from "react";
import { Badge } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SearchFilter from "../../../components/SearchFilter";
import ResourceDataMapperWithEmptyMessage from "../../../components/shared/ResourceDataMapperWithEmptyMessage";
import TitleH2 from "../../../components/shared/TitleH2";
import {
  DETAILS_OFFRES_CANDIDATURE_PATH,
  EDIT_OFFRE_PATH,
} from "../../../constants/routes_path";
import { useEntreprise } from "../../../hooks/useEntreprise";
import useQueryParams from "../../../hooks/useQueryParams";
import { setSelectedOffre } from "../../../redux/reducers/offreReducer";
import { setSelectedOffreCandidature } from "../../../redux/reducers/offresCandidature";
import { useGetOffresByEntrepriseQuery } from "../../../redux/slices/entreprises/entreprisesSlice";
import DotLoader from "../../../utils/DotLoader";
import EntrepriseOffreCard from "../components/EntrepriseOffreCard";

const MesOffresListe = () => {
  const { entreprise } = useEntreprise();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchItem, setSearchItem] = React.useState("");

  const { queryParams } = useQueryParams();

  // Convert URLSearchParams object to a plain object
  const paramsObject = {};
  for (const [key, value] of queryParams.entries()) {
    paramsObject[key] = value;
  }
  const {
    data: offres = { count: 0, data: [] },
    isLoading,
    isFetching,
  } = useGetOffresByEntrepriseQuery({
    id_entreprise: entreprise?.id,
    params: paramsObject,
  });

  const navigateToCandidatureDetails = (offre) => {
    dispatch(setSelectedOffreCandidature(offre));
    // !redirection
    navigate(`${offre?.id}/details`, {
      state: { offreId: offre?.id },
    });
  };

  const editOffre = (offre) => {
    dispatch(setSelectedOffre(offre));
    return navigate(EDIT_OFFRE_PATH);
  };

  /**
   *! on clone EntrepriseOffreCard pour pouvoir lui passer une props supplementaire : navigateToCandidatureDetails
   */
  const ClonedEntrepriseOffreCard = ({ offre }) =>
    cloneElement(<EntrepriseOffreCard offre={offre} />, {
      navigateToCandidatureDetails,
      editOffre,
    });

  return (
    <div className="">
      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex align-items-center gap-2">
          <TitleH2>Offres</TitleH2>
          {isLoading || isFetching ? (
            <span className="ms-4">
              <DotLoader />
            </span>
          ) : (
            <Badge>{offres?.count}</Badge>
          )}
        </div>

        <Link className="btn bg-gradient-dark text-light fw-bold" to={"add"}>
          <MdAdd /> Ajouter
        </Link>
      </div>
      <div className="my-3">
        <SearchFilter simpleFilter={false} entrepriseOffreFilters />
      </div>
      {searchItem && (
        <div className="mb-3">
          {offres.count ?? "0"} résultats trouvés pour{" "}
          <span className="fw-bold text-succes">{searchItem}</span>
        </div>
      )}
      <div className="row">
        <ResourceDataMapperWithEmptyMessage
          isLoading={isLoading || isFetching}
          resourceItem={ClonedEntrepriseOffreCard}
          resourceName="offre"
          resourceData={offres.data}
          emptyMessage="Pas encore d'offres"
        />
      </div>
    </div>
  );
};

export default MesOffresListe;
