import React, { useEffect, useState } from "react";
import { RiMenu3Fill } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import {
  DEFAULT_PATH,
  ETABLISSEMENT_PATH,
  LANDINGPAGE_ENTREPRISES_PATH,
  LANDINGPAGE_EVENEMENTS_PATH,
  LANDINGPAGE_OFFRES_PATH,
  LANDING_PAGE_ENTREPRISE,
  LOGIN_PATH,
  REGISTER_ETUDIANT_PATH,
} from "../../../constants/routes_path";
import LandingBtn from "./LandingBtn";

const Header = () => {
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  const navigate = useNavigate();
  const links = [
    {
      to: "",
      label: "Accueil",
    },
    {
      to: LANDINGPAGE_OFFRES_PATH,
      label: "Offres",
    },
    // {
    //     to: LANDING_PAGE_ENTREPRISE,
    //     label: 'Entreprise'
    // },
    {
      to: LANDINGPAGE_ENTREPRISES_PATH,
      label: "Entreprises",
    },
    {
      to: ETABLISSEMENT_PATH,
      label: "Etablissements",
    },
    // {
    //   to: LANDINGPAGE_EVENEMENTS_PATH,
    //   label: "Événements",
    // },
  ];

  return (
    <div className="header_container">
      <div className="landing_page_container">
        <nav className={`navbar navbar-expand-lg p-0' ${stickyClass}`}>
          <div className="logo-pane navbar-brand" onClick={() => navigate("/")}>
            <span className="logo-green">Bakeli</span>{" "}
            <span className="logo-orange">Carriere</span>
          </div>
          <button
            style={{ border: "none !important" }}
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <RiMenu3Fill />
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="nav_items ms-auto d-flex flex-column flex-lg-row gap-lg-4 gap-2 pe-4">
              {links.map((link) => {
                return (
                  <li className="nav_item">
                    <NavLink className="nav_link" to={link.to}>
                      {link.label}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <LandingBtn
              onClick={() => navigate(REGISTER_ETUDIANT_PATH)}
              className="bg-color-green rounded ms-lg-2 ms-0 mt-3 mt-lg-0 py-1 fs-5"
            >
              S'inscrire
            </LandingBtn>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
