import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ETUDIANT_HOME_PAGE_PATH } from "../../constants/routes_path";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";

const GoogleCallback = () => {
  // STATES
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const initialized = useRef(false);

  const redirectFunction = (data) => {
    if (data?.type === "etudiant") {
      return navigate(ETUDIANT_HOME_PAGE_PATH);
    }
  };

  // On page load, we take "search" parameters
  // and proxy them to /api/auth/callback on our Laravel API
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${process.env.REACT_APP_API_URL}auth/callback${location.search}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setLoading(false);
          localStorage.setItem("access_token", data?.token);
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...data?.googleUser,
            })
          );
          redirectFunction(data?.googleUser);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      {loading && <SpinnerLoaderSearch />}
    </div>
  );
};

export default GoogleCallback;
