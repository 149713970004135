import {
  CANDIDATURES_ROUTE,
  CANDIDATURE_BY_ID_ROUTE,
  CANDIDATURE_SPONTANEE_ROUTE,
  CONVOQUER_CANDIDAT_ROUTE,
  REJECT_CANDIDATURE_ROUTE,
  VALIDATE_CANDIDATURE_ROUTE,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const candidatureSlice = apiSlice.injectEndpoints({
  // tagTypes: ["Offres"],
  endpoints: (builder) => ({
    validate: builder.mutation({
      query: (id) => {
        return {
          url: VALIDATE_CANDIDATURE_ROUTE + id,
          // body: data,
          method: "POST",
        };
      },
      invalidatesTags: ["Candidatures"],
    }),
    reject: builder.mutation({
      query: (id) => {
        return {
          url: REJECT_CANDIDATURE_ROUTE + id,
          // body: data,
          method: "POST",
        };
      },
      invalidatesTags: ["Candidatures"],
    }),
    convoquer: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: CONVOQUER_CANDIDAT_ROUTE + id,
          body: data,
          method: "POST",
        };
      },
      invalidatesTags: ["Candidatures"],
    }),
    candidatureSpontanee: builder.mutation({
      query: ({ motivation, entreprise_id, user_id, domaine_id }) => {
        return {
          url:
            CANDIDATURE_SPONTANEE_ROUTE +
            user_id +
            "/" +
            domaine_id +
            "/" +
            entreprise_id,
          body: { motivation },
          method: "POST",
        };
      },
      invalidatesTags: ["Candidatures"],
    }),
    getByEntreprise: builder.query({
      query: ({ id_entreprise, params }) => {
        return {
          url: `${CANDIDATURES_ROUTE}${id_entreprise}`,
          method: "GET",
          params: params,
        };
      },
      providesTags: ["Candidatures"],
    }),
    getCandidatureById: builder.query({
      query: ({ id_candidature }) => {
        return `${CANDIDATURE_BY_ID_ROUTE}${id_candidature}/`;
      },
      // transformResponse: (response) => {
      //     const transform = response.data
      //     return transform
      // },
      providesTags: ["Candidatures"],
    }),
  }),
});

export const {
  useValidateMutation,
  useRejectMutation,
  useConvoquerMutation,
  useGetByEntrepriseQuery,
  useGetCandidatureByIdQuery,
  useCandidatureSpontaneeMutation,
} = candidatureSlice;
