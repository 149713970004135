import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../../../components/ButtonLoading";
import Input from "../../../components/shared/Input";
import NavigateBack from "../../../components/shared/NavigateBack";
import Select from "../../../components/shared/Select";
import { useEntreprise } from "../../../hooks/useEntreprise";
import {
  useDeleteOffreByEntrepriseMutation,
  useEditOffreByEntrepriseMutation,
} from "../../../redux/slices/offres/offresSlice";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";

const EditOffrePage = () => {
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { selectedOffre } = useSelector((state) => state.offres);
  const [description, setDescription] = useState(selectedOffre.description);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      titre: selectedOffre.titre,
      date_expiration: selectedOffre.date_expiration,
      offre_type: selectedOffre.offre_type,
    },
    values: {
      titre: selectedOffre.titre,
      offre_type: selectedOffre.offre_type,
    },
  });

  const { entreprise } = useEntreprise();
  const [editOffreByEntreprise] = useEditOffreByEntrepriseMutation();
  const [supprimerOffreEntreprise] = useDeleteOffreByEntrepriseMutation();

  const addOffre = (data) => {
    // const newData = { ...data, entreprise_id: entreprise.id }
    if (description === "") {
      return setError("description", {
        type: "custom",
        message: "Veuillez saisir la description",
      });
    }
    if (description.length < 50) {
      return setError("description", {
        type: "custom",
        message: "Saisissez au moins 50 caractères",
      });
    }
    if (new Date(data.date_expiration) < new Date()) {
      return setError("date_expiration", {
        type: "custom",
        message: "Veuillez saisir une date supérieur à aujourd'hui",
      });
    }
    setLoading(true);
    editOffreByEntreprise({
      data: { ...data, description, entreprise_id: entreprise.id },
      offre_id: selectedOffre.id,
    })
      .unwrap()
      .then((res) => {
        if (res.success) {
          toastAlertSuccess("Offre modifiée");
          navigate(-1);
          return;
        }
      })
      .catch((err) => {
        toastAlertError(err.message);
      })
      .finally(() => {
        // reset()
        setLoading(false);
      });
  };

  const selectOptions = useMemo(() => {
    return [
      { value: "stage", label: "Stage" },
      { value: "cdi", label: "CDI" },
      { value: "cdd", label: "CDD" },
      { value: "", label: "Selectionner le type d'offre" },
    ];
  }, []);

  const supprimerEntrepriseFunc = () => {
    setIsDeleting(true);
    supprimerOffreEntreprise({ offre_id: selectedOffre.id })
      .then((res) => {
        if (res.data.success) {
          toastAlertSuccess("Offre supprimée");
          navigate(-1);
          return;
        }
        toastAlertError("Une erreur est survenue");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <div
      // style={{ maxWidth: '800px' }}
      className="mx-auto"
    >
      {/* label, register, htmlFor, required, type, error, ...props */}
      <NavigateBack />
      <h2 className="text-center fs-4">Mofifiez l'offre</h2>
      <form onSubmit={handleSubmit(addOffre)}>
        <Input
          type="text"
          label="Titre"
          register={{ ...register("titre") }}
          htmlFor="titre"
          required
          error={errors?.titre?.message}
        />
        <Input
          type="date"
          label="Date d'expiration"
          min={new Date()}
          register={{
            ...register("date_expiration", {
              required: "Veuillez saisir la date date d'expiration",
            }),
          }}
          htmlFor="date_expiration"
          required
          error={errors?.date_expiration?.message}
        />

        <Select
          label="Type d'offre"
          register={{ ...register("offre_type") }}
          selectOptions={selectOptions}
        ></Select>
        <div className="mt-3">
          <span className="fs-6">Description</span>
          {errors?.description && (
            <span className="text-danger fs-6 ps-2">
              {errors.description.message}
            </span>
          )}
          <ReactQuill
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code-block"],

                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                // [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ direction: "rtl" }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["link", "image", "video", "formula"],

                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],

                ["clean"],
              ],
            }}
            theme="snow"
            onChange={setDescription}
            value={description}
          />
        </div>
        {/* type, className, onClick, isLoading */}
        <div className="d-flex gap-2">
          <ButtonLoading
            loading={loading}
            text="Modifier"
            className="btn-apply w-100 mt-3"
          />
          {/* <Button
                    type="submit"
                    className="w-100 btn btn-success mt-3 py-2"
                    isLoading={loading}
                >
                    Modifier
                </Button> */}
          <ButtonLoading
            onClick={supprimerEntrepriseFunc}
            type="button"
            className="btn-danger w-100 mt-3"
            loading={isDeleting}
            text="Supprimer"
          />
        </div>
      </form>
    </div>
  );
};

export default EditOffrePage;
