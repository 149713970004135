import React from "react";

const CandidatureCardItem = ({ text, number }) => {
  // 009688
  return (
    <div
      style={{
        border: "2px solid transparent",
        borderRight: "5px solid #ff9800",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
      }}
      className="card mb-"
    >
      <div className="card-body d-flex justify-content-between align-items-center">
        <p className="m-0 card-text">
          <span className="fw-bold">{text}</span>
        </p>
        <p className="m-0 mb-1 fw-bold fs-4" style={{ color: "#ff9800" }}>
          {number ?? "..."}
        </p>
      </div>
    </div>
  );
};

export default CandidatureCardItem;
