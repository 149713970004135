import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import ButtonLoading from '../../../components/ButtonLoading';
import Input from '../../../components/shared/Input';
import NavigateBack from '../../../components/shared/NavigateBack';
import Select from '../../../components/shared/Select';
import { ENTREPRISE_HOME_PAGE_PATH, ENTREPRISE_MES_OFFRES_PATH } from '../../../constants/routes_path';
import { useEntreprise } from '../../../hooks/useEntreprise';
import { useAddOffreByEntrepriseMutation } from '../../../redux/slices/offres/offresSlice';
import { toastAlertError, toastAlertSuccess } from '../../../utils/toastAlerts';

// ! Ajouter une offre cote entreprise
const AjoutOffre = () => {
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState("")
    const navigate = useNavigate()
    const { register, handleSubmit, reset, setError, formState: { errors } } = useForm();
    const { entreprise } = useEntreprise()
    const [addOffreByEntreprise] = useAddOffreByEntrepriseMutation()

    const addOffre = (data) => {
        if (description === '') {
            return setError('description', { type: 'custom', message: 'Veuillez saisir la description' })
        }
        if (description.length < 50) {
            return setError('description', { type: 'custom', message: 'Saisissez au moins 50 caractères' })
        }
        if (new Date(data.date_expiration) < new Date()) {
            return setError('date_expiration', { type: 'custom', message: "Veuillez saisir une date supérieur à aujourd'hui" })
        }
        const newData = { ...data, description, entreprise_id: entreprise.id }
        setLoading(true)
        addOffreByEntreprise(newData)
            .unwrap()
            .then(res => {
                if (res.success) {
                    toastAlertSuccess("Offre ajoutée")
                    navigate(ENTREPRISE_HOME_PAGE_PATH + '/' + ENTREPRISE_MES_OFFRES_PATH)
                    return
                }
                toastAlertError('Une erreur est survenue')
            })
            .catch(() => {
                toastAlertError('Une erreur est survenue')
            })
            .finally(() => {
                reset()
                setLoading(false)
            })
    }

    const selectOptions = useMemo(() => {
        return [
            { value: 'stage', label: "Stage" },
            { value: 'cdi', label: "CDI" },
            { value: 'cdd', label: "CDD" },
            // { value: '', label: "Selectionner le type d'offre" },
        ]
    }, [])

    return (
        <div
            // style={{ maxWidth: '800px' }}
            className='mx-auto'
        >
            {/* label, register, htmlFor, required, type, error, ...props */}
            <NavigateBack />
            <h2 className='text-center fs-4'>Ajoutez une offre</h2>
            <form
                onSubmit={handleSubmit(addOffre)}
            >
                <Input
                    type="text"
                    label="Titre"
                    register={{ ...register('titre') }}
                    htmlFor="titre"
                    required
                    error={errors?.titre?.message}
                />
                <Input
                    type="date"
                    label="Date d'expiration"
                    min={new Date()}
                    register={{ ...register('date_expiration', { required: "Veuillez saisir la date date d'expiration" }) }}
                    htmlFor="date_expiration"
                    required
                    error={errors?.date_expiration?.message}
                />
                {/* register, label, htmlFor, required, error, ...props */}
                <Select
                    label="Type d'offre"
                    register={{ ...register('offre_type') }}
                    selectOptions={selectOptions}
                    required
                >

                </Select>
                {/* <TextArea
                    label="Description"
                    register={{ ...register('description', { required: 'Ce champ est obligatoire' }) }}
                    htmlFor="description"
                    required
                    error={errors.description?.message}
                /> */}
                <div className='mt-3'>
                    <span className='fs-6 required'>Description</span>
                    {
                        errors?.description && <span className='text-danger fs-6 ps-2'>{errors.description.message}</span>
                    }
                    <ReactQuill
                        modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike'],
                                ['blockquote', 'code-block'],

                                [{ 'header': 1 }, { 'header': 2 }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                // [{ 'indent': '-1' }, { 'indent': '+1' }],
                                [{ 'direction': 'rtl' }],
                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                ['link', 'image', 'video', 'formula'],

                                [{ 'color': [] }, { 'background': [] }],
                                [{ 'font': [] }],
                                [{ 'align': [] }],

                                ['clean']
                            ]
                        }}
                        theme="snow"
                        onChange={setDescription}
                    />

                </div>

                {/* type, className, onClick, isLoading */}
                {/* <Button
                    type="submit"
                    className="w-100 btn btn-success mt-3 py-2"
                    isLoading={loading}
                >
                    Ajouter
                </Button> */}
                <div style={{ width: '150px' }}>
                    <ButtonLoading
                        loading={loading}
                        text="Ajouter"
                        className="btn-apply w-100 mt-3 py-2"
                    />
                </div>
            </form>
        </div>
    )
}

export default AjoutOffre