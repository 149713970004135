import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { BiTrash } from "react-icons/bi";
import CustomModal from "../../components/CustomModal";
import { useDeleteEtudiantCvMutation } from "../../redux/slices/etudiants/etudiantsSlice";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";

// Register the plugin
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const EditCvModal = ({
  show,
  close,
  setCvFile,
  cvId,
  user,
  accessToken,
  refetch,
  isEditing,
  setIsEditing,
  userCv,
}) => {
  // use delete cv mutation
  const [deleteCv] = useDeleteEtudiantCvMutation();

  // Delete Cv
  const handleDeleteCv = () => {
    deleteCv(cvId)
      .unwrap()
      .then((res) => {
        toastAlertSuccess(res?.message);
        close();
        refetch();
        setCvFile(null);
      })
      .catch((err) => {
        toastAlertError(err.message);
      });
  };

  return (
    <CustomModal
      modalTitle="Modifier mon cv"
      show={show}
      handleClose={close}
      size=""
    >
      <div className="text-center">
        {isEditing && (
          <FilePond
            acceptedFileTypes={["application/pdf"]}
            maxFileSize="2MB"
            allowFileSizeValidation={true}
            onupdatefiles={(fileItems) => {
              setCvFile(fileItems.map((fileItem) => fileItem.file));
            }}
            labelIdle={`<span className="filepond--label-action">${userCv?.fichier}</span>`}
            credits={null}
            server={{
              process: `${process.env.REACT_APP_API_URL}resume/${user?.id}`,
              remove: null,
              revert: null,
              fetch: false,
              load: false,
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }}
            instantUpload={true}
            name={"cv"}
            onprocessfile={() => {
              refetch();
              toastAlertSuccess("CV Modifié.");
              close();
            }}
            onremovefile={() => {
              setIsEditing(false);
              setCvFile(null);
            }}
            labelFileProcessing="Modification en cours"
            labelTapToCancel="Appuyer pour annuler"
            labelFileProcessingAborted="Modification annulé"
            labelTapToRetry="Appuyer pour recommencer"
            labelMaxFileSize="La taille maximale est de 2 Mo"
            labelMaxFileSizeExceeded="Fichier trop volumineux"
          />
        )}
        <button className="btn btn-pdf mx-" onClick={handleDeleteCv}>
          <BiTrash /> Supprimer le CV
        </button>
      </div>
    </CustomModal>
  );
};

export default EditCvModal;
