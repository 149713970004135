import React from 'react'
import { MdEdit, MdOutlineRemoveRedEye } from 'react-icons/md';
import { dateFormatter } from '../../utils/timeFormatters';

const CvInfoItem = ({ userCv, onClick }) => {
    const { fichier, updated_at } = userCv
    return (
        <div className="row d-flex align-items-center justify-content-between mb-4 white-bg px-2 py-3 mx-auto">
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-6 col-12">
                <p>{fichier}</p>
                <p>{`Mise à jour le ${dateFormatter(updated_at)}`}</p>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6 col-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-end px-0 mt-3 mt-sm-0 mt-xl-0 mt-lg-0 mt-md-0">
                <a
                    href={`${process.env.REACT_APP_BASE_URL}resume/${fichier}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <button className="btn btn-pdf mx-2">
                        <MdOutlineRemoveRedEye />
                    </button>
                </a>
                {
                    onClick && <button
                        className="btn btn-edit"
                        onClick={onClick}
                    >
                        <MdEdit />
                    </button>
                }

            </div>
        </div>
    )
}

export default CvInfoItem