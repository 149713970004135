import { createSlice } from "@reduxjs/toolkit";

const offreCandidatureReducer = createSlice({
    name: 'offresCandidatures',
    initialState: { selectedOffreCandidature: {} },
    reducers: {
        setSelectedOffreCandidature: (state, payload) => {
            state.selectedOffreCandidature = payload
        },
        resetselectedOffreCandidature: (state) => {
            state.selectedOffreCandidature = {}
        }
    }
})

export const { setSelectedOffreCandidature, resetSelectedOffreCandidature } = offreCandidatureReducer.actions

export default offreCandidatureReducer.reducer