import { Badge } from "react-bootstrap";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { TbFileUpload } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../components/SearchFilter";
import TableMapper from "../../components/TableMapper";
import TitleH2 from "../../components/shared/TitleH2";
import { ENTREPRISE_CANDIDATURE_DETAILS_PAGE_PATH } from "../../constants/routes_path";
import { useEntreprise } from "../../hooks/useEntreprise";
import useQueryParams from "../../hooks/useQueryParams";
import {
  setSelectedCandidature,
  setSelectedCandidatureId,
} from "../../redux/reducers/candidatureReducer";
import { useGetByEntrepriseQuery } from "../../redux/slices/candidature/candidatureSlce";
import DotLoader from "../../utils/DotLoader";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
import Pagination from "../../components/Pagination";

const EntrepriseCandidaturePage = () => {
  // Custom Hooks
  const { queryParams, setQueryParams, getCurrentParams } = useQueryParams();
  const { entreprise } = useEntreprise();
  const currentParams = getCurrentParams();

  // Convert URLSearchParams object to a plain object
  const paramsObject = {};
  for (const [key, value] of queryParams.entries()) {
    paramsObject[key] = value;
  }
  const {
    data: candidatures,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetByEntrepriseQuery({
    id_entreprise: entreprise?.id,
    params: paramsObject,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToDetails = (row) => {
    // navigate(ENTREPRISE_CANDIDATURE_DETAILS_PAGE_PATH);
    navigate(`${row?.id}/details`, {
      state: { candidatureId: row?.id },
    });
    dispatch(setSelectedCandidatureId(row.id));
    dispatch(setSelectedCandidature(row));
  };

  // Add index field to each item
  const indexedData = candidatures?.data?.data?.map((item, index) => ({
    ...item,
    index,
  }));

  // Table columns
  const columns = [
    {
      dataField: "user",
      text: "Prénom & Nom",
      formatter: (cell, row) => (
        <span style={{ cursor: "pointer" }}>{row?.user?.name}</span>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },

    {
      dataField: "cv",
      text: "Cv",
      formatter: (cell, row) =>
        row?.user?.cv?.fichier ? (
          <a
            href={
              process.env.REACT_APP_BASE_URL +
              "resume/" +
              row?.user?.cv?.fichier
            }
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
            onClick={(e) => e.stopPropagation()}
          >
            {row?.user?.cv?.fichier ? <TbFileUpload size={25} /> : "vide"}
          </a>
        ) : (
          <span>Vide</span>
        ),
      headerStyle: (colum, colIndex) => {
        return { width: "50px" };
      },
    },

    {
      dataField: "completionRate",
      text: "Profil completé à",
      formatter: (cell, row) => (
        // <div className="progress">
        //   <div
        //     className="progress-bar bg-success progress-bar-animated p-1"
        //     role="progressbar"
        //     style={{
        //       width: `${row?.user?.etudiant?.profile_completion_rate ?? 0}%`,
        //     }}
        //     aria-valuenow={row?.user?.etudiant?.profile_completion_rate ?? 0}
        //     aria-valuemin="0"
        //     aria-valuemax="100"
        //   >
        //     {row?.user?.etudiant?.profile_completion_rate ?? 0}%
        //   </div>
        // </div>
        <div className="d-flex align-items-center">
          <progress
            id="file"
            value={row?.user?.etudiant?.profile_completion_rate ?? 0}
            max="100"
            className="h-25"
          >
            {row?.user?.etudiant?.profile_completion_rate ?? 0}
          </progress>
          <p>
            <span className="fw-bold fs-6">
              {row?.user?.etudiant?.profile_completion_rate ?? 0}%
            </span>
          </p>
        </div>
      ),
    },

    {
      dataField: "niveau",
      text: "Niveau",
      formatter: (cell, row) => (
        <span>{row?.user?.etudiant?.niveau_etude}</span>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
    },

    {
      dataField: "experiences",
      text: "Expériences",
      formatter: (cell, row) => (
        <span>{row?.user?.etudiant?.nb_an_experiences ?? "Néant"}</span>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
    },

    {
      dataField: "location",
      text: "Adresse",
      formatter: (cell, row) => {
        return (
          <span
            onClick={() => goToDetails(row)}
            style={{ color: "#7b809a", cursor: "pointer" }}
          >
            {row?.user?.location ?? "Néant"}
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
    },

    {
      dataField: "name",
      text: "Statut",
      formatter: (cell, row) => (
        <div className="text-center">
          {row?.status_candidature === "rejetter" ? (
            <Badge bg="danger">rejetée</Badge>
          ) : row?.status_candidature === "convoque" ? (
            <Badge bg="success">convoquée</Badge>
          ) : row?.status_candidature === "valider" ? (
            <Badge bg="warning">validée</Badge>
          ) : row?.status_candidature === "en_cours" ? (
            <Badge>Nouvelle</Badge>
          ) : null}
        </div>
      ),
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      goToDetails(row);
    },
  };

  const activeHandler = (clickedActive) => {
    setQueryParams({
      ...currentParams,
      page: clickedActive,
    });
  };

  return (
    <div>
      {/* <div className="d-flex justify-content-between align-items-center"> */}
      <div className="d-flex align-items-center gap-2 my-2">
        <TitleH2>Candidatures</TitleH2>
        {isLoading || isFetching ? (
          <span className="ms-4">
            <DotLoader />
          </span>
        ) : (
          <Badge>{candidatures?.data?.total}</Badge>
        )}
        {/* </div> */}
      </div>

      <div>
        {/* Custom filter */}
        <SearchFilter simpleFilter={false} entrepriseCandidatureFilters />

        {isLoading || isFetching ? (
          <SpinnerLoaderSearch />
        ) : isSuccess ? (
          <div className="mt-4">
            <TableMapper
              keyField="id"
              data={indexedData ?? []}
              columns={columns}
              rowEvents={rowEvents}
              noDataIndication={() => "Pas de candidats"}
              rowStyle={{ cursor: "pointer" }}
              hover
              striped
            />
            {candidatures?.data?.total > 15 && (
              <div className="d-flex justify-content-center">
                <Pagination
                  active={parseInt(queryParams.get("page")) || 1}
                  size={candidatures?.data?.last_page}
                  step={2}
                  onClickHandler={activeHandler}
                />
              </div>
            )}
          </div>
        ) : (
          <p className="text-center mt-3">
            Une erreur s'est produite lors du chargement
          </p>
        )}
      </div>
    </div>
  );
};

export default EntrepriseCandidaturePage;
