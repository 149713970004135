import React from "react";
import OffresResourceDataMap from "../../components/OffresResourceDataMap";
import SearchFilter from "../../components/SearchFilter";
import PaginatedItems from "../../components/shared/PaginationItems";
import useQueryParams from "../../hooks/useQueryParams";
import { useGetAllOffresQuery } from "../../redux/slices/offres/offresSlice";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";

const Offres = () => {
  // Query params hooks
  const { queryParams } = useQueryParams();

  // Convert URLSearchParams object to a plain object
  const paramsObject = {};
  for (const [key, value] of queryParams.entries()) {
    paramsObject[key] = value;
  }

  // All offers query
  const {
    data: offres,
    isLoading,
    isSuccess,
    isFetching,
    currentData,
    // refetch,
  } = useGetAllOffresQuery(paramsObject);

  return (
    <div className="pb-5 px-0">
      {/* Multi factor filter component */}
      <SearchFilter simpleFilter={false} etudiantOffreFilters />

      <div>
        {isLoading || (isFetching && !currentData) ? (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <SpinnerLoaderSearch />
          </div>
        ) : isSuccess ? (
          offres?.count >= 1 ? (
            <PaginatedItems itemsPerPage={10} ressourceData={offres?.data}>
              <OffresResourceDataMap />
            </PaginatedItems>
          ) : (
            <p className="text-center mt-3">Aucune offre pour le moment.</p>
          )
        ) : (
          <p className="text-center mt-3">
            Une erreur s'est produite lors du chargement
          </p>
        )}
      </div>
    </div>
    // </Main >
  );
};

export default Offres;
