import React, { cloneElement, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CardOnEmptyProfilData from "../../components/CardOnEmptyProfilData";
import FormationListItem from "../../components/FormationListItem";
import ResourceDataProfil from "../../components/ResourceDataProfil";
import NavigateBack from "../../components/shared/NavigateBack";
import ResourceDataMap from "../../components/shared/ResourceDataMap";
import { setSelectedCertification } from "../../redux/reducers/certificationReducer";
import { setSelectedProject } from "../../redux/reducers/projetReducer";
import { useGetEtudiantInfoQuery } from "../../redux/slices/etablissements/etablissementsSlice";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
import CvInfoItem from "../Profile/CvInfoItem";
import CertificationGallery from "../Profile/certification/CertificationGallery";
import CertificationListItem from "../Profile/certification/components/CertificationListItem";
import ExperienceCardItem from "../Profile/experience/ExperienceCardItem";
import ProjectImagesDetails from "../Profile/projet/ProjectImagesDetails";
import ProjetListItem from "../Profile/projet/components/ProjetListItem";
// import { setSelectedCertification } from "../../redux/reducers/certificationReducer";

// User infos section
const UserInfo = ({ user = { user: {} } }) => {
  // TODO: fetcher le user ici
  //   const dataEtablissement = JSON.parse(localStorage.getItem("user"));

  function isLinkedInAvatar(str) {
    try {
      const newUrl = new URL(str);
      return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    } catch (err) {
      return false;
    }
  }

  return (
    <div className="white-bg px-2 py-3 mx-auto">
      <div className="d-flex align-items-center justify-content-center">
        <img
          src={
            isLinkedInAvatar(user?.user?.avatar)
              ? user?.user?.avatar
              : user?.user?.avatar !== null
              ? `${process.env.REACT_APP_BASE_TWO}${user?.user?.avatar}`
              : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBmaWxsPSIjRjZGNkY5IiBkPSJNMCAwaDEyOHYxMjhIMHoiLz48cGF0aCBmaWxsPSIjQkRCREMwIiBkPSJNMTYgMTE2YzAtMTYuNTY5IDEzLjQzMS0zMCAzMC0zMGgzNmMxNi41NjkgMCAzMCAxMy40MzEgMzAgMzB2MjQuODM3YzAgMTYuNTY5LTEzLjQzMSAzMC0zMCAzMEg0NmMtMTYuNTY5IDAtMzAtMTMuNDMxLTMwLTMwVjExNlptNzYtNjRjMCAxNS40NjQtMTIuNTM2IDI4LTI4IDI4UzM2IDY3LjQ2NCAzNiA1MnMxMi41MzYtMjggMjgtMjggMjggMTIuNTM2IDI4IDI4WiIvPjwvc3ZnPgo="
          }
          alt="Candidat"
          //   onError={
          //     "https://img.myloview.com/stickers/default-avatar-profile-icon-vector-social-media-user-image-700-205124837.jpg"
          //   }
          className="rounded-circle img-fluid"
          style={{ width: "90px", height: "90px" }}
        />
      </div>
      <h2 className="text-center mt-2 profil-name">
        {user?.user?.name ?? "..........."}
      </h2>
      <p className=" text-center mx-1 mb-3 fw-bold">
        {user?.fonction ?? "Fonction non définit"}
      </p>
      <div className="px-2 profil-body">
        <p className="mx-1 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
            />
          </svg>

          {user?.user?.email ?? "..........."}
        </p>

        <p className="mx-1 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
            />
          </svg>
          {user?.user?.phone ?? "..........."}
        </p>

        <p className="mx-1 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          {user?.user?.location ?? "..........."}
        </p>
        <p className="mx-1 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
            />
          </svg>
          {user?.nomEtablissement ?? "Pas encore d'école"}
        </p>
        <p className="mx-1 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
            />
          </svg>
          {user?.niveau_etude ?? "Pas ecore d'école"}
        </p>
        <p className="mx-1 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
            />
          </svg>

          {user?.nb_an_experiences === null
            ? "Pas d'experience"
            : `${user?.nb_an_experiences} années d'experience`}
        </p>
      </div>
      <div className="d-flex flex-column mt-3">
        <p>
          Profil renseigné à{" "}
          <span className="fw-bold fs-6">
            {user?.profile_completion_rate ?? 0}%
          </span>
        </p>
        <progress
          id="file"
          value={user?.profile_completion_rate ?? 0}
          max="100"
        >
          {user?.profile_completion_rate ?? 0}
        </progress>
      </div>
    </div>
  );
};

// Complementary infos
const ComplementaryInfos = ({
  loadingCv,
  userCv,
  loadingFormations,
  userFormations,
  userExperiences,
  loadingExperiences,
  isLoading,
  userProjets,
  userCertifications,
  userProfileCompletionRate,
}) => {
  const [showCertificationProject, setShowCertificationProject] =
    useState(false);

  const [showImageProject, setShowImageProject] = useState(false);

  const dispatch = useDispatch();

  // !open project images
  const openImages = (selectedProject) => {
    dispatch(setSelectedProject(selectedProject));
    setShowImageProject(true);
  };
  // !open project images
  const openCertificationImages = (selectedCertification) => {
    dispatch(setSelectedCertification(selectedCertification));
    setShowCertificationProject(true);
  };
  const ResourceItemFormation = ({ formation }) =>
    cloneElement(<FormationListItem formation={formation} />);

  const ResourceItemCv = ({ userCv }) =>
    cloneElement(<CvInfoItem userCv={userCv} />);

  const ClonedProjetListItem = ({ projet }) =>
    cloneElement(<ProjetListItem projet={projet} />, { openImages });

  const ClonedCertificationListItem = ({ certification }) =>
    cloneElement(<CertificationListItem certification={certification} />, {
      openCertificationImages,
    });
  // const ClonedExperienceCardItem = ({ experience }) => cloneElement(<ExperienceCardItem experience={experience} />)

  return (
    <>
      {/* *********************************** cv **************************************** */}
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <SpinnerLoaderSearch />
        </div>
      ) : (
        <>
          <ResourceDataProfil
            title="CV"
            isLoading={loadingCv}
            // resourceData={Object.entries(userCv)}
            resourceData={userCv}
          >
            <ResourceDataMap
              // resourceData={Object.entries(userCv)}
              resourceData={userCv}
              resourceName="userCv"
              resourceItem={ResourceItemCv}
            />
            <CardOnEmptyProfilData
              titleOnEmpty=""
              textOnEmpty="Cv non disponible"
              addBtnText=""
              onClick={null}
            />
          </ResourceDataProfil>

          <ResourceDataProfil
            accordion
            onAdd={null}
            title="Formations"
            isLoading={loadingFormations}
            resourceData={userFormations}
          >
            <ResourceDataMap
              resourceData={userFormations}
              resourceName="formation"
              resourceItem={ResourceItemFormation}
              // resourceItem={FormationListItem}
            />
            <CardOnEmptyProfilData
              titleOnEmpty=""
              textOnEmpty="Formations non disponible"
              addBtnText=""
              onClick={null}
            />
          </ResourceDataProfil>

          {/* +++++++++++++++++++++++++++++++++++++++++ PROJETS ++++++++++++++++++++++++++++++++++++++ */}
          <ProjectImagesDetails
            show={showImageProject}
            close={() => setShowImageProject(false)}
          />
          <ResourceDataProfil
            accordion
            onAdd={null}
            title="Projets"
            isLoading={false}
            resourceData={userProjets}
          >
            <ResourceDataMap
              resourceData={userProjets}
              resourceName="projet"
              resourceItem={ClonedProjetListItem}
              // resourceItem={FormationListItem}
            />
            <CardOnEmptyProfilData
              titleOnEmpty=""
              textOnEmpty="Projets non disponible"
              addBtnText=""
              onClick={null}
            />
          </ResourceDataProfil>

          {/* +++++++++++++++++++++++++++++++++++++++++ CERTIFICATIONS ++++++++++++++++++++++++++++++++++++++ */}
          <CertificationGallery
            show={showCertificationProject}
            close={() => setShowCertificationProject(false)}
          />
          <ResourceDataProfil
            accordion
            onAdd={null}
            title="Certifications"
            isLoading={false}
            resourceData={userCertifications}
          >
            <ResourceDataMap
              resourceData={userCertifications}
              resourceName="certification"
              resourceItem={ClonedCertificationListItem}
              // resourceItem={FormationListItem}
            />
            <CardOnEmptyProfilData
              titleOnEmpty=""
              textOnEmpty="Certifications non disponibles"
              addBtnText=""
              onClick={null}
            />
          </ResourceDataProfil>

          {/* +++++++++++++++++++++++++++++++++++++++++ EXPERIENCES ++++++++++++++++++++++++++++++++++++++ */}
          <ResourceDataProfil
            accordion
            onAdd={null}
            title="Expériences"
            isLoading={loadingExperiences}
            resourceData={userExperiences}
          >
            <ResourceDataMap
              resourceData={userExperiences}
              resourceName="experience"
              resourceItem={ExperienceCardItem}
            />
            <CardOnEmptyProfilData
              titleOnEmpty=""
              textOnEmpty="Expériences non disponibles"
              addBtnText=""
              onClick={null}
            />
          </ResourceDataProfil>
        </>
      )}
    </>
  );
};

const DetailsEtudiantPage = () => {
  const { userId } = useParams();

  const {
    data = {
      cv: [],
      etudiant: [],
      experience: [],
      formation: [],
      candidature: [],
    },
    isLoading,
  } = useGetEtudiantInfoQuery(userId);

  const { etudiant } = data;

  return (
    <>
      <div className="mb-3">
        <NavigateBack />
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-12 mb-md-3 mb-3 mx-auto px-0">
          <UserInfo user={etudiant[0]} userId={Number(userId)} />
        </div>
        <div className="col-xl-8 col-lg-8 col-12 mb-3 px-sm-0 ps-xl-4 ps-lg-4">
          <ComplementaryInfos
            isLoading={isLoading}
            loadingCv={false}
            userCv={(etudiant?.cv || etudiant[0]?.cv) ?? []}
            loadingFormations={false}
            userFormations={etudiant?.formation || etudiant[0]?.formation}
            loadingExperiences={false}
            userExperiences={etudiant?.experience || etudiant[0]?.experience}
            userProjets={etudiant?.projects || etudiant[0]?.projects}
            userCertifications={
              etudiant?.certifications || etudiant[0]?.certifications
            }
          />
        </div>
      </div>
    </>
  );
};

export default DetailsEtudiantPage;
