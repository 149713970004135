import React, { useEffect, useReducer, useRef } from "react";
import { FaGraduationCap } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import useQueryParams from "../../../hooks/useQueryParams";

const DEBOUNCE_THRESHOLD = 500;

const EntrepriseOffresFilters = () => {
  // Query params hooks
  const { queryParams, getCurrentParams, setQueryParams } = useQueryParams();

  const offreTypeOptions = [
    { value: "CDI", label: "CDI" },
    { value: "CDD", label: "CDD" },
    { value: "stage", label: "stage" },
    { value: "alternance", label: "alternance" },
  ];

  // Initial states
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      offreName: queryParams.get("titre") || "",
      offreType:
        offreTypeOptions?.find(
          (option) => option?.value === queryParams.get("type")
        ) || null,
      hasCandidats: queryParams.get("has_candidats") || false,
    }
  );

  //   Refs
  const timeoutHandler = useRef(null);
  const ref = useRef(null);

  const currentParams = getCurrentParams();

  // This function is responsible for setting and updating query params
  const handleFilter = (e, filter) => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    timeoutHandler.current = setTimeout(() => {
      if (filter === 1) {
        setQueryParams({
          ...currentParams,
          titre: e.target.value ? e.target.value : "",
        });
      } else if (filter === 2) {
        setQueryParams({
          ...currentParams,
          type: e ? e?.value : "",
        });
      } else if (filter === 3) {
        setState({ hasCandidats: !state?.hasCandidats });
      }
    }, DEBOUNCE_THRESHOLD);
  };

  useEffect(() => {
    setQueryParams({
      ...currentParams,
      has_candidats: state?.hasCandidats,
    });
  }, [state?.hasCandidats]);

  // Reset filters
  const reset = () => {
    setQueryParams();
    setState({
      offreName: "",
      offreType: null,
      hasCandidats: false,
    });
  };

  return (
    <>
      <div className="row py-0">
        <div className="col-12 col-xl col-lg col-md col-sm my-1 my-xl-0 my-lg-0 my-md-0 my-sm-0 d-flex align-items-center">
          <FiSearch className="mx-1 search_filter_icon" icon="search" />
          <input
            type="text"
            ref={ref}
            value={state?.offreName}
            onChange={(e) => {
              setState({ offreName: e.target.value });
              handleFilter(e, 1);
            }}
            placeholder="Que recherchez-vous ?"
            className="search_input mx-1 form-control border"
          />
        </div>

        <div className="col-12 col-xl col-lg col-md col-sm my-1 my-xl-0 my-lg-0 my-md-0 my-sm-0 d-flex align-items-center ">
          <FaGraduationCap className="mx-1 search_filter_icon" icon="search" />
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
                height: "50px",
              }),
            }}
            options={offreTypeOptions}
            onChange={(e) => {
              setState({ offreType: e });
              handleFilter(e, 2);
            }}
            value={state?.offreType}
            className="w-100 border-none"
            isSearchable
            noOptionsMessage={() => "Pas d'options"}
            placeholder="Type d'offres"
            isClearable
          />
        </div>
      </div>

      <div className="row py-0 mt-2">
        <div className="col d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name=""
              id="hasCandidats"
              checked={state?.hasCandidats}
              onChange={(e) => handleFilter(e, 3)}
            />
            <label htmlFor="hasCandidats" className="pointer-event">
              Offres avec candidatures
            </label>
          </div>
          <button className="btn btn-red fs-6 border-0 mt-2 " onClick={reset}>
            <FaRegTrashCan className="me-1" size={18} />
            Effacer filtres
          </button>
        </div>
      </div>
    </>
  );
};

export default EntrepriseOffresFilters;
