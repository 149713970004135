import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import ButtonLoading from "../../components/ButtonLoading";
import CustomModal from "../../components/CustomModal";
import { useAjouterFormationEtudiantMutation } from "../../redux/slices/etudiants/formationSlice";
import { yearMonthDayFormatter } from "../../utils/timeFormatters";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";

const AddFormationModal = ({ show, close, userId, refetch }) => {
  const [startDate, setStartDate] = React.useState("");
  // const [setEndDate] = React.useState("");
  const [loading, setLoading] = useState(false)
  const currentDate = new Date();

  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    etablissement: Yup.string().required("Obligatoire"),
    date_debut: Yup.string().required("Obligatoire"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  // Function to reset the input field after submit
  const resetInputFields = () => {
    reset({
      name: "",
      etablissement: '',
      date_debut: "",
      date_fin: "",
    });
    setStartDate("");
    // setEndDate("");
  };

  // use add formation mutation
  const [addNewFormation] = useAjouterFormationEtudiantMutation();

  const addFormation = (data) => {
    setLoading(true)
    addNewFormation(data)
      .unwrap()
      .then((res) => {
        close();
        resetInputFields();
        toastAlertSuccess(res.message);
        refetch();
      })
      .catch((err) => {
        toastAlertError(err.message);
      })
      .finally(() => {
        setLoading(false)
      })
  };

  return (
    <CustomModal
      modalTitle="Ajoutez une Formation"
      show={show}
      handleClose={() => {
        close();
        resetInputFields();
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          data.user_id = userId;
          addFormation(data);
        })}
      >
        <div className="mb-1">
          <label htmlFor="titrePoste" className="form-label">
            Nom de la formation
          </label>
          <input
            type="text"
            className={`form-control ${errors.name && "is-invalid"}`}
            id="name"
            {...register("name")}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="titrePoste" className="form-label">
            Ecole formation
          </label>
          <input
            type="text"
            className={`form-control ${errors.etablissement && "is-invalid"}`}
            id="etablissement"
            {...register("etablissement")}
          />
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="dateDebut" className="form-label">
              Date debut
            </label>
            <input
              type="date"
              className={`form-control ${errors.date_debut && "is-invalid"}`}
              id="dateDebut"
              max={yearMonthDayFormatter(currentDate)}
              {...register("date_debut", {
                onChange: (e) => setStartDate(e.target.value),
              })}
            />
          </div>
          <div className="col mb-3">
            <label htmlFor="dateFin" className="form-label">
              Date fin
            </label>
            <input
              type="date"
              className="form-control"
              id="dateFin"
              min={startDate}
              {...register("date_fin")}
              disabled={startDate === "" || (startDate === undefined && true)}
            />
          </div>
        </div>

        <div className="text-end">
          {/* <button type="submit" className="btn btn-apply w-100 d-flex align-items-center justify-content-center">
            <span>Enregistrer</span>
            <div style={{ width: '20px', height: '20px' }} className="spinner-border ms-3" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </button> */}
          <ButtonLoading loading={loading} text="Enregistrer" className="btn-apply" />
        </div>
      </form>
    </CustomModal>
  );
};

export default AddFormationModal;
