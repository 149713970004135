import React, { useContext } from "react";
import { useTransition, animated } from "react-spring";
import PropTypes from "prop-types";
// import * as S from "./styles";
import { TabContext } from "../context";

// const Panel = animated(S.Panel);

export default function TabsPanel({ id, children }) {
    let { activePanel } = useContext(TabContext);
    const transitions = useTransition(activePanel === id, {
        from: {
            transform: "translateY(50%)",
            opacity: 0,
            zIndex: 1
        },
        enter: {
            transform: "translateY(0)",
            position: "absolute",
            zIndex: 2,
            opacity: 1,
            width: "100%",
            color: 'black'
        },
        leave: { transform: "translateY(50%)", opacity: 0, position: "absolute" },
        update: { position: "static" }
    });

    return transitions((styles, item) =>
        item && (
            <animated.div
                style={{ ...styles, marginTop: 10 }}
            >
                {children}
            </animated.div>
        )
    );
}

TabsPanel.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};
