import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";

export const TabContext = React.createContext();

const STORAGE_TITLE = "activeReactTabsPanel";

const TabContextProvider = ({
    children,
    defaultActivePanel,
    isUseLocalStorage = true
}) => {
    const [activePanel, setActivePanel] = useState("");
    let tabValues = {
        activePanel,
        switchPanel: newPanel => {
            setActivePanel(newPanel);
            if (isUseLocalStorage) {
                localStorage.setItem(STORAGE_TITLE, newPanel);
            }
        }
    };

    function clearStorage() {
        localStorage.removeItem(STORAGE_TITLE);
    }

    useEffect(() => {
        if (!isUseLocalStorage) {
            clearStorage();
            setActivePanel(defaultActivePanel);
        } else {
            const storagedActivePanel = localStorage.getItem(STORAGE_TITLE);
            setActivePanel(defaultActivePanel);
            setActivePanel(storagedActivePanel);
        }
    }, [defaultActivePanel, isUseLocalStorage]);

    return (
        <TabContext.Provider value={tabValues}>
            {children}
        </TabContext.Provider>
    );
};

// TabContextProvider.defaultProps = {
//     isUseLocalStorage: true
// };

// TabContextProvider.propTypes = {
//     children: PropTypes.node.isRequired,
//     defaultActivePanel: PropTypes.string.isRequired,
//     isUseLocalStorage: PropTypes.bool
// };

export default TabContextProvider;
