import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
// import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from "react-bootstrap-table2-paginator";

const TableMapper = ({ keyField, columns, data, loading, ...props }) => {
  const pagination = paginationFactory({
    page: 2,
  });

  return (
    <BootstrapTable
      // pagination={pagination}
      keyField={keyField ?? "id"}
      data={data}
      columns={columns}
      loading={loading} //only loading is true, react-bootstrap-table will render overlay
      // overlay={overlayFactory()}
      {...props}
    />
  );
};

export default TableMapper;
