import React from 'react'
import Banniere from './components/Banniere'
import './landingpage.css'
import AvantagesSection from './sections/AvantagesSection'
import ContextSection from './sections/ContextSection'
import LesDifferentsActeurs from './sections/LesDifferentsActeurs'
import ObjectifsSection from './sections/ObjectifsSection'
import Partners from './sections/Partners'

// import LesChiffresSection from './sections/LesChiffresSection'
import Footer from './sections/Footer'
// import ContactezNous from './sections/ContactezNous'
import OffresSection from './sections/OffresSection'

const LandingPage = () => {
    return (
        <div>
            <Banniere />
            <OffresSection />
            {/* <ContextSection /> */}
            <LesDifferentsActeurs />
            <Partners />
            {/* <AvantagesSection /> */}
            {/* <LesChiffresSection /> */}
            {/* <ContactezNous /> */}
            <Footer />
        </div>
    )
}

export default LandingPage