import React, { cloneElement, useState } from 'react'
import { useDeleteMutation, useGetEntrepriseDomaineQuery } from '../../../redux/slices/entreprises/domainesSlice'
import { useEntreprise } from '../../../hooks/useEntreprise'
import { GridList } from 'react-flexible-list'
import EntrepriseDomaineCardItem from '../components/EntrepriseDomaineCardItem'
import EditEntrepriseDomaine from '../components/EditEntrepriseDomaine'
import { toastAlertError, toastAlertSuccess } from '../../../utils/toastAlerts'
import LoaderSpinner from '../../../components/LoaderSpinner';
import { useDispatch } from 'react-redux'
import { setCurrentDomaineCandidats } from '../../../redux/reducers/candidatureReducer'

const ListeEntrepriseDomaines = () => {
    const dispatch = useDispatch()
    const { entreprise } = useEntreprise()
    const [openEditDomaine, setOpenEditDomaine] = useState(false)
    // const [openDeleteDomaine, setOpenDeleteDomaine] = useState(false)
    const [deleteDomaineMutation] = useDeleteMutation()
    const { data = { count: 0, data: [] }, isLoading } = useGetEntrepriseDomaineQuery(entreprise?.id)
    const [selectedDomaine, setSelectedDomaine] = useState(null)
    const deleteDomaine = (domaine) => {
        // setOpenDeleteDomaine(true)
        deleteDomaineMutation({ entreprise_id: entreprise.id, domaine_id: domaine.id }).then((res) => {
            toastAlertSuccess("Domaine supprimé")
        }).catch(err => {
            toastAlertError("Une erreur est survenue")
        })
        setSelectedDomaine(domaine)
    }
    const editDomaine = (domaine) => {
        setOpenEditDomaine(true)
        setSelectedDomaine(domaine)
    }
    const setCurrentDomainesFunc = (candidtas) => {
        dispatch(setCurrentDomaineCandidats(candidtas))
    }
    const ClonedEntrepriseDomaineCardItem = ({ domaine }) => cloneElement(<EntrepriseDomaineCardItem
        domaine={domaine}
    />,
        {
            editDomaine,
            deleteDomaine,
            setCurrentDomainesFunc
        })

    return (
        <>
            <EditEntrepriseDomaine
                show={openEditDomaine}
                close={() => setOpenEditDomaine(false)}
                selectedDomaine={selectedDomaine}
            />
            <h6 className='fs-5'>Domaines</h6>
            {
                isLoading ? <LoaderSpinner /> : <GridList
                    resourceName='domaine'
                    resourceData={data.data}
                    resourceItem={ClonedEntrepriseDomaineCardItem}
                    cardWidth={200}
                    emptyComponent={() => <p>Pas de domaines</p>}
                />
            }
        </>
    )
}

export default ListeEntrepriseDomaines