import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import * as Yup from "yup";
import ButtonLoading from "../../components/ButtonLoading";
import CustomModal from "../../components/CustomModal";
import FileUpload from "../../components/FileUpload";
import { useGetAllEtablissementsQuery } from "../../redux/slices/etablissements/etablissementsSlice";
import {
  useEditUserInfosMutation,
  useGetProfileCompletionRateQuery,
} from "../../redux/slices/etudiants/etudiantsSlice";
import { toastAlertSuccess } from "../../utils/toastAlerts";

const EditProfileModal = ({ show, close, userInfos }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  // Phone number regex
  const phoneNumberRegex = /^(77|78|76|75|70)\d{7}$/;

  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    location: Yup.string().required("Obligatoire"),
    phone: Yup.string()
      .required("Numéro téléphone obligatoire")
      .matches(phoneNumberRegex, "Numéro téléphone invalide. Ex: 77 777 77 77"),
    etablissement_id: Yup.string().required("Établissement requis").nullable(),
    genre: Yup.string().required("Genre requis").nullable(),
    niveau_etude: Yup.string().required("Niveau d'étude requis").nullable(),
    nb_an_experiences: Yup.string()
      .required("Années d'expériences requis")
      .nullable(),
    fonction: Yup.string()
      .required("Votre fonction est obligatoire")
      .nullable(),
  });

  const {
    register,
    control, // Used by controller wrapper component for controlled inputs
    handleSubmit,
    reset,
    formState,
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: { ...userInfos },
    values: { ...userInfos },
  });

  const { errors } = formState;

  // Function to reset the input field after submit
  const resetInputFields = () => {
    reset({
      name: "",
      phone: "",
      location: "",
      genre: "",
      niveau_etude: "",
      nb_an_experiences: "",
      fonction: "",
      etablissement_id: "",
    });
  };

  const [editUser, isLoading] = useEditUserInfosMutation();
  const { refetch: refetchCompletionRate } = useGetProfileCompletionRateQuery(
    user?.id
  );

  // Edit user infos
  const handleEditUser = async (data) => {
    await editUser(data)
      .unwrap()
      .then((res) => {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...res?.etudiant,
            // etablissement: userData?.etablissement,
          })
        );
        refetchCompletionRate();
        toastAlertSuccess(res?.message, "top-center");
        close();
      })
      .catch((err) => {});
  };

  // Options for selects
  const levelOptions = [
    { value: "BFEM", label: "BFEM" },
    { value: "Bac", label: "Bac" },
    { value: "Licence 1", label: "Licence 1" },
    { value: "Licence 2", label: "Licence 2" },
    { value: "Licence 3", label: "Licence 3" },
    { value: "Master 1", label: "Master 1" },
    { value: "Master 2", label: "Master 2" },
  ];
  const experienceOptions = [
    { value: "1", label: "1 an" },
    { value: "2", label: "2 ans" },
    { value: "3", label: "3 ans" },
    { value: "4", label: "4 ans" },
    { value: "5", label: "5 ans" },
    { value: "6", label: "6 ans" },
    { value: "7", label: "7 ans" },
    { value: "8", label: "8 ans" },
    { value: "9", label: "9 ans" },
    { value: "10", label: "10 ans" },
  ];
  const functionOptions = [
    { value: "Développeur Web", label: "Développeur Web" },
    { value: "Développeur Mobile", label: "Développeur Mobile" },
    { value: "Développeur Logiciel", label: "Développeur Logiciel" },
    { value: "Développeur FullStack", label: "Développeur FullStack" },
    { value: "Designer Graphique", label: "Designer Graphique" },
    {
      value: "Spécialiste en Marketing Digital",
      label: "Spécialiste en Marketing Digital",
    },
    { value: "Chef de Projet", label: "Chef de Projet" },
    { value: "Rédacteur Web", label: "Rédacteur Web" },
    { value: "Analyste de Données", label: "Analyste de Données" },
    {
      value: "Gestionnaire de Réseaux Sociaux",
      label: "Gestionnaire de Réseaux Sociaux",
    },
    { value: "Ingénieur Informatique", label: "Ingénieur Informatique" },
    {
      value: "Comptable / Analyste Financier",
      label: "Comptable / Analyste Financier",
    },
    {
      value: "Responsable des Ressources Humaines",
      label: "Responsable des Ressources Humaines",
    },
    {
      value: "Technicien de Support Technique",
      label: "Technicien de Support Technique",
    },
    { value: "Commercial / Vendeur", label: "Commercial / Vendeur" },
    {
      value: "Responsable des Relations Publiques",
      label: "Responsable des Relations Publiques",
    },
    { value: "Consultant en Stratégie", label: "Consultant en Stratégie" },
    { value: "Scientifique de la Vie", label: "Scientifique de la Vie" },
    { value: "Ingénieur Mécanique", label: "Ingénieur Mécanique" },
    { value: "Designer Industriel", label: "Designer Industriel" },
    { value: "Traducteur / Interprète", label: "Traducteur / Interprète" },
    {
      value: "Animateur 3D / Spécialiste en Effets Spéciaux",
      label: "Animateur 3D / Spécialiste en Effets Spéciaux",
    },
    { value: "Photographe / Vidéaste", label: "Photographe / Vidéaste" },
    {
      value: "Développeur de Jeux Vidéo",
      label: "Développeur de Jeux Vidéo",
    },
    { value: "Expert en Cyber Sécurité", label: "Expert en Cyber Sécurité" },
    {
      value: "Responsable de la Chaîne d'Approvisionnement",
      label: "Responsable de la Chaîne d'Approvisionnement",
    },
    {
      value: "Spécialiste en Commerce Électronique",
      label: "Spécialiste en Commerce Électronique",
    },
    { value: "Designer UX/UI", label: "Designer UX/UI" },
    {
      value: "Médecin / Professionnel de la Santé",
      label: "Médecin / Professionnel de la Santé",
    },
    { value: "Avocat / Juriste", label: "Avocat / Juriste" },
    {
      value: "Spécialiste de l'Environnement / Durabilité",
      label: "Spécialiste de l'Environnement / Durabilité",
    },
    { value: "Responsable Qualité", label: "Responsable Qualité" },
    { value: "Formateur / Enseignant", label: "Formateur / Enseignant" },
    { value: "Architecte", label: "Architecte" },
    {
      value: "Ingénieur en Intelligence Artificielle / Machine Learning",
      label: "Ingénieur en Intelligence Artificielle / Machine Learning",
    },
    {
      value: "Spécialiste du Développement Durable",
      label: "Spécialiste du Développement Durable",
    },
    {
      value: "Ingénieur en Énergie Renouvelable",
      label: "Ingénieur en Énergie Renouvelable",
    },
    {
      value: "Responsable Logistique / Transport",
      label: "Responsable Logistique / Transport",
    },
    {
      value: "Journaliste / Professionnel des Médias",
      label: "Journaliste / Professionnel des Médias",
    },
    { value: "Créateur de Contenu", label: "Créateur de Contenu" },
  ];
  const genreOptions = [
    { value: "H", label: "Homme" },
    { value: "F", label: "Femme" },
  ];

  // Get all etablissements
  const { data = [], isLoading: loadingEtablissements } =
    useGetAllEtablissementsQuery();

  // Format data for React Select
  const etablissements = data?.map((option) => ({
    value: option?.id,
    label: option?.user?.name,
  }));

  // Get current etablissement id & genre from form state
  const etablissementID = watch("etablissement_id");
  const genreType = watch("genre");

  return (
    <CustomModal
      modalTitle="Infos perso"
      show={show}
      handleClose={() => {
        close();
        resetInputFields();
      }}
    >
      {/* FilePond custom component */}
      <FileUpload
        labelIdle="Ajouter une photo"
        acceptedFileTypes={["image/png", "image/jpeg"]}
        name="avatar"
        className="filepond filepond--root--avatar"
        instantUpload={true}
        serverUrl={`${process.env.REACT_APP_API_URL}edit-user-photo/${userInfos?.id}`}
        // filesSource={`${process.env.REACT_APP_BASE_TWO}${user?.avatar}`}
        filesSource={[
          {
            source: `${process.env.REACT_APP_BASE_TWO}${user?.avatar}`,
            options: {
              type: "local",
              metadata: {
                poster: `${process.env.REACT_APP_BASE_TWO}${user?.avatar}`,
              },
            },
          },
        ]}
        loadFile={false}
        onProcessFile={(error, file) => {
          const processedFileResponse = JSON.parse(file.serverId);
          localStorage.setItem(
            "user",
            JSON.stringify({ ...processedFileResponse?.user })
          );
        }}
        // imagePreviewHeight={150}
        // imageCropAspectRatio="1:1"
        // imageResizeTargetWidth={150}
        // imageResizeTargetHeight={150}
        stylePanelLayout="compact circle"
        styleLoadIndicatorPosition="center bottom"
        styleButtonRemoveItemPosition="center bottom"
        styleProgressIndicatorPosition="center bottom"
        styleButtonProcessItemPosition="center bottom"
      />
      <form
        onSubmit={handleSubmit((data) => {
          handleEditUser({
            user_id: userInfos?.id,
            payload: data,
          });
        })}
      >
        <div className="mb-1">
          <label htmlFor="name" className="form-label">
            Prénom & Nom
          </label>
          <input
            type="text"
            className={`form-control ${errors.name && "is-invalid"}`}
            id="name"
            {...register("name")}
          />
          <p
            style={{
              fontSize: "12px",
              padding: "0 10px",
              color: "red",
            }}
          >
            {errors?.name?.message}
          </p>
        </div>

        <div className="mb-1">
          <label htmlFor="userPhone" className="form-label">
            Numéro de téléphone
          </label>
          <input
            type="text"
            className={`form-control ${errors?.phone && "is-invalid"}`}
            id="userPhone"
            {...register("phone")}
          />
          <p
            style={{
              fontSize: "12px",
              padding: "0 10px",
              color: "red",
            }}
          >
            {errors?.phone?.message}
          </p>
        </div>

        <div className="mb-1">
          <label htmlFor="userLocation" className="form-label">
            Addresse
          </label>
          <input
            type="text"
            className={`form-control ${errors?.location && "is-invalid"}`}
            id="userLocation"
            {...register("location")}
          />
          <p
            style={{
              fontSize: "12px",
              padding: "0 10px",
              color: "red",
            }}
          >
            {errors?.location?.message}
          </p>
        </div>

        {/* New code */}
        <div className="d-flex align-center">
          <div className="w-75">
            <Controller
              name="etablissement_id"
              control={control}
              //   defaultValue={
              //     etablissements?.find(
              //       (option) => option?.value === etablissementID
              //     )?.label ?? ""
              //   }
              render={({ field: { onChange, value } }) => (
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid black",
                      fontSize: "12px",
                      color: "#000000",
                      //   fontWeight: "bold",
                      height: "50px",
                      outline: "none",
                    }),
                  }}
                  value={
                    value && {
                      value: value,
                      label:
                        etablissements?.find(
                          (option) => option?.value === etablissementID
                        )?.label ?? "",
                    }
                  }
                  options={etablissements}
                  onChange={(item) => onChange(item.value)}
                  className="w-100 input-form border-bottom-0 ps-0"
                  isSearchable
                  isLoading={loadingEtablissements}
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Établissements (Ex: ISI, ISEP, UCAD, UNCHK...)"
                />
              )}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "-8px",
                padding: "0 10px",
                color: "red",
              }}
            >
              {errors?.etablissement_id?.message}
            </p>
          </div>

          <div className="w-25">
            <Controller
              name="genre"
              control={control}
              //   defaultValue={
              //     genreOptions?.find((option) => option?.label === genreType) ??
              //     ""
              //   }
              render={({ field: { onChange, value } }) => (
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid black",
                      fontSize: "12px",
                      color: "#000000",
                      //   fontWeight: "bold",
                      height: "50px",
                      outline: "none",
                    }),
                  }}
                  value={
                    value && {
                      value: value,
                      label:
                        genreOptions?.find(
                          (option) => option?.value === genreType
                        )?.label ?? "",
                    }
                  }
                  options={genreOptions}
                  onChange={(item) => onChange(item.value)}
                  className="w-100 input-form border-bottom-0 pe-0"
                  isSearchable
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Genre"
                />
              )}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "-8px",
                padding: "0 10px",
                color: "red",
              }}
            >
              {errors.genre?.message}
            </p>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className="w-50">
            <Controller
              name="niveau_etude"
              control={control}
              //   defaultValue={""}
              render={({ field: { onChange, value } }) => (
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid black",
                      fontSize: "12px",
                      color: "#000000",
                      // fontWeight: "bold",
                      height: "50px",
                      outline: "none",
                    }),
                  }}
                  options={levelOptions}
                  value={value && { value: value, label: value }}
                  onChange={(item) => onChange(item.value)}
                  className="w-100 input-form border-bottom-0 ps-0"
                  isSearchable
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Niveau d'étude"
                />
              )}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "-8px",
                padding: "0 10px",
                color: "red",
              }}
            >
              {errors.niveau_etude?.message}
            </p>
          </div>

          <div className="w-50">
            <Controller
              name="nb_an_experiences"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, value } }) => (
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid black",
                      fontSize: "12px",
                      color: "#000000",
                      // fontWeight: "bold",
                      height: "50px",
                      outline: "none",
                    }),
                  }}
                  value={
                    value && {
                      value: value,
                      label: `${value} an${value >= 2 ? "s" : ""}`,
                    }
                  }
                  options={experienceOptions}
                  onChange={(item) => onChange(item.value)}
                  className="w-100 input-form border-bottom-0 pe-0"
                  isSearchable
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Niveau d'expérience"
                />
              )}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "-8px",
                padding: "0 10px",
                color: "red",
              }}
            >
              {errors.nb_an_experiences?.message}
            </p>
          </div>
        </div>

        <Controller
          name="fonction"
          control={control}
          defaultValue={""}
          render={({ field: { onChange, value } }) => (
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: "1px solid black",
                  fontSize: "12px",
                  color: "#000000",
                  //   fontWeight: "bold",
                  height: "50px",
                  outline: "none",
                }),
              }}
              value={value && { value: value, label: value }}
              options={functionOptions}
              onChange={(item) => onChange(item.value)}
              className="w-100 input-form border-bottom-0 px-0"
              isSearchable
              noOptionsMessage={() => "Pas d'options"}
              placeholder="Fonction (Ex: développeur frontend, Etudiant en commerce..)"
            />
          )}
        />
        <p
          style={{
            fontSize: "12px",
            marginTop: "-8px",
            padding: "0 10px",
            color: "red",
          }}
        >
          {errors.fonction?.message}
        </p>

        <div className="text-end mt-3">
          <ButtonLoading
            loading={!isLoading}
            text={"Enregistrer"}
            className="btn-apply"
          />
        </div>
      </form>
    </CustomModal>
  );
};

export default EditProfileModal;
