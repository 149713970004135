import { CONTACT_US_PATH } from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const contactUsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    contactUs: builder.mutation({
      query: (payload) => ({
        url: CONTACT_US_PATH,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
  }),
});

export const { useContactUsMutation } = contactUsSlice;
