import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/shared/Button";
import { ETABLISSEMENT_HOME_PAGE_PATH } from "../../constants/routes_path";
// import Main from "../../layouts/Main/Main";
import { useAddEtudiantsMutation } from "../../redux/slices/etablissements/etablissementsSlice";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";

const AjoutEtudiant = () => {
  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    email: Yup.string().required("Obligatoire"),
    location: Yup.string().required("Obligatoire"),
    phone: Yup.string().required("Obligatoire"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit } = useForm(formOptions);
  //   const { errors } = formState;

  const [addEtudiant, { isLoading }] = useAddEtudiantsMutation();

  const etablissementInfo = JSON.parse(
    localStorage.getItem("etablissementInfos")
  );

  const navigate = useNavigate();

  const handleAddEtudiant = (data) => {
    addEtudiant(data)
      .unwrap()
      .then((res) => {
        navigate(ETABLISSEMENT_HOME_PAGE_PATH);
        toastAlertSuccess(res.message);
      })
      .catch((err) => {
        toastAlertError(err.message);
      });
  };

  return (
    <div className="registerContainer p-0 mt-5">
      <div className="title">
        {/* <img src={logo} alt="" className="taille-img" /> */}
        <h4 className="mb-3 mt-3 fs-5 text-center">Ajouter un étudiant</h4>
      </div>
      <form
        style={{ maxWidth: '800px' }}
        className="mb-3 mx-auto"
        onSubmit={handleSubmit((data) => {
          data.etablissement_id = etablissementInfo.id;
          handleAddEtudiant(data);
        })}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nom complet
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                {...register("name")}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                {...register("email")}
                pattern="^[a-zA-Z0-9]+@gmail\.com$"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Téléphone
              </label>
              <input
                type="tel"
                className="form-control"
                iid="phone"
                {...register("phone")}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="location" className="form-label">
                Adresse
              </label>
              <input
                type="text"
                className="form-control"
                id="location"
                {...register("location")}
              />
            </div>
          </div>
        </div>

        <div className="btnContainer btnInsc">
          <Button type="submit" hasCTA className="btn-apply w-100 mt-3">
            {isLoading ? "Ajout en cours" : "Ajouter"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AjoutEtudiant;
