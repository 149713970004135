import {
  ADD_FROMATION_API_ROUTE,
  DELETE_FORMATION_API_ROUTE,
  EDIT_FORMATION_API_ROUTE,
  GET_ETUDIANT_FORMATIONS_API_ROUTE,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

const formationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEtudiantFormations: builder.query({
      query: (user_id) => `${GET_ETUDIANT_FORMATIONS_API_ROUTE}${user_id}`,
      providesTags: ["Formations"],
    }),

    ajouterFormationEtudiant: builder.mutation({
      query: (payload) => ({
        url: `${ADD_FROMATION_API_ROUTE}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Formations"],
    }),

    modifierFormationEtudiant: builder.mutation({
      query: (args) => {
        const { formation_id, payload } = args;
        return {
          url: `${EDIT_FORMATION_API_ROUTE}${formation_id}`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Formations"],
    }),

    supprimerFormationEtudiant: builder.mutation({
      query: (formation_id) => ({
        url: `${DELETE_FORMATION_API_ROUTE}${formation_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Formations"],
    }),
  }),
});

export const {
  useGetEtudiantFormationsQuery,
  useAjouterFormationEtudiantMutation,
  useModifierFormationEtudiantMutation,
  useSupprimerFormationEtudiantMutation,
} = formationSlice;
