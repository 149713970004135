import { ADD_MOTIVATION_API_ROUTE, DELETE_ETUDIANT_MOTIVATION_API_ROUTE, EDIT_ETUDIANT_MOTIVATION_API_ROUTE, GET_ETUDIANT_MOTIVATION_API_ROUTE } from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

const motivationSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        ajouterMotivation: builder.mutation({
            query: (payload) => ({
                url: `${ADD_MOTIVATION_API_ROUTE}`,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["Motivations"],
        }),

        getEtudiantMotivation: builder.query({
            query: (user_id) => `${GET_ETUDIANT_MOTIVATION_API_ROUTE}${user_id}`,
            providesTags: ["Motivations"],
        }),

        modifierMotivationEtudiant: builder.mutation({
            query: (args) => {
                const { motivation_id, payload } = args;
                return {
                    url: `${EDIT_ETUDIANT_MOTIVATION_API_ROUTE}${motivation_id}`,
                    method: "POST",
                    body: payload,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            invalidatesTags: ["Motivations"],
        }),
        supprimerMotivationEtudiant: builder.mutation({
            query: (motivation_id) => ({
                url: `${DELETE_ETUDIANT_MOTIVATION_API_ROUTE}${motivation_id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Motivations"],
        }),
    })
})

export const { useAjouterMotivationMutation, useGetEtudiantMotivationQuery, useModifierMotivationEtudiantMutation, useSupprimerMotivationEtudiantMutation } = motivationSlice