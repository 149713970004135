import Badge from "react-bootstrap/Badge";
import { Link, NavLink } from "react-router-dom";
import Button from "../../components/shared/Button";
// import Dropdown from 'react-bootstrap/Dropdown';

import { AiOutlineLogout } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvatarInitials from "../../components/AvatarInitials";
import {
  ENTREPRISE_HOME_PAGE_PATH,
  ETABLISSEMENT_ENTREPRISE_PATH,
  ETABLISSEMENT_HOME_PAGE_PATH,
  ETABLISSEMENT_PATH,
  ETUDIANT_CANDIDATURES_PAGE_PATH,
  ETUDIANT_ENTREPRISES_PAGE_PATH,
  ETUDIANT_HOME_PAGE_PATH,
  LOGIN_PATH,
  MY_PROFILE_PAGE,
  OFFRES_PATH,
} from "../../constants/routes_path";
import { apiSlice } from "../../redux/slices/apiSlice/apiSlice";
import { useLogoutMutation } from "../../redux/slices/authSlice/authApiSlice";
import "./navbar.css";

const Navbar = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Logout
  const [logout] = useLogoutMutation();

  const doLogOut = async () => {
    await logout()
      .unwrap()
      .then((payload) => {
        if (payload.success === true) {
          navigate(LOGIN_PATH);
          dispatch(apiSlice.util.resetApiState());
          localStorage.clear();
        }
      })
      .catch((error) => {});
  };
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary my-nav py-0">
      <div className="container-fluid align-items-center py-2">
        {/* Bakeli carriere */}

        <Link
          className="navbar-brand"
          to={
            user?.type === "etudiant"
              ? ETUDIANT_HOME_PAGE_PATH
              : user?.type === "etablissement"
              ? ETABLISSEMENT_HOME_PAGE_PATH
              : ENTREPRISE_HOME_PAGE_PATH
          }
        >
          <div className="d-xl-inline-block d-lg-inline-block d-md-inline-block d-sm-none d-none">
            <span className="bakeli-color">Bakeli</span>
            <span className="carriere-color">Carriere</span>
          </div>
          &nbsp;&nbsp;
          <Badge bg="warning" style={{ fontFamily: "ProximaNovaSemibold" }}>
            {user?.type === "etudiant"
              ? "Etudiant"
              : user?.type === "etablissement"
              ? "Etablissement"
              : user?.type === "entreprise"
              ? `${user?.name}`
              : "Entreprise"}
          </Badge>
        </Link>

        <button
          style={{ border: "none" }}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBackdrop"
          aria-controls="offcanvasWithBackdrop"
        >
          {/* <span className="navbar-toggler-icon"></span> */}
          <BiMenu size={30} />
        </button>
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasWithBackdrop"
          aria-labelledby="offcanvasWithBackdropLabel"
        >
          <div className="offcanvas-header">
            {/* <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              Offcanvas
            </h5> */}
            <div className="offcanvas-title">
              <span className="bakeli-color">Bakeli</span>
              <span className="carriere-color">Carriere</span>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              data-bs-target="#offcanvasWithBackdrop"
            ></button>
          </div>

          {/* NavLinks for Etudiants */}
          {user?.type === "etudiant" && (
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-0 gap-2 align-items-center">
                {/* <li className="nav-item me-sm-3">
                  <IoMdNotificationsOutline size={25} />
                </li> */}
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  data-bs-target="#offcanvasWithBackdrop"
                >
                  <NavLink className="nav-link" to={""} end>
                    Offres
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  data-bs-target="#offcanvasWithBackdrop"
                >
                  <NavLink
                    className="nav-link"
                    to={
                      ETUDIANT_HOME_PAGE_PATH + ETUDIANT_ENTREPRISES_PAGE_PATH
                    }
                  >
                    Entreprises
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  data-bs-target="#offcanvasWithBackdrop"
                >
                  <NavLink
                    className="nav-link"
                    to={ETUDIANT_HOME_PAGE_PATH + ETABLISSEMENT_PATH}
                  >
                    Etablissements
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  data-bs-target="#offcanvasWithBackdrop"
                >
                  <NavLink
                    className="nav-link"
                    to={
                      ETUDIANT_HOME_PAGE_PATH + ETUDIANT_CANDIDATURES_PAGE_PATH
                    }
                  >
                    Mes candidatures
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  data-bs-target="#offcanvasWithBackdrop"
                >
                  <NavLink
                    className="nav-link"
                    to={ETUDIANT_HOME_PAGE_PATH + MY_PROFILE_PAGE}
                  >
                    Profil
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  data-bs-target="#offcanvasWithBackdrop"
                >
                  <Button onClick={doLogOut} className="btn-red w-100">
                    Déconnexion
                  </Button>
                </li>

                {/* <button
                  // style={{ width: '20px', height: '20px' }}
                  type="button"
                  className="btn btn-secondary dropdown-toggle mx-1 bg-transparent text-dark border-0 p-0 d-flex align-items-center gap-1"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                  aria-expanded="false"
                >
                  <AvatarInitials data={user} />
                  <span className="mt-2">{user?.name}</span>
                </button>
                <ul className="dropdown-menu dropdown-menu-lg-end myCustomDropdown p-3">
                  <li
                    className="dropdown-item my-2 p-0"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    data-bs-target="#offcanvasWithBackdrop"
                  >
                    <Link
                      className="profil-dropdown text-light"
                      to={ETUDIANT_HOME_PAGE_PATH + MY_PROFILE_PAGE}
                    >
                      Profil
                    </Link>
                  </li>
                  <li className="dropdown-item w-100 p-0">
                    <Button onClick={doLogOut} className="btn-red w-100">
                      Déconnexion
                    </Button>
                  </li>
                </ul> */}
              </ul>
              {/* <div className="btn-group d-flex"> */}
            </div>
            // </div>
          )}

          {/* NavLinks for Etablissements */}
          {user?.type === "etablissement" && (
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item">
                  <NavLink className="nav-link" to={""} end>
                    Etudiants
                  </NavLink>
                </li>
                <li className="nav-item px-2">
                  <NavLink
                    className="nav-link"
                    to={ETABLISSEMENT_HOME_PAGE_PATH + OFFRES_PATH}
                  >
                    Offres
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={
                      ETABLISSEMENT_HOME_PAGE_PATH +
                      ETABLISSEMENT_ENTREPRISE_PATH
                    }
                  >
                    Entreprises
                  </NavLink>
                </li>
                <button
                  // style={{ width: '20px', height: '20px' }}
                  type="button"
                  className="btn btn-secondary dropdown-toggle mx-1 bg-transparent text-dark border-0 p-0 ms-4"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                  aria-expanded="false"
                >
                  Mon compte
                  {/* <AvatarInitials data={user} /> */}
                </button>
                <ul className="dropdown-menu dropdown-menu-lg-end myCustomDropdown p-3">
                  <li
                    className="dropdown-item my-2 p-0"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    data-bs-target="#offcanvasWithBackdrop"
                  >
                    {/* <Link
                      className="p-2 fs-6 text-center w-100 btn btn-outline-success rounded mb-2 d-block "
                      to={ETABLISSEMENT_HOME_PAGE_PATH + ETABLISSEMENT_PROFIL_PATH}
                    >
                      Mon compte
                    </Link> */}
                  </li>
                  <li className="dropdown-item w-100 p-0">
                    <Button onClick={doLogOut} className="btn-red w-100">
                      Déconnexion
                    </Button>
                  </li>
                </ul>
              </ul>
              {/* <Button type="" onClick={doLogOut} className="btn-red">
                Se déconnecter
              </Button> */}

              {/* <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Mon compte
                </Dropdown.Toggle>

                <Dropdown.Menu className="p-2">
                  <Link
                    className="p-2 fs-6 text-center w-100 btn btn-outline-success rounded mb-2 d-block "
                    to={ETABLISSEMENT_HOME_PAGE_PATH + ETABLISSEMENT_PROFIL_PATH}
                  >
                    Mon compte
                  </Link>
                  <Button type="" onClick={doLogOut} className="btn-red w-100 p-2">
                    Se déconnecter
                  </Button>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          )}

          {/* NavLinks for Etablissements */}
          {user?.type === "entreprise" && (
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                {/* <ResourceDataMap
                  resourceItem={NavbarLinkItem}
                  resourceData={entrepriseLinks}
                  resourceName="linkItem"
                /> */}
                <Button
                  tooltipContent={"Déconnexion"}
                  type=""
                  onClick={doLogOut}
                  className="btn-red"
                >
                  {/* <IoLogOutOutline /> */}
                  <AiOutlineLogout />
                </Button>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
