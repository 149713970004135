import React from 'react'
import LoaderSpinner from '../../../components/LoaderSpinner'
import { MdOutlineLocalOffer, MdOutlinePeopleOutline } from 'react-icons/md'

const LandingPageEntrepriseBanniere = ({ isLoading, name, effectif = 0, secteur = '', banniere }) => {
    return (
        <div
            style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgb(255, 255, 255)), url(${banniere})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '50vh'
            }}
            className="shadow-sm"
        >
            <div
                style={{ color: '#00172d' }}
                className=' p-3 d-flex flex-column justify-content-end h-100'
            >
                <h1 className='fs-2'>{!isLoading ? name ?? '' : <LoaderSpinner />}</h1>
                {
                    effectif ? <p
                        className='d-flex align-items-center gap-1'
                    >
                        <MdOutlinePeopleOutline size={25} />
                        <span className='fw-bold'>{effectif} Employés</span>
                    </p> : null
                }

                {
                    secteur ? <p>
                        <MdOutlineLocalOffer size={20} />
                        <span className='fw-bold'>{secteur}</span>
                    </p> : null
                }

            </div>
        </div>
    )
}

export default LandingPageEntrepriseBanniere