import styled from "styled-components";

export const Nav = styled.div(({ style }) =>
    style
        ? style
        : {
            color: "#333",
            display: "flex",
            alignItems: "center",
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: "1rem 0",
            gap: 15,
            '@media (max-width: 500px)': {
                justifyContent: 'flex-start',
            }
        }
);

export const Button = styled.button.attrs({
    type: "button",
    role: "tab"
})(({ style, isActive }) =>
    Boolean(style)
        ? style
        : {
            borderRadius: 5,
            border: "none",
            borderBottom: `1.5px solid ${isActive ? "transparent" : "#333333"}`,
            backgroundColor: isActive ? "#009688" : 'transparent',
            // background: "none",
            outline: "none",
            padding: "6px 12px",
            fontWeight: 600,
            fontSize: "16px",
            color: isActive ? "#fff" : "#333333",
            cursor: "pointer",
            transition: "color 150ms linear",
            minWidth: '150px',
            "&:hover": {
                color: isActive ? "#FFFFFF" : "#FFFFFF",
                backgroundColor: '#009688'
            },
            '@media (max-width: 600px)': {
                fontSize: '12px',
                flex: 2,
            }

        }
);
