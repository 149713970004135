import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const EtablissementDataMapLanding = ({ currentItems }) => {
  return (
    <div className="row-entreprise gap-4">
      {currentItems.map((item, index) => (
        <div
          style={{
            boxShadow: "0 0 0 1px #3ac1b3b7, 0 2px 3px #3ac1b3b7",
            cursor: "pointer",
          }}
          className="card"
        >
          <div className="card-body position-relative">
            <div className="card-body-text">
              <h5 className="card-title fw-bold">{item?.user?.name}</h5>
              <p className="card-text mb-0 d-flex align-items-center">
                <span>
                  <FeatherIcon icon="mail" size="16" />
                </span>
                <span className="dep-list-profil-entreprise ms-2">
                  {item?.user?.email}
                </span>
              </p>

              {/* <p className="card-text mb-0 d-flex align-items-center">
                <span>
                  <FeatherIcon icon="phone" size="16" />
                </span>
                <span className="dep-list-profil-entreprise ms-2">
                  {item?.user?.phone}
                </span>
              </p> */}

              <p className="card-text mb-0 d-flex align-items-center">
                <span>
                  <FeatherIcon icon="map-pin" size="16" />
                </span>
                <span className="dep-list-profil-entreprise ms-2">
                  {item?.user?.location}
                </span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EtablissementDataMapLanding;
