import React, { memo } from "react";
import {
    HiLocationMarker,
    HiOutlineClock,
    HiOutlinePaperClip,
} from "react-icons/hi";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import { dayJsFormat } from "../../../utils/dayJsFormat";
import { onError } from "../../../utils/onError";

const dayjs = require("dayjs");

dayJsFormat();

const CardPane = styled.div`
  padding: 16px;
  gap: 17px;
  isolation: isolate;
  background: #fff;
  border: 1px solid #d6cee4;
  box-shadow: 0 5px 5px rgba(213, 205, 227, 0.5);
  border-radius: 10px;
  cursor: pointer;
  border: ${({ active }) => (active ? "2px solid #009688" : null)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CompanyLogoPane = styled.img`
  object-fit: contain;
  background-position: 50%;
  background-size: 70%;
  background-color: #fff;
  background-repeat: no-repeat;
  border: 3px solid #d6cee4;
  border-radius: 50%;
`;
const CardBody = styled.div`
  width: 100%;
`;
const CardTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #0d0140;
`;
const TruncateParagraphe = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const OffreCardItem = (
  {
    offre = {
      titre: "",
      offre_type: "",
      created_at: "",
      entreprise: { user: {} },
    },
    navigateToDetails,
  },
  ref
) => {
  const { offreId } = useSelector((state) => state.offres);
  const { id, titre, offre_type, created_at, entreprise } = offre;

  return (
    <CardPane
      // ref={cardRed}
      id={id + offre_type}
      active={offre.id === offreId ? true : false}
      onClick={() =>
        navigateToDetails({
          offre_id: offre.id,
          offre_name: offre.titre,
          refId: id + offre_type,
        })
      }
    >
      <div className="row d-flex align-items-center">
        <div className="col-lg-4 col-md-3 d-flex align-items-center justify-content-center">
          <CompanyLogoPane
            src={`${process.env.REACT_APP_BASE_URL}entreprisesLogos/${entreprise?.logo}`}
            alt="company"
            /* onError={onError} */
            loading="lazy"
            width="60px"
            height="60px"
          />
        </div>
        <div className="col-lg-8 col-md-9">
          <span>
            <h6>{entreprise?.user?.name}</h6>
          </span>
        </div>
      </div>
      <CardBody>
        <CardTitle>{titre}</CardTitle>
        <div className="d-flex align-items-center mt-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              fillRule="evenodd"
              d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
              clipRule="evenodd"
            />
          </svg>

          {offre_type}
        </div>

        <div className="d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>

          <TruncateParagraphe>
            {entreprise?.user?.location ? entreprise.user.location : "..."}
          </TruncateParagraphe>
        </div>
        <div className="d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="me-2"
            width={16}
            height={16}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>

          <TruncateParagraphe>
            Il y à {dayjs(created_at).fromNow(true)}
          </TruncateParagraphe>
        </div>
      </CardBody>
      <div className="mt-2">
        {!offre?.date_expiration ? (
          <span className="text-warning fs-6">
            {" "}
            Pas de date d'expiration specifiée
          </span>
        ) : new Date(offre?.date_expiration) < new Date() ? (
          <span className="fw-bold text-danger fs-6">Offre expirée 🚫</span>
        ) : (
          <p className="text-success">
            Disponible jusqu'au{" "}
            {new Date(offre?.date_expiration).toLocaleString("fr-Fr", {
              dateStyle: "short",
            })}
          </p>
        )}
      </div>
    </CardPane>
  );
};

export default memo(OffreCardItem);
