import { createSlice } from "@reduxjs/toolkit";

const candidature = createSlice({
  name: "candidatures",
  initialState: {
    selectedCandidature: {},
    allCandidaturesList: [],
    candidaturesList: [],
    currentCandidatureDate: null,
    selectedCandidatureId: null,
    currentCandidatureOffre: "",
    currentDomaineCandidats: null,
    currentDomaineId: null,
  },
  reducers: {
    setCandidatureList: (state, { payload }) => {
      state.allCandidaturesList = payload;
      state.candidaturesList = payload;
    },
    setSelectedCandidature: (state, { payload }) => {
      state.selectedCandidature = payload;
    },
    setCurrentDomaineCandidats: (state, { payload }) => {
      return {
        ...state,
        currentDomaineCandidats: payload,
      };
    },
    setCurrentDomaineId: (state, { payload }) => {
      return {
        ...state,
        currentDomaineId: payload,
      };
    },
    resetSelectedCandidature: (state) => {
      state.selectedCandidature = null;
    },
    setSelectedCandidatureId: (state, { payload }) => {
      state.selectedCandidatureId = payload;
    },
    filterCandidatureByDateCandidature: (state, { payload }) => {
      state.currentCandidatureDate = payload;
      // const newCandidaturesList = state.allCandidaturesList.filter(candidature => {
      //     let created_at = new Date(candidature.created_at)
      //     let date = new Date(payload)

      //     created_at.setHours(0, 0, 0)
      //     date.setHours(0, 0, 0)

      //     return created_at === date
      // }
      // )
      // return {
      //     ...state,
      //     currentCandidatureDate: payload,
      //     candidaturesList: newCandidaturesList
      // }
      // state.currentCandidatureDate = payload

      // if (payload === '') {
      //     state.candidaturesList = state.allCandidaturesList
      // } else {
      //     state.candidaturesList = state.allCandidaturesList.filter(candidature => {
      //         let champs = [candidature.created_at]
      //         let txt = champs.join(' ').toLowerCase()
      //         return txt.includes(payload.toLowerCase())
      //     })
      // }
    },
    filterCandidatureByOffre: (state, { payload }) => {
      state.currentCandidatureOffre = payload;
      if (payload === "") {
        state.candidaturesList = state.allCandidaturesList;
      } else {
        state.candidaturesList = state.allCandidaturesList.filter(
          (candidature) => {
            let champs = [
              candidature.offre.titre,
              candidature.offre.offre_type,
            ];
            let txt = champs.join(" ").toLowerCase();
            return txt.includes(payload.toLowerCase());
          }
        );
      }
    },
  },
});

export const {
  setSelectedCandidature,
  resetSelectedCandidature,
  setCandidatureList,
  filterCandidatureByDateCandidature,
  filterCandidatureByOffre,
  setSelectedCandidatureId,
  setCurrentDomaineCandidats,
  setCurrentDomaineId,
} = candidature.actions;

export default candidature.reducer;
