import {
    ADD_ENTREPRISE_DOMAINES_API_ROUTE,
    DELETE_ENTREPRISE_DOMAINE_API_ROUTE,
    EDIT_ENTREPRISE_DOMAINE_API_ROUTE,
    GET_ALL_AVAILABLE_DOMAINES_API_ROUTE,
    GET_ALL_ENTREPRISES_DOMAINES_API_ROUTE,
    GET_DETAILS_DOMAINE_BY_ID_API_ROUTE
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const entreprisesDomainesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEntrepriseDomaine: builder.query({
            query: (id_entreprise) => GET_ALL_ENTREPRISES_DOMAINES_API_ROUTE + id_entreprise,
            keepUnusedDataFor: 60,
            providesTags: ["Entreprise_Domaines"]
        }),
        getAvailableDomaines: builder.query({
            query: (id_entreprise) => GET_ALL_AVAILABLE_DOMAINES_API_ROUTE + id_entreprise,
            keepUnusedDataFor: 60,
            providesTags: ["Entreprise_Domaines"]
        }),
        add: builder.mutation({
            query: ({ data, id_entreprise }) => {
                return {
                    url: ADD_ENTREPRISE_DOMAINES_API_ROUTE + id_entreprise,
                    body: data,
                    method: "POST"
                }
            },
            invalidatesTags: ["Entreprise_Domaines"]
        }),


        // domaines_by_entreprise/{domaine_id}
        getDetailsDomaineById: builder.query({
            query: (domaine_id) => GET_DETAILS_DOMAINE_BY_ID_API_ROUTE + domaine_id,
            keepUnusedDataFor: 60,
            providesTags: ["Entreprise_Domaines"]
        }),



        edit: builder.mutation({
            query: ({ data, domaine_id }) => {
                return {
                    url: EDIT_ENTREPRISE_DOMAINE_API_ROUTE + domaine_id,
                    body: data,
                    method: "POST"
                }
            },
            invalidatesTags: ["Entreprise_Domaines"]
        }),
        delete: builder.mutation({
            query: ({ entreprise_id, domaine_id }) => {
                return {
                    url: DELETE_ENTREPRISE_DOMAINE_API_ROUTE + entreprise_id + '/' + domaine_id,
                    method: "DELETE"
                }
            },
            invalidatesTags: ["Entreprise_Domaines"]
        }),
    })
});

export const {
    useGetEntrepriseDomaineQuery,
    useGetDetailsDomaineByIdQuery,
    useGetAvailableDomainesQuery,
    useAddMutation,
    useEditMutation,
    useDeleteMutation
} =
    entreprisesDomainesSlice;
