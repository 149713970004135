import React from "react";
import { BiLinkAlt } from "react-icons/bi";
import { FaFilePdf, FaImage } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import LinkBadge from "../../projet/components/LinkBadge";

const CertificationListItem = ({
  certification,
  editCertification,
  openCertificationImages,
}) => {
  const { name, description, lien_certification, images, pdf_file } =
    certification;
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="card p-2 mb-2">
      <div className="d-flex justify-content-between align-items-center mb-1">
        <h6 className="fs-6">{name}</h6>
        {user?.type !== "entreprise" && (
          <button
            className="btn btn-edit p-0 px-2 border"
            onClick={() => editCertification(certification)}
          >
            <MdEdit size={15} />
          </button>
        )}
      </div>
      <p className="fs-6">{description}</p>
      <div className="d-flex flex-wrap gap-2">
        {/* {
                    lien_depot ? <LinkBadge
                        icon={<FaGithubAlt size={13.5} />}
                        link={lien_depot}
                        text="Lien dépôt"
                        bgColor="#4b4453"
                    /> : null
                } */}
        {lien_certification ? (
          <LinkBadge
            icon={<BiLinkAlt size={15} />}
            link={lien_certification}
            text="Certification"
            bgColor="#534439"
          />
        ) : null}

        {images?.length ? (
          <span
            onClick={() => openCertificationImages(certification)}
            style={{
              backgroundColor: "#845ec2",
              color: "white",
              fontWeight: 500,
              cursor: "pointer",
            }}
            className="rounded px-2 d-flex align-items-center"
          >
            <FaImage size={13.5} />{" "}
            <span style={{ fontSize: "11px" }} className="ms-1">
              Voir
            </span>
          </span>
        ) : null}

        {pdf_file ? (
          <a
            href={`${process.env.REACT_APP_BASE_URL}pdfCertifications/${pdf_file?.pdf_file}`}
            target="_blank"
            rel="noreferrer"
          >
            <span
              style={{
                backgroundColor: "#845ec2",
                color: "white",
                fontWeight: 500,
                cursor: "pointer",
              }}
              className="rounded px-2 d-flex align-items-center"
            >
              <FaFilePdf size={13.5} />{" "}
              <span style={{ fontSize: "11px" }} className="ms-1">
                Voir
              </span>
            </span>
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default CertificationListItem;
