import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonLoading from "../../../components/ButtonLoading";
import CustomModal from "../../../components/CustomModal";
import FileUpload from "../../../components/FileUpload";
import Input from "../../../components/shared/Input";
import TextArea from "../../../components/shared/TextArea";
import {
  DELETE_PROJECT_IMAGE,
  EDIT_ETUDIANT_PROJET_API_ROUTE,
} from "../../../constants/api_endpoints";
import { useUserId } from "../../../hooks/useUserId";
import { useGetProfileCompletionRateQuery } from "../../../redux/slices/etudiants/etudiantsSlice";
import { useSupprimerProjetEtudiantMutation } from "../../../redux/slices/etudiants/projetSlice";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";

const EditAddProjetModal = ({ show, close, projet, refetchProject }) => {
  const { userId } = useUserId();
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [files, setFiles] = useState([]);
  const [removeFileEventActive, setRemoveFileEventActive] = useState(false);

  useEffect(() => {
    setFiles(projet?.images);
    setRemoveFileEventActive(true);
  }, [close, projet?.images]);

  const {
    register,
    handleSubmit,
    reset,
    formState: errors,
  } = useForm({
    defaultValues: {
      name: projet.name,
      description: projet.description,
      lien_depot: projet?.lien_depot,
      link_project: projet?.link_project,
      projet_id: projet?.id,
    },
    values: {
      name: projet.name,
      description: projet.description,
      lien_depot: projet?.lien_depot,
      link_project: projet?.link_project,
      projet_id: projet?.id,
    },
  });
  const [supprimerProjet] = useSupprimerProjetEtudiantMutation();

  const submitForm = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data?.description);
    formData.append("lien_depot", data?.lien_depot);
    formData.append("link_project", data?.link_project);
    formData.append("project_id", data?.projet_id);

    for (let i = 0; i < files.length; i++) {
      formData.append("url[]", files[i].file);
    }

    const url = `${process.env.REACT_APP_API_URL}${EDIT_ETUDIANT_PROJET_API_ROUTE}`;

    setLoading(true);
    axios
      .post(url, formData)
      .then((res) => {
        refetchProject();
        toastAlertSuccess("Projet modifié");
        setFiles([]);
      })
      .catch((err) => {
        toastAlertError("Une erreur est survenue");
        setFiles([]);
      })
      .finally(() => {
        setLoading(false);
        close();
        setFiles([]);
      });
  };

  // Get Profile completion rate
  const { refetch: refetchCompletionRate } =
    useGetProfileCompletionRateQuery(userId);

  const supprimerProjetFunc = () => {
    setIsDeleting(true);
    supprimerProjet({ projet_id: projet.id })
      .then((res) => {
        refetchProject();
        refetchCompletionRate();
      })
      .catch((err) => {})
      .finally(() => {
        setIsDeleting(false);
        close();
      });
  };

  const handleRemoveFile = (fileId) => {
    return fileId
      ? axios
          .delete(
            `${process.env.REACT_APP_API_URL}${DELETE_PROJECT_IMAGE}${fileId}`
          )
          .then((res) => {
            toastAlertSuccess("Image supprimé");
            // refetchProject();
          })
          .catch((err) => {
            toastAlertError("Une erreur est survenue");
            // refetchProject();
          })
      : null;
  };

  return (
    <CustomModal
      modalTitle="Modifier projet"
      show={show}
      handleClose={() => {
        close();
        reset();
        setFiles([]);
      }}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          type="text"
          placeholder="Gestion de budget"
          label="Nom projet"
          register={{ ...register("name") }}
          error={errors.name}
          required
          htmlFor="name"
          minLength="5"
          maxLength="90"
        />
        <Input
          type="url"
          placeholder="www.github.com/lka/gestion-budget"
          label="Lien dépôt"
          html="lien_depot"
          // pattern="https://www.*"
          register={{ ...register("lien_depot") }}
          error={errors.lien_depot}
        />
        <Input
          type="url"
          placeholder="www.geston-budget.bakeli.tech"
          label="Lien projet"
          htmlFor="link_project"
          register={{ ...register("link_project") }}
          error={errors.link_project}
        />
        <FileUpload
          labelIdle="Choisir au moins 5 image de votre projet"
          acceptedFileTypes={["image/png", "image/jpeg"]}
          name={"url[]"}
          multiple
          maxFiles={5}
          filesSource={files?.map((item) => ({
            source: `${process.env.REACT_APP_BASE_URL}imagesProjects/${item?.url}`,
            options: {
              file: {
                name: item?.url,
                size: 3001025,
                type: "image/png",
                id: item?.id,
              },
              type: "local",
              metadata: {
                poster: `${process.env.REACT_APP_BASE_URL}imagesProjects/${item?.url}`,
              },
            },
          }))}
          imagePreviewHeight={150}
          imageCropAspectRatio="1:1"
          imageResizeTargetWidth={150}
          imageResizeTargetHeight={150}
          loadFile={false}
          allowRemove={removeFileEventActive}
          beforeRemoveFile={(file) => {
            if (removeFileEventActive) {
              handleRemoveFile(file?.file?.id);
            }
          }}
          onaddfilestart={() => setLoading(true)}
          onaddfile={() => setLoading(false)}
          onupdatefiles={setFiles}
        />
        <TextArea
          minLength="10"
          maxLength="500"
          placeholder="Description du projet"
          label="Description"
          htmlFor="description"
          register={{ ...register("description") }}
          error={errors.description}
        />
        <ButtonLoading
          loading={loading}
          text="Enregistrer"
          className="btn-apply w-100 mt-3"
        />
        <ButtonLoading
          onClick={supprimerProjetFunc}
          type="button"
          loading={isDeleting}
          text="Supprimer"
          className="btn-danger w-100 mt-3"
        />
      </form>
    </CustomModal>
  );
};

export default EditAddProjetModal;
