import { AiFillHome } from "react-icons/ai";
import { IoMdSchool } from "react-icons/io";
import { MdAccountBalanceWallet } from "react-icons/md";
import {
  ENTREPRISE_CANDIDATURES_PAGE_PATH,
  ENTREPRISE_MES_OFFRES_PATH,
  MON_COMPTE,
  PROFILES,
} from "../constants/routes_path";

export const entrepriseLinks = [
  {
    icon: AiFillHome,
    to: "",
    label: "Accueil",
  },
  {
    icon: IoMdSchool,
    to: ENTREPRISE_CANDIDATURES_PAGE_PATH,
    label: "Candidatures",
  },
  {
    icon: AiFillHome,
    to: ENTREPRISE_MES_OFFRES_PATH,
    label: "Offres",
  },
  {
    icon: IoMdSchool,
    to: PROFILES,
    label: "Profils",
  },
  {
    icon: MdAccountBalanceWallet,
    to: MON_COMPTE,
    label: "Mon compte",
  },

  // {
  //     to: ENTREPRISES_PATH,
  //     label: "Entreprises"
  // },
  // {
  //     to: ETABLISSEMENT_HOME_PAGE_PATH,
  //     label: "Etablissements"
  // },
  // {
  //     to: "",
  //     label: "Mes Offres"
  // },
].reverse();
