import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { REFRESH_API_TOKEN } from "../../../constants/api_endpoints";
// import { logOut, setCredentials } from "../authSlice/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: "same-origin",
  prepareHeaders: (headers, { getState }) => {
    const accessToken = localStorage.getItem("access_token");
    // const accessToken = Cookies.get("access_token");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
      headers.set("Content-Type", "application/json");
    }
    return headers;
  },
});

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions);
//   const user = api.getState().auth.user;
//   if (result?.error?.status === 401 || result?.error?.status === 302) {
//     // send the refresh token to get new access token
//     const refreshResult = await baseQuery(
//       { method: "GET", url: REFRESH_API_TOKEN },
//       api,
//       extraOptions
//     );

//     if (refreshResult?.data) {
//       const user = api.getState().auth.user;
//       //    store the new token
//       api.dispatch(setCredentials({ ...refreshResult.data, user }));
//       // retry original query with new access token
//       result = await baseQuery(args, api, extraOptions);
//     } else {
//       api.dispatch(logOut());
//     }
//   }

//   return result;
// };

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: (builder) => ({}),
  keepUnusedDataFor: 60,
});
