import React from "react";
import { BiImport } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DerniersOffresDataMapper from "../../components/DerniersOffresDataMapper";
import ListeEtudiantsDataMapper from "../../components/ListeEtudiantsDataMapper";
import Button from "../../components/shared/Button";
import PaginatedItems from "../../components/shared/PaginationItems";
import { AJOUT_ETUDIANTS_PATH, OFFRES_PATH } from "../../constants/routes_path";
import { useGetEtudiantsByEtablissementQuery } from "../../redux/slices/etablissements/etablissementsSlice";
import { useGetAllOffresQuery } from "../../redux/slices/offres/offresSlice";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
import ImportEtudiantsModal from "./ImportEtudiantsModal";
import "./listeEtudiants.css";
// import { Tooltip } from 'react-tooltip'
// import { useDispatch } from "react-redux";
// import { filterEtudiant, setEtudiantsListe } from "../../redux/reducers/etudiantReducer";

const ListeEtudiants = () => {
  // const { etudiantsListe, currentEtudiantSearch, isSearchingStudent } = useSelector(state => state.etudiants)
  // const dispatch = useDispatch()
  // Initial state
  const [showImportModal, setShowImportModal] = React.useState(false);
  const etablissementInfo = JSON.parse(
    localStorage.getItem("etablissementInfos")
  );
  const accessToken = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const id_etablissement = etablissementInfo?.id;
  const {
    data: etudiants = [],
    isLoading: loadingEtudiants,
    refetch: refetchListeEtudiants,
  } = useGetEtudiantsByEtablissementQuery(id_etablissement);

  const { data: offres, isLoading: loadingEtablissements } =
    useGetAllOffresQuery();

  const redirectPageAjout = () => {
    return navigate(AJOUT_ETUDIANTS_PATH);
  };

  const handleShowImportModal = () => {
    return setShowImportModal(true);
  };

  // Refecth on import success
  const handleRefetchEtudiants = () => {
    refetchListeEtudiants();
  };

  // useEffect(() => {
  //   dispatch(setEtudiantsListe(etudiants))
  // }, [dispatch, etudiantsListe, etudiants, currentEtudiantSearch, isSearchingStudent])

  return (
    <>
      <ImportEtudiantsModal
        show={showImportModal}
        close={() => setShowImportModal(false)}
        accessToken={accessToken}
        etablissement={etablissementInfo}
        refetch={() => handleRefetchEtudiants()}
        user={user}
      />
      <div className="">
        <div className="row px-0">
          <div className="col-lg-8 col-12 mb-5">
            <div className="row border-bottom pb-2 align-items-center">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-6 p-0">
                <h5
                  style={{ fontSize: "18px" }}
                  className="h-theme m-0 fs-5 fs-md-2"
                >
                  Liste des étudiants{" "}
                </h5>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 d-flex align-items-center justify-content-xl-end justify-content-lg justify-content-md-end justify-content-end mb-0 p-0">
                <Button
                  tooltipContent="Ajouter un etudiant"
                  className="btn-add mx-1"
                  onClick={redirectPageAjout}
                >
                  <MdAdd />
                </Button>
                <Button
                  tooltipContent="Importer une liste"
                  className="btn-import mx-1"
                  onClick={handleShowImportModal}
                >
                  <BiImport />
                </Button>
              </div>
            </div>
            {/* <form className="w-100 position-relative">
              <BiSearch className="position-absolute top-50 start-5 translate-middle-y ms-1 " />
              <input
                style={{ backgroundColor: 'transparent' }}
                className="w-100 form-control border-bottom mt-2 bg-transparant ps-4
               "
                onChange={(e) => dispatch(filterEtudiant(e.target.value))}
                type="search"
                name="etudiant"
                value={currentEtudiantSearch}
              />
            </form> */}
            {!loadingEtudiants ? (
              <PaginatedItems itemsPerPage={5} ressourceData={etudiants}>
                <ListeEtudiantsDataMapper />
              </PaginatedItems>
            ) : (
              <div className="d-flex align-items-center justify-content-center mt-2">
                {" "}
                <SpinnerLoaderSearch />
              </div>
            )}
          </div>
          <div className="col-lg-4 col-12 ps-lg-5 mt-4">
            <h5 style={{ fontSize: "16px" }} className="h-theme  text-end">
              Dernières offres
            </h5>
            {loadingEtablissements ? (
              <div className="d-flex align-items-center justify-content-center mt-2">
                <SpinnerLoaderSearch />
              </div>
            ) : (
              // <PaginatedItems
              //   itemsPerPage={5}
              //   ressourceData={offres?.data}
              // >
              // </PaginatedItems>
              <>
                <DerniersOffresDataMapper
                  currentItems={offres?.data.slice(0, 2)}
                />
                <Link
                  style={{ cursor: "pointer" }}
                  className="w-100 text-end fs-6 d-block text-success fw-bold"
                  to={OFFRES_PATH}
                >
                  <span className="border p-2 voir-plus">Voir plus</span>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListeEtudiants;
