import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ETUDIANT_HOME_PAGE_PATH } from "../../constants/routes_path";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";

const LinkedInAuthCallback = () => {
  // STATES
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const initialized = useRef(false);

  const redirectFunction = (data) => {
    if (data?.type === "etudiant") {
      return navigate(ETUDIANT_HOME_PAGE_PATH);
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const fetchToken = async (code) => {
        fetch(
          `${process.env.REACT_APP_API_URL}auth/linkedin/callback?code=${code}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setLoading(false);
            // console.log("Response from LinkedIn auth callback", data);
            localStorage.setItem("access_token", data?.token);
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...data?.user,
              })
            );
            redirectFunction(data?.user);
          })
          .catch((err) => {
            console.error("Error handling LinkedIn callback", err);
          });
      };

      const code = new URLSearchParams(location.search).get("code");
      if (code) {
        fetchToken(code);
      }
    }
  }, [location]);

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      {loading && <SpinnerLoaderSearch />}
    </div>
  );
};

export default LinkedInAuthCallback;
