import { FilePond, registerPlugin } from "react-filepond";
// FilePond plugins
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond/dist/filepond.min.css";

// Register the plugin
registerPlugin(
  // validates files based on input type
  FilePondPluginFileValidateType,

  FilePondPluginFileValidateSize,

  // encodes the file as base64 data
  FilePondPluginFileEncode,

  // previews the image
  FilePondPluginImagePreview,

  // crops the image to a certain aspect ratio
  FilePondPluginImageCrop,

  // resizes the image to fit a certain size
  FilePondPluginImageResize,

  // applies crop and resize information on the client
  FilePondPluginImageTransform,

  FilePondPluginFilePoster
);

const FileUpload = ({
  labelIdle,
  acceptedFileTypes,
  name,
  className,
  filesSource,
  serverUrl,
  loadFile,
  instantUpload,
  onProcessFile,
    multiple = false,
  remove,
  maxFiles,
  ...props
}) => {
  return (
    <FilePond
      labelIdle={labelIdle}
      credits={null}
      acceptedFileTypes={acceptedFileTypes}
      name={name}
      className={className}
      allowMultiple={multiple}
      maxFiles={maxFiles}
      files={filesSource}
      server={{
        process: serverUrl,
        remove: null,
        revert: null,
        fetch: null,
        load: loadFile
          ? (source, load, error, progress, abort, headers) => {
              var myRequest = new Request(source);
              fetch(myRequest, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Expose-Headers": "Content-Disposition",
                },
                mode: "no-cors",
              }).then(function (response) {
                response.blob().then(function (myBlob) {
                  load(myBlob);
                });
              });
            }
          : false,
      }}
      instantUpload={instantUpload}
      onprocessfile={onProcessFile}
      allowImagePreview={true}
      {...props}
    />
  );
};

export default FileUpload;
