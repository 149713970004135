import React from "react";
import PaginatedItems from "../../../components/shared/PaginationItems";
import { SpinnerLoaderSearch } from "../../../utils/spinnersLoader";
import ListeEtudiant from "../offres/ListeEtudiant";

const CandidatsListe = ({
  ressourceData = [],
  ressourceTitle = "Liste des candidats",
  className,
  loadingRessource,
  ressourceOptionalBtnFunction,
  ressourceOptionalBtn,
  ressourceOptionalBtnText,
}) => {
  return (
    <div className={className ?? `col`}>
      <h6 className="fs-6 fw-bold">{ressourceTitle}</h6>
      {loadingRessource ? (
        <SpinnerLoaderSearch />
      ) : (
        <>
          <PaginatedItems itemsPerPage={10} ressourceData={ressourceData}>
            <ListeEtudiant />
          </PaginatedItems>
          {ressourceOptionalBtn && (
            <div className="d-flex align-items-center justify-content-end">
              <button
                onClick={ressourceOptionalBtnFunction}
                className="btn btn-dark"
              >
                {ressourceOptionalBtnText}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CandidatsListe;
