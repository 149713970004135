import { cloneElement } from "react";
import { GridList } from "react-flexible-list";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineLocalOffer, MdOutlinePeopleOutline } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import SearchFilter from "../../../components/SearchFilter";
import {
  ENTREPRISE_HOME_PAGE_PATH,
  ENTREPRISE_MES_OFFRES_PATH,
} from "../../../constants/routes_path";
import useQueryParams from "../../../hooks/useQueryParams";
import { useGetEntrepriseOffreDetailsQuery } from "../../../redux/slices/entreprises/entreprisesSlice";
import { dayJsFormat } from "../../../utils/dayJsFormat";
import { SpinnerLoaderSearch } from "../../../utils/spinnersLoader";
import CandidatListItem from "../components/CandidatListItem";

const dayjs = require("dayjs");

dayJsFormat();

const OffresCandidatureDetails = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { queryParams } = useQueryParams();

  // Convert URLSearchParams object to a plain object
  const paramsObject = {};
  for (const [key, value] of queryParams.entries()) {
    paramsObject[key] = value;
  }

  const {
    data: selectedOffreCandidature,
    isLoading: loadingOffreDetails,
    isFetching: fetchingOffreDetails,
  } = useGetEntrepriseOffreDetailsQuery({ offre_id: id, params: paramsObject });

  const navigateToCandidatDetails = (candidat) => {
    return navigate(
      ENTREPRISE_HOME_PAGE_PATH +
        "/" +
        ENTREPRISE_MES_OFFRES_PATH +
        "/" +
        candidat.id +
        "/candidat"
    );
  };

  const ClonedCandidatListItem = ({ candidat }) =>
    cloneElement(<CandidatListItem candidat={candidat} />, {
      navigateToCandidatDetails,
    });

  return (
    <div>
      {/* <NavigateBack /> */}

      {/* TODO */}
      {/* <div className="mb-2 mt-3">
        <SearchFilter
          simpleFilter={false}
          entrepriseDetailsOffreFilters
          canReset={handleReset}
        />
      </div> */}
      <div className="mb-2">
        <SearchFilter simpleFilter={false} entrepriseDetailsOffreFilters />
      </div>

      <div className="d-flex align-items-center justify-content-between p-3 shadow-sm bg-white mb-2 rounded">
        <div>
          <h3 className="fs-5 fw-bold mb-0">
            {selectedOffreCandidature?.data?.titre}
          </h3>
        </div>
        <div className="d-flex gap-3">
          <p>
            <MdOutlineLocalOffer />{" "}
            <span className="fs-6">
              {selectedOffreCandidature?.data?.offre_type}
            </span>
          </p>
          <p>
            <MdOutlinePeopleOutline />{" "}
            <span className="fs-6">
              {selectedOffreCandidature?.data?.candidats?.length} candidats
            </span>
          </p>
          <p>
            <AiOutlineClockCircle />{" "}
            <span className="fs-6">
              il y a{" "}
              {dayjs(selectedOffreCandidature?.data?.created_at).fromNow(true)}
            </span>
          </p>
        </div>
      </div>

      <div className="bg-white px-2 py-2 px-sm-3 shadow-sm rounded">
        <div className="row">
          <div className="col-md col-12 d-flex flex-column justify-content-between">
            {loadingOffreDetails || fetchingOffreDetails ? (
              <SpinnerLoaderSearch />
            ) : (
              <GridList
                resourceData={selectedOffreCandidature?.data?.candidats}
                resourceItem={ClonedCandidatListItem}
                resourceName="candidat"
                cardWidth={300}
                emptyComponent={() => <span>Pas de candidats</span>}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffresCandidatureDetails;
