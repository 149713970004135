import { apiSlice } from "../apiSlice/apiSlice";

export const resetPasswordSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendPasswordReset: builder.mutation({
      query: (payload) => ({
        url: "send-reset-password",
        method: "POST",
        body: payload,
      }),
    }),

    resetPassword: builder.mutation({
      query: (payload) => ({
        url: "reset-password",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useSendPasswordResetMutation, useResetPasswordMutation } =
  resetPasswordSlice;
