import React from "react";
import { FilePond } from "react-filepond";
import CustomModal from "../../components/CustomModal";
import modele from "../../doc/modele.png";
import modeleBase from "../../doc/modele.xlsx";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";

const ImportEtudiantsModal = ({
  show,
  close,
  setExelFile,
  etablissement,
  accessToken,
  refetch,
  user,
}) => {
  return (
    <CustomModal
      modalTitle="Importer vos étudiants"
      show={show}
      handleClose={close}
      size=""
    >
      <div>
        <p className="text-black">
          Importer vos étudiants selon le modele ci-dessous
        </p>
        <img src={modele} className="img-fluid" alt="" />
        <p className="text-black mb-2">
          Télécharger le modele de base{" "}
          <span className="text-black fw-bold text-decoration-underline">
            <a
              href={modeleBase}
              target="_blank"
              rel="noreferrer"
              download="Modele"
            >
              ici
            </a>
          </span>
        </p>

        <input
          type="hidden"
          name="etablissement_id"
          value={etablissement?.id}
        />
        <FilePond
          onupdatefiles={(fileItems) => {
            setExelFile(fileItems.map((fileItem) => fileItem.file));
          }}
          labelIdle='Glisser & deposer pour importer ou <span className="filepond--label-action">Parcourrir</span>'
          credits={null}
          server={{
            process: {
              url: `${process.env.REACT_APP_API_URL}import_etudiants`,
              method: "POST",
              ondata: (fd) => {
                fd.append("etablissement_id", etablissement?.id);
                fd.append("title", user?.name);
                return fd;
              },
              onerror: () => {
                toastAlertError(
                  "Erreur lors de l'importation. Veuillez revoir votre fichier svp."
                );
              },
              onload: () => {
                setTimeout(() => {
                  refetch();
                  toastAlertSuccess("Importation effectuée.");
                  close();
                }, 1000);
              },
            },
            remove: null,
            revert: null,
            fetch: false,
            load: false,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }}
          instantUpload={true}
          name={"file_csv"}
          //   onprocessfile={() => {
          //       refetch();
          //       toastAlertSuccess("Importation effectuée.");
          //     close();
          //   }}
          labelFileProcessing="Importation en cours"
          labelTapToCancel="Appuyer pour annuler"
          labelFileProcessingAborted="Impotration annulé"
          labelTapToRetry="Appuyer pour recommencer"
          labelFileProcessingError="Erreur lors de l'importation"
          labelFileProcessingComplete="Importation terminée"
          labelTapToUndo=""
        />
      </div>
    </CustomModal>
  );
};

export default ImportEtudiantsModal;
