import React from 'react'
import { styled } from 'styled-components'


const Container = styled.div`
    max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : '1200px'};
    margin: auto;
    padding: 0 15px;
`

const LandingPageContainer = ({ children, maxWidth }) => {
    return (
        <Container maxWidth={maxWidth}>
            {children}
        </Container>
    )
}

export default LandingPageContainer