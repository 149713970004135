import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonLoading from "../../../components/ButtonLoading";
import CustomModal from "../../../components/CustomModal";
import Select from "../../../components/shared/Select";
import TextArea from "../../../components/shared/TextArea";
import {
  ETUDIANT_HOME_PAGE_PATH,
  MY_PROFILE_PAGE,
} from "../../../constants/routes_path";
import { useUser } from "../../../hooks/useUser";
import { useUserId } from "../../../hooks/useUserId";
import { useCandidatureSpontaneeMutation } from "../../../redux/slices/candidature/candidatureSlce";
import { useGetEntrepriseDomaineQuery } from "../../../redux/slices/entreprises/domainesSlice";
import { useGetProfileCompletionRateQuery } from "../../../redux/slices/etudiants/etudiantsSlice";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";

const CandidatureSpontane = ({ show, close, entreprise_id }) => {
  const [isAdding, setIsAdding] = useState(false);
  const { userId } = useUserId();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const [candidaterSpontanement] = useCandidatureSpontaneeMutation();
  const { data = { count: 0, data: [] } } =
    useGetEntrepriseDomaineQuery(entreprise_id);

  const selectOptions = data.data.map((option) => ({
    value: option.id,
    label: option.name,
  }));

  const candidaterSpontanementFunc = (data) => {
    if (!data.domaine_id) {
      return setError("domaine_id", {
        type: "custom",
        message: "Domaine obligatoire",
      });
    }
    setIsAdding(true);
    candidaterSpontanement({
      motivation: data.motivation,
      user_id: userId,
      domaine_id: data.domaine_id,
      entreprise_id,
    })
      .unwrap()
      .then((res) => {
        if (res.success) {
          toastAlertSuccess("Candidature spontanée réussie");
          reset();
          close();
        }
      })
      .catch((err) => {
        toastAlertError(err.data.message);
      })
      .finally(() => {
        setIsAdding(false);
      });
  };
  const user = useUser();
  const { data: completionRate } = useGetProfileCompletionRateQuery(user?.id);
  const ProfileIncomplete = () => (
    <div>
      <h6>Profil à : {completionRate.completion_rate}%</h6>
      <p>
        Merci de completer votre profil avant de faire une candidature spontanée
      </p>
      <Link
        className="btnCompleteProfile"
        to={ETUDIANT_HOME_PAGE_PATH + MY_PROFILE_PAGE}
      >
        Le Complétez ici
      </Link>{" "}
    </div>
  );

  return completionRate?.completion_rate < 75 ? (
    toast(ProfileIncomplete, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // transition: Bounce,
    })
  ) : (
    <CustomModal
      modalTitle="Candidature spontanée"
      show={show}
      handleClose={() => {
        close();
        reset();
      }}
    >
      <form onSubmit={handleSubmit(candidaterSpontanementFunc)}>
        <Select
          required
          htmlFor="domaine"
          label="Choisir le domaine"
          emptyMessage="Pas de domaines"
          selectOptions={selectOptions}
          register={{ ...register("domaine_id") }}
          errors={errors}
          msgError={errors.domaine_id?.message}
        />
        <TextArea
          minLength="10"
          maxLength="500"
          placeholder="Motivation"
          label="Motivation"
          htmlFor="motivation"
          register={{ ...register("motivation") }}
          error={errors.motivation}
        />
        <ButtonLoading
          loading={isAdding}
          text="Ajouter"
          className="btn-apply w-100 mt-3"
        />
      </form>
    </CustomModal>
  );
};

export default CandidatureSpontane;
