import React from "react";
import ResourceDataMap from "../../../components/shared/ResourceDataMap";
import { useGetAllEtablissementsQuery } from "../../../redux/slices/etablissements/etablissementsSlice";
import { SpinnerLoaderSearch } from "../../../utils/spinnersLoader";
import EtablissementListItem from "../components/EtablissementListItem";

const EntitiesListeEtablissment = () => {
  const { data: etablissements = [], isLoading } =
    useGetAllEtablissementsQuery();
  return (
    <div className="row">
      {isLoading ? (
        <SpinnerLoaderSearch />
      ) : etablissements.length ? (
        <ResourceDataMap
          resourceItem={EtablissementListItem}
          resourceName="etablissement"
          resourceData={etablissements}
        />
      ) : (
        <p className="mt-5 fs-5 text-center">Pas encore d'établissements</p>
      )}
    </div>
  );
};

export default EntitiesListeEtablissment;
