export const phoneNumberPattern = /^(77|76|75|78|70)\d{7}$/;
// export const emailPattern = /^\w+([.-]?\w+)*@gmail\.com$/
export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export class FormValidate {
  constructor(_number, _email) {
    this.phoneNumber = _number;
    this.email = _email;
    this.phoneNumberPattern = /^(77|76|75|78)\d{7}$/;
  }

  static verifyPhoneNumber(phoneNumber) {
    return phoneNumber.match(/^(77|76|75|78)\d{7}$/);
  }
}
