import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import CustomModal from "../../../components/CustomModal";
import Button from "../../../components/shared/Button";
import InputForm from "../../../components/shared/InputForm";
import TextArea from "../../../components/shared/TextArea";
import {
  LANDINGPAGE_OFFRES_PATH,
  LOGIN_PATH,
} from "../../../constants/routes_path";
import { useContactUsMutation } from "../../../redux/slices/contactUs/contactUsSlice";
import { toastAlertSuccess } from "../../../utils/toastAlerts";
import LandingBtn from "./LandingBtn";

const Banniere = () => {
  // Modal state
  const [openContactForm, setOpenContactForm] = useState(false);

  const navigate = useNavigate();

  // Form validation schema
  const formSchema = object().shape({
    name: string().required("Veuillez remplir ce champ"),
    email: string().email().required("Cet email n'est pas valide"),
    subject: string().required("Veuillez mettre le sujet de votre message"),
    message: string().required("Veuillez ecrire votre message"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  // Contact us
  const [contactUs, { isLoading }] = useContactUsMutation();

  const handleContactUs = (values) => {
    contactUs(values)
      .then((res) => {
        reset();
        toastAlertSuccess("Message envoyé");
        setOpenContactForm(false);
      })
      .catch((err) => {});
  };

  return (
    <div className="banniere_container">
      <div className="landing_page_container d-flex justify-content-start align-items-center h-100">
        <div className="">
          {/* <p className="banniere-bg-p animate__animated animate__backInLeft">
            Connectons vos succès
          </p> */}
          <h1 className="animate__animated animate__backInLeft">
            Stages simplifiés. Recruteurs satisfaits.{" "}
          </h1>
          <p className="animate__animated animate__backInLeft">
            Révolutionnez la gestion des stages avec notre plateforme
            tout-en-un, offrant une expérience optimale aux étudiants, aux
            établissements et aux entreprises, du début à la fin
          </p>
          <div className="d-flex flex-wrap gap-3 mt-2">
            <LandingBtn
              onClick={() => navigate(LANDINGPAGE_OFFRES_PATH)}
              className="bg-color-green animate__animated animate__backInLeft rounded"
            >
              {/* Postulez sur une offre */}
              Trouvez votre stage idéal
            </LandingBtn>
            <LandingBtn
              className="bg-color-red animate__animated animate__backInLeft rounded"
              // onClick={() => {
              //   setOpenContactForm(true);
              // }}
              onClick={() => navigate(LOGIN_PATH)}
            >
              {/* Contactez nous */}
              Publiez une offre de stage
            </LandingBtn>
          </div>
        </div>
      </div>
      <CustomModal
        modalTitle="Contactez-nous"
        show={openContactForm}
        handleClose={() => {
          setOpenContactForm(false);
          reset();
        }}
      >
        <form onSubmit={handleSubmit(handleContactUs)}>
          <InputForm
            placeholder="Nom Complet"
            type="text"
            register={{ ...register("name") }}
            htmlFor="name"
            required
            error={errors.name?.message}
          />
          <InputForm
            placeholder="Email"
            type="email"
            register={{ ...register("email") }}
            htmlFor="email"
            // required
            error={errors.email?.message}
          />

          <InputForm
            placeholder="Sujet de votre message"
            type="text"
            register={{ ...register("subject") }}
            htmlFor="subject"
            required
            error={errors.name?.subject}
          />

          <TextArea
            minLength="10"
            maxLength="500"
            placeholder="Ecrivez quelque chose..."
            label="Message"
            htmlFor="message"
            register={{ ...register("message") }}
            error={errors.message?.message}
          />

          <div className=" mt-3">
            <Button
              type="submit"
              className="btn-add w-100 py-2"
              disabled={true}
            >
              {isLoading ? "Envoie en cours..." : "Envoyer"}
            </Button>
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default Banniere;
