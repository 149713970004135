import React, { cloneElement, useMemo } from 'react'
import { GridList } from 'react-flexible-list'
import TitleH2 from '../../components/shared/TitleH2'
import { ENTREPRISE_CANDIDATURES_PAGE_PATH, ENTREPRISE_MES_OFFRES_PATH } from '../../constants/routes_path'
import GraphEntreprise from './components/GraphEntreprise'
import { useEntreprise } from '../../hooks/useEntreprise'
import { useGetEntrepriseKpiQuery } from '../../redux/slices/entreprises/entreprisesSlice'
import DashKpi from './components/DashKpi'

const EntrepriseDashboard = () => {
    const { entreprise } = useEntreprise()
    const { data } = useGetEntrepriseKpiQuery({ id_entreprise: entreprise?.id })
    const ClonedDashSkpi = ({ kpi }) => cloneElement(<DashKpi kpi={kpi} />, {})
    // const entrepriseKpis = useMemo(() => [
    //     {
    //         label: 'Offres',
    //         number: data?.nbrOffre ?? '...',
    //         className: 'bg-gradient-dark',
    //         to: ENTREPRISE_MES_OFFRES_PATH
    //     },
        
    // ], [data?.nbrOffre])
    const entrepriseKpis = useMemo(() => [
        {
            label: 'Offres',
            number: data?.nbrOffre ?? '...',
            className: 'bg-gradient-dark',
            to: ENTREPRISE_MES_OFFRES_PATH
        },

    ], [data?.nbrOffre])
    const entrepriseOffresKpis = useMemo(() => [
        {
            label: 'Total Offres',
            number: data?.nbrOffre ?? '...',
            className: 'bg-gradient-warning',
            to: ENTREPRISE_MES_OFFRES_PATH,
            icon:'book-open'
        },
        {
            label: 'Offres Expirées',
            number: data?.offresExpirees ?? '...',
            className: 'bg-gradient-primary',
            to: ENTREPRISE_MES_OFFRES_PATH,
            icon:'delete'
        },
        {
            label: 'Offres Ouvertes',
            number: data?.offresOuvertes ?? '...',
            className: 'bg-gradient-success',
            
            to: ENTREPRISE_MES_OFFRES_PATH,
            icon:'check-square'
        },
    ], [data?.nbrOffre,data?.offresExpirees,data?.offresOuvertes ])

   const entrepriseCandidaturesKpis= useMemo(() => [
    {
        label: 'Total Candidatures',
        number: data?.nbrCandidature ?? '...',
        className: 'bg-gradient-dark',
        to: ENTREPRISE_CANDIDATURES_PAGE_PATH,
        icon:'users'
    }, {
        label: 'Nouvelles',
        number: data?.nbrNouvellesCandidatures ?? '...',
        className: 'bg-gradient-info',
        to: ENTREPRISE_CANDIDATURES_PAGE_PATH,
        icon:'user'
    },
    {
        label: 'Convoquées',
        number: data?.nbrCandidatConvoques ?? '...',
        className: 'bg-gradient-warning',
        to: ENTREPRISE_CANDIDATURES_PAGE_PATH,
        icon:'user-plus'
    },
    {
        label: 'Rejetées',
        number: data?.nbrCandidatureRejetes ?? '...',
        className: 'bg-gradient-primary',
        to: ENTREPRISE_CANDIDATURES_PAGE_PATH,
        icon:'user-x'
    },
    {
        label: 'Validées',
        number: data?.nbrCandidatureValides ?? '...',
        className: 'bg-gradient-success',
        to: ENTREPRISE_CANDIDATURES_PAGE_PATH,
        icon:'user-check'
    },
 ], [data?.nbrNouvellesCandidatures,data?.nbrCandidatConvoques, data?.nbrCandidature, data?.nbrCandidatureRejetes, data?.nbrCandidatureValides,  ])

    return (
        <div className=''>
            {/* <TitleH1>Dashboard</TitleH1> */}
            {/* <GridList
                resourceData={entrepriseKpis}
                resourceItem={ClonedDashSkpi}
                resourceName="kpi"
            /> */}
            <div className="mt-3">
            <TitleH2 className="mt-3">Mes Offres</TitleH2>
            <GridList
                resourceData={entrepriseOffresKpis}
                resourceItem={ClonedDashSkpi}
                resourceName="kpi"
            />
            </div>
            <div className="mt-3">
               <TitleH2 >Mes Candidats</TitleH2>
            <GridList
                resourceData={entrepriseCandidaturesKpis}
                resourceItem={ClonedDashSkpi}
                resourceName="kpi"
            />
            </div>
            <GraphEntreprise/>

            {/* <DashKpi /> */}
        </div>
    )
}

export default EntrepriseDashboard