import React from "react";

const InputForm = ({
  label,
  register,
  htmlFor,
  required,
  type,
  error,
  placeholder,
  ...props
}) => {
  return (
    <div className="mb-3">
      <label htmlFor={htmlFor} className="form-label">
        {label}
      </label>
      <input
        placeholder={placeholder}
        type={type}
        className={`input-form ${error && " form-is-invalid"}`}
        id={htmlFor}
        {...register}
        required={required}
        {...props}
      />
      {error && (
        <p
          style={{
            fontSize: "12px",
            padding: "0 10px",
            color: "red",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default InputForm;
