import { useState } from "react";
import TypeBadge from "./TypeBadge";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";
import { useConvoquerMutation } from "../../../redux/slices/candidature/candidatureSlce";
import ConvoquerProfilForm from "../forms/ConvoquerProfilForm";

const ProfileListItem = ({ profil, navigateToProfileDetails }) => {
  // Hooks
  const [convoquer, { isLoading: isConvocation }] = useConvoquerMutation();

  // States
  const [confirmCandidatureModal, setConfirmCandidatureModal] = useState();

  const onSubmit = async (data) => {
    await convoquer({ data, id: profil?.user?.id })
      .then((res) => {
        setConfirmCandidatureModal(false);
        toastAlertSuccess("Candidat convoqué");
      })
      .catch((err) => {
        toastAlertError(err.message);
      });
  };

  return (
    <>
      {/* Call profil for interview */}
      <ConvoquerProfilForm
        show={confirmCandidatureModal}
        close={() => setConfirmCandidatureModal(false)}
        profilName={profil?.user?.name}
        convoquerProfil={onSubmit}
        isLoading={isConvocation}
      />

      <div
        style={{ cursor: "pointer" }}
        onClick={() => navigateToProfileDetails(profil)}
        className="mb-3 shadow p-3 w-100 position-relative"
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column">
            <h6 className="m-0 fs-5 ">{profil?.user?.name}</h6>
            <span className="m-0 fs-6 ">
              {profil?.fonction ?? "Fonction non défini"}
            </span>
          </div>
          <TypeBadge
            text="Convoquer"
            bgCOlor="#6CD7A5"
            onClick={(e) => {
              e.stopPropagation();
              setConfirmCandidatureModal(true);
            }}
          />
        </div>
        <div className="d-flex flex-column flex-wrap gap-2 mt-3">
          <p className="m-0 fs-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              width={15}
              height={15}
              className="me-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>{" "}
            {profil?.user?.phone ?? "Non définit"}
          </p>
          <p className="m-0 fs-6">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              width={15}
              height={15}
              className="me-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>{" "}
            {profil?.user?.email}
          </p>
          <p className="m-0 fs-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              width={15}
              height={15}
              className="me-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>{" "}
            {profil?.user?.location ?? "Non définit"}
          </p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              width={15}
              height={15}
              className="me-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            {profil?.cv?.fichier ? (
              <span className="ms-1">
                Voir{" "}
                <a
                  href={
                    process.env.REACT_APP_BASE_URL +
                    "resume/" +
                    profil?.cv?.fichier
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => e.stopPropagation()}
                  // className="fs-6"
                  className="text-decoration-underline text-primary"
                >
                  CV
                </a>
              </span>
            ) : (
              <p className="m-0 fs-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  width={15}
                  height={15}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>{" "}
                Pas de CV
              </p>
            )}
          </p>
        </div>

        <div className="d-flex flex-column mt-2">
          <p>
            Profil renseigné à{" "}
            <span className="fw-bold fs-6">
              {profil?.profile_completion_rate ?? 0}%
            </span>
          </p>
          <progress
            id="file"
            value={profil?.profile_completion_rate ?? 0}
            max="100"
          >
            {profil?.profile_completion_rate ?? 0}
          </progress>
        </div>
        {/* {profil?.completionRate > 0 ? (
      ) : (
        <p>Porfil non compléte</p>
      )} */}
      </div>
    </>
  );
};

export default ProfileListItem;
