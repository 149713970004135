import React, { cloneElement, useDeferredValue, useEffect } from "react";
import SearchFilter from "../../../components/SearchFilter";
import PaginatedItems from "../../../components/shared/PaginationItems";
import TitleH2 from "../../../components/shared/TitleH2";
import { useGetAllEntreprisesQuery } from "../../../redux/slices/entreprises/entreprisesSlice";
import { SpinnerLoaderSearch } from "../../../utils/spinnersLoader";
import EntrepriseDataMapLanding from "../../Entreprises/EntrepriseDataMapLanding";
import FadeTransition from "../components/Fadetransition";

const LandingPageEntreprise = () => {
  const { data: entreprises, isLoading } = useGetAllEntreprisesQuery();

  return (
    // <Main>
    <div className="p-0">
      <div className="mb-3">
        {/* <SearchFilter
          inputState={searchItem}
          inputSetState={setSearchItem}
          resetState={resetStates}
        /> */}
      </div>
      <FadeTransition in={true}>
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center mt-2">
            <SpinnerLoaderSearch />
          </div>
        ) : entreprises?.count >= 1 ? (
          <>
            <TitleH2>Liste des entreprises</TitleH2>
            <PaginatedItems itemsPerPage={7} ressourceData={entreprises?.data}>
              <EntrepriseDataMapLanding />
            </PaginatedItems>
          </>
        ) : (
          <p className="text-center">Aucune entreprise pour le moment.</p>
        )}
      </FadeTransition>
    </div>
  );
};

export default LandingPageEntreprise;
