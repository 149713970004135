import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import CustomModal from "../../../components/CustomModal";
import ButtonLoading from "../../../components/ButtonLoading";
import { typeFormations } from "../../../utils/type_entretien";
import Select from "../../../components/shared/Select";
import InputForm from "../../../components/shared/InputForm";

const ConvoquerProfilForm = ({
  show,
  close,
  profilName,
  convoquerProfil,
  isLoading,
}) => {
  const formSchema = Yup.object().shape({
    date_entretien: Yup.string().required("Obligatoire"),
  });
  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);
  return (
    <CustomModal
      modalTitle={`Convoquer ${profilName} pour un entretien`}
      show={show}
      handleClose={() => {
        close();
        reset();
      }}
      centered={false}
      scrollable
      backdrop="static"
      keyboard={false}
      className="border-3"
    >
      <form onSubmit={handleSubmit((data) => convoquerProfil(data))}>
        <InputForm
          type="date"
          label="Date entretien"
          htmlFor="date"
          required
          register={{ ...register("date_entretien") }}
          error={errors.date_entretien?.message}
        />
        <InputForm
          type="date"
          label="Date limite confirmation"
          htmlFor="date"
          required
          register={{ ...register("date_confirmation") }}
          error={errors.date_entretien?.message}
        />
        <InputForm
          type="time"
          label="Heure"
          htmlFor="heure"
          required
          register={{ ...register("heure_entretien") }}
          error={errors.heure_entretien?.message}
        />
        <InputForm
          type="text"
          placeholder="Lieu"
          htmlFor="lieu"
          required
          register={{ ...register("lieu_entretien") }}
          error={errors.lieu_entretien?.message}
        />
        <div>
          <div className="d-flex align-items-center mb-2">
            <input
              type="checkbox"
              defaultChecked
              className={`input-form py-2 ${
                errors.is_presentiel && "is-invalid"
              }`}
              id="name"
              {...register("is_presentiel")}
            />
            <span style={{ fontSize: 12, marginLeft: 5 }}>En presentiel?</span>
          </div>
        </div>
        <Select
          label="Type d'entretien"
          htmlFor="type_entretien"
          register={{ ...register("type_entretien") }}
          selectOptions={typeFormations}
        />
        <div
          // style={{ width: "150px" }}
          className="mt-2"
        >
          <ButtonLoading
            type="submit"
            text="Convoquer"
            loading={isLoading}
            className="bg-gradient-success text-light py-2 fw-bold"
          />
        </div>
      </form>
    </CustomModal>
  );
};

export default ConvoquerProfilForm;
