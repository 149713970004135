import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createQueryString } from "../utils/createQueryString";

function useQueryParams() {
  const location = useLocation();
  const navigate = useNavigate();

  // get query params
  const queryParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  // Get current params key-value pairs
  function getCurrentParams() {
    let transformedObject = {};
    [...queryParams.entries()].forEach((snippet) => {
      // Extracting key and value from each code snippet
      let key = snippet[0];
      let value = snippet[1];

      // Assigning key-value pair to the transformed object
      transformedObject[key] = value;
    });

    return transformedObject;
  }

  // updates the query params
  function setQueryParams(queryObj) {
    //   queryParams.set(queryName, queryObj);
    navigate({
      //   search: queryParams.toString(),
      search: createQueryString(queryObj),
    });
  }

  return { queryParams, getCurrentParams, setQueryParams };
}

export default useQueryParams;
