import React from 'react'

const LinkBadge = ({ link, text, bgColor, icon, element }) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      style={{ fontSize: '11px', backgroundColor: bgColor, color: 'white', fontWeight: 600 }}
      className='rounded px-2 d-flex align-items-center'
      href={link}>
      <span className='me-1'>
        {icon}
      </span>
      {text}
    </a>
  )
}

export default LinkBadge