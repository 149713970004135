import { ADD_CERTIFICATION_API_ROUTE, DELETE_CERTIFICATION_API_ROUTE, EDIT_CERTIFICATION_API_ROUTE, GET_CERTIFICATIONS_BY_USER_API_ROUTE } from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

const certificationSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        ajouterCertification: builder.mutation({
            query: (payload) => ({
                url: `${ADD_CERTIFICATION_API_ROUTE}`,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["Certification"],
        }),

        getEtudiantCertifications: builder.query({
            query: (user_id) => `${GET_CERTIFICATIONS_BY_USER_API_ROUTE}${user_id}`,
            providesTags: ["Certification"],
        }),

        modifierEtudiantCertification: builder.mutation({
            query: (args) => {
                const { certification_id, payload } = args;

                return {
                    url: `${EDIT_CERTIFICATION_API_ROUTE}${certification_id}`,
                    method: "POST",
                    body: payload,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            invalidatesTags: ["Certification"],
        }),
        supprimerEtudiantCertification: builder.mutation({
            query: (certification_id) => ({
                url: `${DELETE_CERTIFICATION_API_ROUTE}${certification_id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Certification"],
        }),
    })
})

export const { useAjouterCertificationMutation, useGetEtudiantCertificationsQuery, useModifierEtudiantCertificationMutation, useSupprimerEtudiantCertificationMutation } = certificationSlice