import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CustomModal from "../../../components/CustomModal";
import Button from "../../../components/shared/Button";
import InputForm from "../../../components/shared/InputForm";
import TextArea from "../../../components/shared/TextArea";
import { LANDINGPAGE_OFFRES_PATH } from "../../../constants/routes_path";
import { useContactUsMutation } from "../../../redux/slices/contactUs/contactUsSlice";
import { toastAlertSuccess } from "../../../utils/toastAlerts";
import entreprise from "../assets/img/entreprise.png";
import etablissement from "../assets/img/etablissement.png";
import marieme from "../assets/img/marieme.png";
import postuler from "../assets/img/postuler.png";
import LandingPageContainer from "../components/LandingPageContainer";
import Section from "../components/Section";
import SectionH2 from "../components/SectionH2";
import SectionParagrapheTitle from "../components/SectionParagrapheTitle";
import TabItemImgLeft from "../components/TabItemImgLeft";
import TabItemImgRight from "../components/TabItemImgRight";
import { TabContextProvider, TabsNavbar, TabsPanel } from "../components/tabs";

const TabsList = [
  "Bakeli Carrière",
  "Espace étudiant",
  "Espace entreprise",
  "Espace établissement",
];

// Contact form modal
export const contactFormModal = ({ show, close }) => {
  return (
    <CustomModal
      modalTitle="Contactez-nous"
      show={show}
      handleClose={() => {
        close();
      }}
    >
      <h1>This is contact form modal</h1>
    </CustomModal>
  );
};

const LesDifferentsActeurs = () => {
  // Modal state
  const [openContactForm, setOpenContactForm] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Veuillez remplir ce champ"),
    email: Yup.string().email().required("Cet email n'est pas valide"),
    subject: Yup.string().required("Veuillez mettre le sujet de votre message"),
    message: Yup.string().required("Veuillez ecrire votre message"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  // Contact us
  const [contactUs, { isLoading }] = useContactUsMutation();

  const handleContactUs = (values) => {
    contactUs(values)
      .then((res) => {
        reset();
        toastAlertSuccess("Message envoyé");
        setOpenContactForm(false);
      })
      .catch((err) => {});
  };

  return (
    <Section className="differents_acteurs_container">
      <LandingPageContainer>
        <SectionH2>Bakeli Carrière: Les Avantages</SectionH2>
        <SectionParagrapheTitle>
          La plateforme ultime pour la gestion de stages : connectant les
          étudiants, les établissements et les entreprises pour une
          collaboration fluide et des opportunités de stage inégalées
        </SectionParagrapheTitle>

        <div className="mt-4">
          <TabContextProvider
            defaultActivePanel={TabsList[0]}
            isUseLocalStorage={false}
          >
            <TabsNavbar items={TabsList} />
            <div
              className="container-lg p-0"
              style={{ position: "relative", minHeight: "400px" }}
            >
              <TabsPanel id={TabsList[0]}>
                <TabItemImgRight
                  title="Bakeli Carriere est une plateforme"
                  paragrapheText="Notre plateforme se positionne comme un soutien essentiel
pour accompagner les étudiants dans leur parcours
académique et professionnel, les entreprises dans
la recherche de talent et les établissements dans le suivi
des stages de leurs apprenants."
                  btnText="Contactez-nous"
                  imgSrc={marieme}
                  alt="Bakeli carriere"
                  onClick={() => {
                    setOpenContactForm(true);
                  }}
                />
              </TabsPanel>
              <TabsPanel id={TabsList[1]}>
                <TabItemImgLeft
                  title="Avoir une plateforme de gestion des stages"
                  paragrapheText="Vous êtes à la recherche d'un stage?
Vous êtes au bon endroit. Découvrez l'espace étudiant de notre plateforme  :
votre portail personnalisé pour explorer, postuler et réussir
vos stages en un seul endroit.
N'attendez plus,
postuler et inviter vos amis à faire pareil."
                  avantage_titre="Les avantages sont :"
                  avantage1="Accès simplifié aux opportunités de stage"
                  avantage2="Large Eventail d'offres"
                  avantage3="Visibilité accrue de votre profil"
                  avantage4="Réseau professionnel élargi"
                  btnText="Postuler sur une offre"
                  imgSrc={postuler}
                  alt="Espace etudiant"
                  onClick={() => navigate(LANDINGPAGE_OFFRES_PATH)}
                />
              </TabsPanel>
              <TabsPanel id={TabsList[2]}>
                <TabItemImgLeft
                  title="Trouver les meilleurs profils du marché"
                  paragrapheText="Trouvez les talents les plus prometteurs grâce à notre espace
                      entreprise, où vous pouvez publier des offres de stage ,
                      gérer les candidatures , les conventions et promouvoir
                      votre entreprise  comme un acteur clé du développement
                      professionnel des étudiants."
                  avantage_titre="Les avantages sont :"
                  avantage1="Accès à un pool de talents diversifié"
                  avantage2="Gain de temps dans le processus de recrutement"
                  avantage3="Tri des meilleurs candidats"
                  avantage4="Renforcement de la marque employeur"
                  btnText="Contactez-Nous"
                  imgSrc={entreprise}
                  alt="Espace etudiant"
                  onClick={() => {
                    setOpenContactForm(true);
                  }}
                />
              </TabsPanel>
              <TabsPanel id={TabsList[3]}>
                <TabItemImgRight
                  title="Avoir une plateforme de suivi des stages
de vos apprenants"
                  paragrapheText="Soyez à la pointe de la gestion des stages avec
notre espace établissement, conçu pour vous offrir une
vision globale, simplifier les processus administratifs et
garantir une expérience de stage harmonieuse pour
vos étudiants."
                  btnText="Contactez-nous"
                  imgSrc={etablissement}
                  alt="Bakeli carriere"
                  onClick={() => {
                    setOpenContactForm(true);
                  }}
                />
              </TabsPanel>
            </div>
          </TabContextProvider>
        </div>
      </LandingPageContainer>
      {/* Contact Form Modal Here */}

      <CustomModal
        modalTitle="Contactez-nous"
        show={openContactForm}
        handleClose={() => {
          setOpenContactForm(false);
          reset();
        }}
      >
        <form onSubmit={handleSubmit(handleContactUs)}>
          <InputForm
            placeholder="Nom Complet"
            type="text"
            register={{ ...register("name") }}
            htmlFor="name"
            required
            error={errors.name?.message}
          />
          <InputForm
            placeholder="Email"
            type="email"
            register={{ ...register("email") }}
            htmlFor="email"
            // required
            error={errors.email?.message}
          />

          <InputForm
            placeholder="Sujet de votre message"
            type="text"
            register={{ ...register("subject") }}
            htmlFor="subject"
            required
            error={errors.name?.subject}
          />

          <TextArea
            minLength="10"
            maxLength="500"
            placeholder="Ecrivez quelque chose..."
            label="Message"
            htmlFor="message"
            register={{ ...register("message") }}
            error={errors.message?.message}
          />

          <div className=" mt-3">
            <Button
              type="submit"
              className="btn-add w-100 py-2"
              disabled={true}
            >
              {isLoading ? "Envoie en cours..." : "Envoyer"}
            </Button>
          </div>
        </form>
      </CustomModal>
    </Section>
  );
};

export default LesDifferentsActeurs;
