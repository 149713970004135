import React, { cloneElement } from "react";
import { Badge } from "react-bootstrap";
import { GridList } from "react-flexible-list";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../components/SearchFilter";
import TitleH2 from "../../../components/shared/TitleH2";
import {
  ENTREPRISE_HOME_PAGE_PATH,
  ENTREPRISE_MES_OFFRES_PATH,
} from "../../../constants/routes_path";
import useQueryParams from "../../../hooks/useQueryParams";
import { useGetProfilesQuery } from "../../../redux/slices/entreprises/entreprisesSlice";
import DotLoader from "../../../utils/DotLoader";
import { SpinnerLoaderSearch } from "../../../utils/spinnersLoader";
import ProfileListItem from "../components/ProfileListItem";
import Pagination from "../../../components/Pagination";

const Profiles = () => {
  //   const { id } = useParams();

  const navigate = useNavigate();

  const { queryParams, setQueryParams, getCurrentParams } = useQueryParams();
  const currentParams = getCurrentParams();

  // Convert URLSearchParams object to a plain object
  const paramsObject = {};
  for (const [key, value] of queryParams.entries()) {
    paramsObject[key] = value;
  }

  // Profiles query
  const {
    data: entrepriseProfiles,
    isLoading: loadingEntrepriseProfile,
    isFetching,
  } = useGetProfilesQuery({ params: paramsObject });

  const navigateToProfileDetails = (profile) => {
    return navigate(
      ENTREPRISE_HOME_PAGE_PATH +
        "/" +
        ENTREPRISE_MES_OFFRES_PATH +
        "/" +
        profile?.user?.id +
        "/candidat"
    );
  };

  const ClonedProfileListItem = ({ profil }) =>
    cloneElement(<ProfileListItem profil={profil} />, {
      navigateToProfileDetails,
    });

  const activeHandler = (clickedActive) => {
    setQueryParams({
      ...currentParams,
      page: clickedActive,
    });
  };

  return (
    <>
      <div className="d-flex align-items-center gap-2 my-2">
        <TitleH2>Profiles</TitleH2>
        {loadingEntrepriseProfile || isFetching ? (
          <span className="ms-4">
            <DotLoader />
          </span>
        ) : (
          <Badge>{entrepriseProfiles?.data?.total}</Badge>
        )}
        {/* </div> */}
      </div>
      {/* Custom filters */}
      <SearchFilter simpleFilter={false} profileFilters />
      <div className="bg-white px-2 pt-0 px-sm-3 pt-sm-0 shadow-sm mt-2 rounded">
        <div className="row">
          <div className="col-md col-12 d-flex flex-column justify-content-between">
            {loadingEntrepriseProfile || isFetching ? (
              <div className="vh-100 d-flex align-items-center justify-content-center">
                <SpinnerLoaderSearch />
              </div>
            ) : (
              <>
                <GridList
                  resourceData={entrepriseProfiles?.data?.data}
                  resourceItem={ClonedProfileListItem}
                  resourceName="profil"
                  cardWidth={300}
                  emptyComponent={() => <span>Pas de candidats</span>}
                />
                <div className="d-flex justify-content-center">
                  <Pagination
                    active={parseInt(queryParams.get("page")) || 1}
                    size={entrepriseProfiles?.data?.last_page}
                    step={2}
                    onClickHandler={activeHandler}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profiles;
