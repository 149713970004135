import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import ButtonLoading from "../../components/ButtonLoading";
import CustomModal from "../../components/CustomModal";
import {
    useModifierFormationEtudiantMutation,
    useSupprimerFormationEtudiantMutation,
} from "../../redux/slices/etudiants/formationSlice";
import { yearMonthDayFormatter } from "../../utils/timeFormatters";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";

const EditFormationModal = ({ show, close, formation, setFormation }) => {
  // Initials states
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const currentDate = new Date();
  const [editLoading, setEditLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    etablissement: Yup.string().required("Obligatoire"),
    date_debut: Yup.string().required("Obligatoire"),
  });
  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: formation?.name,
      etablissement: formation?.etablissement,
      date_debut: formation?.dateDebut,
      date_fin: formation?.dateFin,
    },
    values: {
      name: formation?.name,
      etablissement: formation?.etablissement,
      date_debut: formation?.dateDebut,
      date_fin: formation?.dateFin,
    },
  });
  const { errors } = formState;

  // Function to reset the input field after submit
  const resetInputFields = () => {
    reset({
      name: "",
      dateDebut: "",
      dateFin: "",
    });
    setStartDate("");
    setEndDate("");
  };

  // Use edit formation mutation
  const [editFormation] = useModifierFormationEtudiantMutation();

  // Use delete formation mutation
  const [deleteFormation] = useSupprimerFormationEtudiantMutation();

  // Submit function
  const handleEditFormation = (data) => {
    setEditLoading(true)
    editFormation(data)
      .unwrap()
      .then((res) => {
        toastAlertSuccess(res.message);
        close();
      })
      .catch((err) => {
        toastAlertError(err.message);
      })
      .finally(() => {
        setEditLoading(false)
      })
  };

  // Delete function
  const handleDelete = (data) => {
    setDeleteLoading(true)
    deleteFormation(data?.id)
      .unwrap()
      .then((res) => {
        toastAlertSuccess(res.message);
        close();
      })
      .catch((err) => {
        toastAlertError(err.message);
      })
      .finally(() => {
        setDeleteLoading(false)
      })
  };

  return (
    <CustomModal
      modalTitle="Modification Formation"
      show={show}
      handleClose={() => {
        close();
        resetInputFields();
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          handleEditFormation({
            formation_id: formation?.id,
            payload: data,
          });
        })}
      >
        <div className="mb-1">
          <label htmlFor="titrePoste" className="form-label">
            Nom de la formation
          </label>
          <input
            type="text"
            className={`form-control ${errors.name && "is-invalid"}`}
            id="name"
            {...register("name")}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="titrePoste" className="form-label">
            Ecole formation
          </label>
          <input
            type="text"
            className={`form-control ${errors.etablissement && "is-invalid"}`}
            id="etablissement"
            {...register("etablissement")}
          />
        </div>

        <div className="row my-3">
          <div className="col">
            <label htmlFor="dateDebut" className="form-label">
              Date debut
            </label>
            <input
              type="date"
              className={`form-control ${errors.date_debut && "is-invalid"}`}
              id="dateDebut"
              max={yearMonthDayFormatter(currentDate)}
              {...register("date_debut", {
                onChange: (e) => setStartDate(e.target.value),
              })}
            />
          </div>
          <div className="col mb-3">
            <label htmlFor="dateFin" className="form-label">
              Date fin
            </label>
            <input
              type="date"
              className="form-control"
              id="dateFin"
              min={startDate}
              {...register("date_fin", {
                onChange: (e) => setEndDate(e.target.value),
              })}
              disabled={startDate === "" || (startDate === undefined && true)}
            />
          </div>
        </div>

        <div className="text-end">
          {/* <button
            type="button"
            className="btn btn-red m-1"
            onClick={() => handleDelete(formation)}
          >
            Supprimer la formation
          </button> */}
          <ButtonLoading
            loading={editLoading}
            text={"Enregistrer"}
            className="btn-apply"
          />
          <ButtonLoading
            loading={deleteLoading}
            text={"Supprimer"}
            onClick={() => handleDelete(formation)}
            className="btn-red"
          />
        </div>
      </form>
    </CustomModal>
  );
};

export default EditFormationModal;
