import React from 'react'
import Button from './shared/Button'

const CardOnEmptyProfilData = ({
    onClick,
    titleOnEmpty,
    textOnEmpty,
    addBtnText
}
) => {
    return (
        <div className="cv-section text-center mb-4 white-bg px-5 py-3">
            <h2 className='fs-6'>{titleOnEmpty}</h2>
            <p className="py-3 fs-6">
                {textOnEmpty}
            </p>
            {
                onClick && <Button className="btn-add fs-6" onClick={onClick}>
                    {addBtnText}
                </Button>
            }
        </div>
    )
}

export default CardOnEmptyProfilData