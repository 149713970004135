import { useRef } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import EntrepriseDetailsOffreFilters from "../pages/Entreprises/filtres/EntrepriseDetailsOffreFilters";
import EntrepriseFilters from "../pages/Entreprises/filtres/EntrepriseFilters";
import EntrepriseOffresFilters from "../pages/Entreprises/filtres/EntrepriseOffresFilters";
import ProfilesFilters from "../pages/Entreprises/filtres/ProfilesFilters";
import EtudiantFilters from "../pages/Etudiant/filtres/EtudiantFilters";

const DEBOUNCE_THRESHOLD = 500;

const SearchFilter = ({
  simpleFilter = true,
  entrepriseCandidatureFilters,
  entrepriseDetailsOffreFilters,
  etudiantOffreFilters,
  entrepriseOffreFilters,
  profileFilters,
  inputSetState,
  resetState,
}) => {
  const timeoutHandler = useRef(null);
  const ref = useRef(null);

  //  Debouncing
  const handleChange = (e) => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    timeoutHandler.current = setTimeout(() => {
      inputSetState(e.target.value);
    }, DEBOUNCE_THRESHOLD);
  };

  return (
    <>
      <div
        style={{
          boxShadow:
            "0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2) !important",
          top: "5px",
        }}
        className={`search_filter_container ${
          simpleFilter
            ? "sticky-top"
            : "sticky-xl-top sticky-lg-top sticky-md-top sticky-sm-top"
        } m-0 p-2`}
      >
        {simpleFilter && (
          <div className="row py-0">
            <div className="col-10 d-flex align-items-center">
              <FiSearch className="mx-1 search_filter_icon" icon="search" />
              <input
                type="text"
                ref={ref}
                // value={inputState}
                onChange={handleChange}
                placeholder="Que recherchez-vous ?"
                className="search_input mx-1 form-control"
              />
            </div>

            <div className="col-2 d-flex align-items-center justify-content-end m-0 pl-0 ps-1">
              <FaTrashAlt
                className="mx-1 reset_filter_icon"
                icon="trash-2"
                onClick={() => {
                  ref.current.value = "";
                  resetState();
                }}
              />
              {/* <Tooltip title="Reinitialiser le filtre" placement="top">
            </Tooltip> */}
            </div>
          </div>
        )}
        {/* Etudiants Filters */}
        {etudiantOffreFilters && <EtudiantFilters />}

        {/* Entreprise filters */}
        {entrepriseCandidatureFilters && <EntrepriseFilters />}
        {entrepriseDetailsOffreFilters && <EntrepriseDetailsOffreFilters />}
        {entrepriseOffreFilters && <EntrepriseOffresFilters />}
        {profileFilters && <ProfilesFilters />}
      </div>
    </>
  );
};

export default SearchFilter;
