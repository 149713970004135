import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaFilePdf, FaImage } from "react-icons/fa";
import ButtonLoading from "../../../components/ButtonLoading";
import CustomModal from "../../../components/CustomModal";
import FileUpload from "../../../components/FileUpload";
import Input from "../../../components/shared/Input";
import TextArea from "../../../components/shared/TextArea";
import { ADD_CERTIFICATION_API_ROUTE } from "../../../constants/api_endpoints";
import { useUserId } from "../../../hooks/useUserId";
import { useGetProfileCompletionRateQuery } from "../../../redux/slices/etudiants/etudiantsSlice";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";

const AddCertification = ({ show, close, refetchCertifications }) => {
  const [loading, setLoading] = useState(false);
  const { userId } = useUserId();
  const { register, handleSubmit, reset, formState: errors } = useForm();

  const [isPdf, setIsPdf] = useState(true);
  const [pdfFile, setPdfFile] = useState([]);
  const [imagesFiles, setImagesFiles] = useState([]);
  const [removeFileEventActive, setRemoveFileEventActive] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(false);

  // Change this state to prevent FilePond on remove function to trigger
  useEffect(function changeRemoveFileEventActiveState() {
    setRemoveFileEventActive(true);
  }, []);

  // Reset states
  const resetState = () => {
    setIsPdf(true);
    setPdfFile();
    setImagesFiles();
    setRemoveFileEventActive(true);
    setSelectedFileType(false);
  };

  // Get Profile completion rate
  const { refetch: refetchCompletionRate } =
    useGetProfileCompletionRateQuery(userId);

  const addCertification = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data?.description);
    formData.append("lien_certification", data.lien_certification);
    formData.append("user_id", userId);

    if (pdfFile?.length > 0) {
      formData.append("pdf_file", pdfFile[0]);
    }

    if (imagesFiles?.length > 0) {
      formData.append("url[]", imagesFiles[0]);
    }

    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_API_URL + ADD_CERTIFICATION_API_ROUTE,
        formData
      )
      .then((res) => {
        resetState();
        if (res.status === 201) {
            refetchCertifications();
            refetchCompletionRate();
          toastAlertSuccess("Certification ajoutée");
        } else {
          toastAlertError("Une erreur est survenue");
        }
      })
      .catch((err) => {
        resetState();
        toastAlertError("Une erreur est survenue");
        close();
      })
      .finally(() => {
        setLoading(false);
        close();
        resetState();
      });
  };

  return (
    <CustomModal
      modalTitle="Ajouter une certification"
      show={show}
      handleClose={() => {
        close();
        reset();
        resetState();
      }}
    >
      <form onSubmit={handleSubmit(addCertification)}>
        <Input
          type="text"
          placeholder="Ex: Responsive web design"
          label="Nom certification"
          register={{ ...register("name") }}
          error={errors.name}
          required
          htmlFor="name"
          minLength="5"
          maxLength="90"
          name="name"
        />
        <Input
          type="url"
          placeholder="Ex: https://www.sololearn.com/Certificate/1234-1234567"
          label="Lien certification"
          html="lien_certification"
          register={{ ...register("lien_certification") }}
          error={errors.lien_certification}
        />

        <div className="fs-6 d-flex align-items-center justify-content-end mb-2">
          {!selectedFileType && (
            <span
              onClick={() => setIsPdf(!isPdf)}
              role="button"
              className="btn btn-outline-secondary d-flex align-items-center "
              aria-disabled
            >
              {isPdf ? (
                <FaImage className="me-1" />
              ) : (
                <FaFilePdf className="me-1" />
              )}{" "}
              {isPdf ? "Format image" : "Format pdf"}
            </span>
          )}
        </div>
        {isPdf && (
          <FileUpload
            acceptedFileTypes={["application/pdf"]}
            maxFileSize="2MB"
            allowFileSizeValidation={true}
            onupdatefiles={(fileItems) => {
              setPdfFile(fileItems.map((fileItem) => fileItem.file));
            }}
            labelIdle='Glisser & deposer votre certification en pdf ou <span className="filepond--label-action">Parcourrir</span>'
            credits={null}
            name={"pdf_file"}
            labelFileProcessing="Transfert en cours"
            labelTapToCancel="Appuyer pour annuler"
            labelFileProcessingAborted="Transfert annulé"
            labelTapToRetry="Appuyer pour recommencer"
            labelMaxFileSize="La taille maximale est de 2 Mo"
            labelMaxFileSizeExceeded="Fichier trop volumineux"
            labelFileLoading="Chargement..."
            labelFileWaitingForSize="Vérification de la taille du fichier"
            onaddfile={() => setSelectedFileType(true)}
            allowRemove={removeFileEventActive}
            beforeRemoveFile={() => {
              if (removeFileEventActive) {
                setSelectedFileType(false);
                setRemoveFileEventActive(true);
              }
            }}
          />
        )}

        {!isPdf && (
          <FileUpload
            labelIdle="Choisir l'image de votre certification"
            acceptedFileTypes={["image/png", "image/jpeg"]}
            name={"url[]"}
            filesSource={imagesFiles}
            maxFileSize="2MB"
            allowFileSizeValidation={true}
            labelFileProcessing="Transfert en cours"
            labelTapToCancel="Appuyer pour annuler"
            labelFileProcessingAborted="Transfert annulé"
            labelTapToRetry="Appuyer pour recommencer"
            labelMaxFileSize="La taille maximale est de 2 Mo"
            labelMaxFileSizeExceeded="Fichier trop volumineux"
            labelFileLoading="Chargement..."
            labelFileWaitingForSize="Vérification de la taille du fichier"
            onupdatefiles={(fileItems) => {
              setImagesFiles(fileItems.map((fileItem) => fileItem.file));
            }}
            onaddfile={() => setSelectedFileType(true)}
            allowRemove={removeFileEventActive}
            beforeRemoveFile={() => {
              if (removeFileEventActive) {
                setSelectedFileType(false);
              }
            }}
          />
        )}

        <div className="d-flex justify-content-center">
          <p style={{ fontSize: "12px", fontWeight: "500", color: "#000" }}>
            <em>
              <strong className="fw-bold">NB:</strong> Vous pouvez choisir entre
              une version <strong className="fw-bold">pdf</strong> ou
              <strong className="fw-bold"> image</strong> de votre certification
            </em>
          </p>
        </div>

        {/* <Input
          type="file"
          multiple
          accept=".jpg, .jpeg, .png"
          label="Choisir image"
          htmlFor="url"
          register={{ ...register("url") }}
          error={errors.url}
        /> */}
        <TextArea
          minLength="10"
          maxLength="500"
          placeholder="Description"
          label="Description"
          htmlFor="description"
          register={{ ...register("description") }}
          error={errors.description}
        />
        <ButtonLoading
          loading={loading}
          text="Ajouter"
          className="btn-apply w-100 mt-3"
        />
      </form>
    </CustomModal>
  );
};

export default AddCertification;
