import React from 'react'

const SectionH2 = ({ children, styles }) => {
    return (
        <>
            <h2 style={styles} className='section_title'>
                {children}
            </h2>
            <div className='h2_underline'>
                <div className='h2_dots_container'>
                    <div className='h2_dots'></div>
                </div>
            </div>
        </>
    )
}

export default SectionH2