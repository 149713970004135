import React from 'react'
import LandingBtn from './LandingBtn'
import { useNavigate } from 'react-router-dom'
import TitleH2 from '../../../components/shared/TitleH2'

const Page404 = () => {
    const navigate = useNavigate()
    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className='text-center'>
                <TitleH2>Page 404</TitleH2>
                <LandingBtn
                    onClick={() => navigate(-1)}
                    className='bg-color-green rounded'
                >
                    Retour
                </LandingBtn>
            </div>
        </div>
    )
}

export default Page404