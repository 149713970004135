import React from "react";
import { Route, Routes } from "react-router-dom";
import { MY_PROFILE_PAGE } from "../../constants/routes_path";
import Navbar from "../../layouts/Navbar/Navbar";
import Profile from "../Profile/Profile";
import EtudiantDash from "./EtudiantDash";

const EtudiantHomePage = () => {
  return (
    <div>
      <Navbar />
      <div className="container-lg mt-2 px-2">
        <Routes>
          <Route path="/*" element={<EtudiantDash />} />
          <Route path={MY_PROFILE_PAGE} element={<Profile />} />
        </Routes>
      </div>
    </div>
  );
};

export default EtudiantHomePage;
