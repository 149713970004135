import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonLoading from "../../../components/ButtonLoading";
import CustomModal from "../../../components/CustomModal";
import FileUpload from "../../../components/FileUpload";
import Input from "../../../components/shared/Input";
import TextArea from "../../../components/shared/TextArea";
import { ADD_PROJET_API_ROUTE } from "../../../constants/api_endpoints";
import { useUserId } from "../../../hooks/useUserId";
import { useGetProfileCompletionRateQuery } from "../../../redux/slices/etudiants/etudiantsSlice";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";

const AddProjetModal = ({ show, close, refetchProject }) => {
  const { userId } = useUserId();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, formState: errors } = useForm();
  const [files, setFiles] = useState([]);
  // const { refetch } = useGetEtudiantProjetsQuery()
  // Get Profile completion rate
  const { refetch: refetchCompletionRate } =
    useGetProfileCompletionRateQuery(userId);

  const submitForm = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data?.description);
    formData.append("lien_depot", data?.lien_depot);
    formData.append("link_project", data?.link_project);
    formData.append("user_id", userId);
    for (let i = 0; i < files.length; i++) {
      formData.append("url[]", files[i].file);
    }

    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_URL + ADD_PROJET_API_ROUTE, formData)
      .then((res) => {
        refetchProject();
        refetchCompletionRate();
        toastAlertSuccess("Projet ajouté");
      })
      .catch((err) => {
        toastAlertError("Une erreur est survenue");
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  };

  return (
    <CustomModal
      modalTitle="Ajouter un projet"
      show={show}
      handleClose={() => {
        close();
        reset();
        setFiles([]);
      }}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          type="text"
          placeholder="Gestion de budget"
          label="Nom projet"
          register={{ ...register("name") }}
          error={errors.name}
          required
          htmlFor="name"
          minLength="5"
          maxLength="90"
          name="name"
        />
        <Input
          type="url"
          placeholder="www.github.com/lka/gestion-budget"
          label="Lien dépôt"
          html="lien_depot"
          // pattern="https://www.*"
          register={{ ...register("lien_depot") }}
          error={errors.lien_depot}
        />
        <Input
          type="url"
          placeholder="www.geston-budget.bakeli.tech"
          label="Lien projet"
          htmlFor="link_project"
          register={{ ...register("link_project") }}
          error={errors.link_project}
        />
        {/* <Input
          type="file"
          multiple
          accept=".jpg, .jpeg, .png"
          placeholder="www.geston-budget.bakeli.tech"
          label="Choisir images"
          htmlFor="url"
          register={{ ...register("url") }}
          error={errors.link_project}
        /> */}
        <FileUpload
          labelIdle="Choisir au moins 5 image de votre projet"
          acceptedFileTypes={["image/png", "image/jpeg"]}
          name={"url[]"}
          multiple
          maxFiles={5}
          filesSource={files}
          onupdatefiles={setFiles}
        />
        <TextArea
          minLength="10"
          maxLength="500"
          placeholder="Description du projet"
          label="Description"
          htmlFor="description"
          register={{ ...register("description") }}
          error={errors.description}
        />
        <ButtonLoading
          loading={loading}
          text="Ajouter"
          className="btn-apply w-100 mt-3"
        />
      </form>
    </CustomModal>
  );
};

export default AddProjetModal;
