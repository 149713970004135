import React from "react";
import { BiTime } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { FcOvertime } from "react-icons/fc";
import styled from "styled-components";
import { rootStyles } from "../../../styles/globalCss";

const EventContainer = styled.div`
  background-color: ${rootStyles.color.primary};
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${rootStyles.radius.card};
  position: relative;
  box-shadow: ${rootStyles.shadow.card};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const ImageContainer = styled.div`
  height: 150px;
  cursor: pointer;
`;
const CardBody = styled.div`
  padding: ${rootStyles.padding.btnPadding};
`;
const CardTitle = styled.h2`
  font-size: ${rootStyles.fonts.h2FontSize};
`;
const CardText = styled.p`
  font-size: ${rootStyles.fonts.fontSize};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CardFooter = styled.div`
  background-color: ${rootStyles.color.red};
`;
const Span = styled.span`
  font-weight: ${rootStyles.fonts.h2FontWeight};
  color: ${rootStyles.color.primary};
`;
const TimeSpan = styled.span`
  font-size: 12px;
  display: block;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const EvenementCardItem = ({ evenement, navigateToDetails }) => {
  const {
    rencontre_name,
    rencontre_description,
    created_at,
    rencontre_start_date,
    rencontre_start_time,
    // participant,
    rencontre_file,
  } = evenement;
  return (
    <EventContainer onClick={() => navigateToDetails(evenement)}>
      <ImageContainer>
        <img
          style={{ height: "100%", objectFit: "cover", width: "100%" }}
          src={
            "https://images.unsplash.com/photo-1505373877841-8d25f7d46678?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt={rencontre_name ?? "Rencontre img"}
          //   onError={replaceImage}
        />
      </ImageContainer>
      <CardBody>
        <CardTitle>{rencontre_name ?? "Event Name"}</CardTitle>
        <CardText>{rencontre_description ?? "Event description"}</CardText>
        <TimeSpan>
          <FcOvertime size={20} />
          Date:{" "}
          {new Date(rencontre_start_date ?? "2024/04/23").toLocaleString(
            "fr-Fr",
            {
              dateStyle: "short",
            }
          )}{" "}
          à {rencontre_start_time ?? "9h 15"}
        </TimeSpan>
        <TimeSpan>
          <BiTime size={20} />
          Créé le:{" "}
          {new Date(created_at ?? "2024/04/23").toLocaleString("fr-Fr", {
            dateStyle: "short",
          })}
        </TimeSpan>
      </CardBody>
      {/* <CardFooter className="d-flex justify-content-between align-items-center p-2">
        <Span>Inscrits</Span>
        <Span className="d-flex align-items-center gap-2">
          {participant?.length ?? 22}
          <FaUsers size={25} color={rootStyles.color.primary} />
        </Span>
      </CardFooter> */}
    </EventContainer>
  );
};

export default EvenementCardItem;
