import {
  GET_ALL_ENTREPRISES_API_ROUTE,
  GET_ENTREPRISE_DETAILS_API_ROUTE,
  GET_ENTREPRISE_KPI_API_ROUTE,
  GET_ETUDIANTS_FOR_ENTREPRISE,
  GET_OFFRES_BY_ENTREPRISES_API_ROUTE,
  GET_OFFRE_BEST_CANDIDATES,
  GET_OFFRE_DETAILS,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const entreprisesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEntreprises: builder.query({
      query: () => GET_ALL_ENTREPRISES_API_ROUTE,
    }),

    getEntrepriseDetails: builder.query({
      query: ({ id_entreprise }) =>
        `${GET_ENTREPRISE_DETAILS_API_ROUTE}${id_entreprise}`,
    }),
    getOffresByEntreprise: builder.query({
      query: ({ id_entreprise, params }) => {
        return {
          url: `${GET_OFFRES_BY_ENTREPRISES_API_ROUTE}${id_entreprise}`,
          method: "GET",
          params: params,
        };
      },
      providesTags: ["Offres"],
    }),

    getEntrepriseKpi: builder.query({
      query: ({ id_entreprise }) => {
        return `${GET_ENTREPRISE_KPI_API_ROUTE}${id_entreprise}`;
      },
    }),

    getEntrepriseOffreDetails: builder.query({
      query: ({ offre_id, params }) => {
        return {
          url: `${GET_OFFRE_DETAILS}${offre_id}`,
          method: "GET",
          params: params,
        };
      },
      providesTags: ["Offres"],
      //   transformResponse: (response) => response?.data[0],
    }),

    getOffreBestCandidates: builder.query({
      query: (offre_id) => `${GET_OFFRE_BEST_CANDIDATES}${offre_id}`,
      transformResponse: (response) => response?.data,
    }),

    getProfiles: builder.query({
      query: ({ params }) => {
        return {
          url: GET_ETUDIANTS_FOR_ENTREPRISE,
          method: "GET",
          params: params,
        };
      },
    }),
  }),
});

export const {
  useGetAllEntreprisesQuery,
  useGetOffresByEntrepriseQuery,
  useGetEntrepriseDetailsQuery,
  useGetEntrepriseKpiQuery,
  useGetEntrepriseOffreDetailsQuery,
  useGetOffreBestCandidatesQuery,
  useGetProfilesQuery,
} = entreprisesSlice;
