import React from 'react'
import { TiArrowBackOutline } from 'react-icons/ti'
import { useNavigate } from 'react-router-dom'

const NavigateBack = () => {
    const navigate = useNavigate()
    return (
        <button
            onClick={() => navigate(-1)}
            type="button"
            className='navigate--back'
        >
            <TiArrowBackOutline />
        </button>
    )
}

export default NavigateBack