import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import CustomModal from "../../components/CustomModal";
import {
  useModifierExperienceEtudiantMutation,
  useSupprimerExperienceEtudiantMutation,
} from "../../redux/slices/etudiants/etudiantsSlice";
import { yearMonthDayFormatter } from "../../utils/timeFormatters";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";
import ButtonLoading from "../../components/ButtonLoading";

const EditExperienceModal = ({ show, close, refetch, experience }) => {
  // Initials states
  const [startDate, setStartDate] = React.useState("");
  const [loading, setLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  // const [setEndDate] = React.useState("");
  const currentDate = new Date();

  // Use edit experience mutation
  const [editExperience] = useModifierExperienceEtudiantMutation();

  // Use delete experience mutation
  const [deleteExperience] = useSupprimerExperienceEtudiantMutation();

  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    nom_entreprise: Yup.string().required("Obligatoire"),
    poste_occupe: Yup.string().required("Obligatoire"),
    date_debut: Yup.string().required("Obligatoire"),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: experience?.name,
      nom_entreprise: experience?.nomEntreprise,
      poste_occupe: experience?.posteOccupe,
      date_debut: experience?.dateDebut,
      date_fin: experience?.dateFin,
      description: experience?.description,
    },
    values: {
      name: experience?.name,
      nom_entreprise: experience?.nomEntreprise,
      poste_occupe: experience?.posteOccupe,
      date_debut: experience?.dateDebut,
      date_fin: experience?.dateFin,
      description: experience?.description,
    },
  });
  const { errors } = formState;

  // Function to reset the input field after submit
  const resetInputFields = () => {
    reset({
      name: "",
      nomEntreprise: "",
      posteOccupe: "",
      dateDebut: "",
      dateFin: "",
      description: "",
    });
    setStartDate("");
    // setEndDate("");
  };

  // Submit function
  const handleEditExperience = (data) => {
    setLoading(true)
    editExperience(data)
      .unwrap()
      .then((res) => {
        refetch();
        toastAlertSuccess(res.message);
        close();
      })
      .catch((err) => {
        toastAlertError(err.message);
      }).finally(() => {
        setLoading(false)
      })
  };

  // Delete function
  const handleDelete = (data) => {
    setIsDeleting(true)
    deleteExperience(data?.id)
      .unwrap()
      .then((res) => {
        refetch();
        toastAlertSuccess(res.message);
        close();
      })
      .catch((err) => {
        toastAlertError(err.message);
      }).finally(() => {
        setIsDeleting(false)
      })
  };

  return (
    <CustomModal
      modalTitle="Modifier une expérience"
      show={show}
      handleClose={() => {
        close();
        resetInputFields();
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          handleEditExperience({
            experience_id: experience?.id,
            payload: data,
          });
        })}
      >
        <div className="mb-1">
          <label htmlFor="titrePoste" className="form-label">
            Titre du poste
          </label>
          <input
            type="text"
            className={`form-control ${errors.name && "is-invalid"}`}
            id="name"
            // name="name"
            // onChange={(e) => setExperience({ name: e.target.value })}
            // defaultValue={experience?.name}
            {...register("name")}
          />
        </div>

        <div className="mb-1">
          <label htmlFor="nomEntreprise" className="form-label">
            Nom de l'entreprise
          </label>
          <input
            type="text"
            className={`form-control ${errors.nom_entreprise && "is-invalid"}`}
            id="nomEntreprise"
            // name="nom_entreprise"
            // onChange={(e) => setExperience({ nomEntreprise: e.target.value })}
            {...register("nom_entreprise")}
          />
        </div>

        <div className="mb-1">
          <label htmlFor="typeContrat" className="form-label">
            Type de contrat
          </label>
          {/* <input
            type="text"
            className="form-control"
            id="posteOccupe"
            // name="poste_occupe"
            // onChange={(e) => setExperience({ posteOccupe: e.target.value })}
            defaultValue={experience?.posteOccupe}
          /> */}
          <select
            className={`form-select ${errors.poste_occupe && "is-invalid"}`}
            aria-label="Default select example"
            id="posteOccupe"
            {...register("poste_occupe")}
          >
            {/* <option
                defaultValue={experience?.posteOccupe}
              disabled
              selected
              hidden
            >
              {experience?.posteOccupe}
            </option> */}
            <option value="Stage">Stage</option>
            <option value="CDD">CDD</option>
            <option value="CDI">CDI</option>
          </select>
        </div>

        <div className="row mb-1">
          <div className="col">
            <label htmlFor="dateDebut" className="form-label">
              Date debut
            </label>
            <input
              type="date"
              className={`form-control ${errors.date_debut && "is-invalid"}`}
              id="dateDebut"
              max={yearMonthDayFormatter(currentDate)}
              {...register("date_debut")}
            />
          </div>
          <div className="col">
            <label htmlFor="dateFin" className="form-label">
              Date fin
            </label>

            <input
              type="date"
              className="form-control"
              id="dateFin"
              min={startDate}
              {...register("date_fin")}
              disabled={
                experience?.dateDebut === "" ||
                (experience?.dateDebut === undefined && true)
              }
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            placeholder="Description de vos missions"
            id="description"
            // name="description"
            // onChange={(e) => setExperience({ description: e.target.value })}
            {...register("description")}
          ></textarea>
        </div>
        <div className="text-end">
          <ButtonLoading
            loading={loading}
            text={"Enregistrer"}
            className="btn-apply"
          />
          <ButtonLoading
            onClick={() => handleDelete(experience)}
            type="button"
            loading={isDeleting}
            text={"Supprimer"}
            className="btn-danger"
          />
          {/* <button
            type="button"
            className="btn btn-red m-1"
            onClick={() => handleDelete(experience)}
          >
            Supprimer l'expérience
          </button>
          <button type="submit" className="btn btn-apply m-1">
            Enregistrer & Continuer
          </button> */}
        </div>
      </form>
    </CustomModal>
  );
};

export default EditExperienceModal;
