import React from 'react'
import EtudiantListItem from './EtudiantListItem'
import ResourceDataMap from './shared/ResourceDataMap'

/**
 * @param {currentItems:array} param0 
 * @returns 
 */

// ! ResourceDataMap permet de mapper n'importe quelle liste
// ! ListeEtudiantsDataMapper permet de mapper les offres
// ! EtudiantListItem est le cardItem qui affiche une offre
// ! ****************** currentItems vient de la pagination *****************

const ListeEtudiantsDataMapper = ({ currentItems }) => {
    return (
        <>
            <ResourceDataMap
                resourceItem={EtudiantListItem}
                resourceName="etudiant"
                resourceData={currentItems}
            />
        </>
    )
}

export default ListeEtudiantsDataMapper