import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import Button from "../../components/shared/Button";
import InputForm from "../../components/shared/InputForm";
import TitleH2 from "../../components/shared/TitleH2";
import { LOGIN_PATH } from "../../constants/routes_path";
import logo from "../../image/logo.png";
import { useRegisterEtudiantMutation } from "../../redux/slices/authSlice/registerApiSlice";
import { useGetAllEtablissementsQuery } from "../../redux/slices/etablissements/etablissementsSlice";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";
import { emailPattern, phoneNumberPattern } from "../../utils/validateForm";

const RegisterEtudiant = () => {
  // Phone number regex
  const phoneNumberRegex = /^(77|78|76|75|70)\d{7}$/;

  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Veuillez remplir ce champ"),
    email: Yup.string()
      .required("Email obligatoire")
      .email("Cet email n'est pas valide"),
    password: Yup.string()
      .required("Mot de passe obligatoire")
      .min(8, "Le mot de passe doit avoir au moins 8 caractères")
      .max(32),
    location: Yup.string().required("Veuillez remplir ce champ"),
    phone: Yup.string()
      .required("Numéro téléphone obligatoire")
      .matches(phoneNumberRegex, "Numéro téléphone invalide. Ex: 77 777 77 77"),
    etablissement_id: Yup.string().required("Établissement requis").nullable(),
    genre: Yup.string().required("Genre requis").nullable(),
    niveau_etude: Yup.string().required("Niveau d'étude requis").nullable(),
    nb_an_experiences: Yup.string()
      .required("Années d'expériences requis")
      .nullable(),
    fonction: Yup.string()
      .required("Votre fonction est obligatoire")
      .nullable(),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    control, // Used by controller wrapper component for controlled inputs
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm(formOptions);

  const location = useLocation();
  // React router navigate s
  const navigate = useNavigate();
  // Use register etudiant mutation
  const [registerEtudiant, { isLoading }] = useRegisterEtudiantMutation();

  const inscriptionEtudiant = (data) => {
    if (data.phone.match(phoneNumberPattern) !== null) {
      if (data.email.match(emailPattern) !== null) {
        registerEtudiant(data)
          .unwrap()
          .then((res) => {
            reset();
            navigate(LOGIN_PATH, { state: { from: location?.state?.from } });
            toastAlertSuccess(res.message);
          })
          .catch((err) => {
            toastAlertError(err.data.message);
          });
      } else {
        setError("email", {
          type: "custom",
          message: "Email invalide. (Ex. email correcte xyz@gmail.com)",
        });
      }
    } else {
      setError("phone", {
        type: "custom",
        message: "Numéro de téléphone invalide.)",
      });
    }
  };

  // Options
  const levelOptions = [
    { value: "bfem", label: "BFEM" },
    { value: "bac", label: "Bac" },
    { value: "licence 1", label: "Licence 1" },
    { value: "licence 2", label: "Licence 2" },
    { value: "licence 3", label: "Licence 3" },
    { value: "master 1", label: "Master 1" },
    { value: "master 1", label: "Master 2" },
  ];
  const experienceOptions = [
    { value: "1", label: "1 an" },
    { value: "2", label: "2 ans" },
    { value: "3", label: "3 ans" },
    { value: "4", label: "4 ans" },
    { value: "5", label: "5 ans" },
    { value: "6", label: "6 ans" },
    { value: "7", label: "7 ans" },
    { value: "8", label: "8 ans" },
    { value: "9", label: "9 ans" },
    { value: "10", label: "10 ans" },
  ];
  const functionOptions = [
    { value: "Développeur Web", label: "Développeur Web" },
    { value: "Développeur Mobile", label: "Développeur Mobile" },
    { value: "Développeur Logiciel", label: "Développeur Logiciel" },
    { value: "Développeur FullStack", label: "Développeur FullStack" },
    { value: "Designer Graphique", label: "Designer Graphique" },
    {
      value: "Spécialiste en Marketing Digital",
      label: "Spécialiste en Marketing Digital",
    },
    { value: "Chef de Projet", label: "Chef de Projet" },
    { value: "Rédacteur Web", label: "Rédacteur Web" },
    { value: "Analyste de Données", label: "Analyste de Données" },
    {
      value: "Gestionnaire de Réseaux Sociaux",
      label: "Gestionnaire de Réseaux Sociaux",
    },
    { value: "Ingénieur Informatique", label: "Ingénieur Informatique" },
    {
      value: "Comptable / Analyste Financier",
      label: "Comptable / Analyste Financier",
    },
    {
      value: "Responsable des Ressources Humaines",
      label: "Responsable des Ressources Humaines",
    },
    {
      value: "Technicien de Support Technique",
      label: "Technicien de Support Technique",
    },
    { value: "Commercial / Vendeur", label: "Commercial / Vendeur" },
    {
      value: "Responsable des Relations Publiques",
      label: "Responsable des Relations Publiques",
    },
    { value: "Consultant en Stratégie", label: "Consultant en Stratégie" },
    { value: "Scientifique de la Vie", label: "Scientifique de la Vie" },
    { value: "Ingénieur Mécanique", label: "Ingénieur Mécanique" },
    { value: "Designer Industriel", label: "Designer Industriel" },
    { value: "Traducteur / Interprète", label: "Traducteur / Interprète" },
    {
      value: "Animateur 3D / Spécialiste en Effets Spéciaux",
      label: "Animateur 3D / Spécialiste en Effets Spéciaux",
    },
    { value: "Photographe / Vidéaste", label: "Photographe / Vidéaste" },
    {
      value: "Développeur de Jeux Vidéo",
      label: "Développeur de Jeux Vidéo",
    },
    { value: "Expert en Cyber Sécurité", label: "Expert en Cyber Sécurité" },
    {
      value: "Responsable de la Chaîne d'Approvisionnement",
      label: "Responsable de la Chaîne d'Approvisionnement",
    },
    {
      value: "Spécialiste en Commerce Électronique",
      label: "Spécialiste en Commerce Électronique",
    },
    { value: "Designer UX/UI", label: "Designer UX/UI" },
    {
      value: "Médecin / Professionnel de la Santé",
      label: "Médecin / Professionnel de la Santé",
    },
    { value: "Avocat / Juriste", label: "Avocat / Juriste" },
    {
      value: "Spécialiste de l'Environnement / Durabilité",
      label: "Spécialiste de l'Environnement / Durabilité",
    },
    { value: "Responsable Qualité", label: "Responsable Qualité" },
    { value: "Formateur / Enseignant", label: "Formateur / Enseignant" },
    { value: "Architecte", label: "Architecte" },
    {
      value: "Ingénieur en Intelligence Artificielle / Machine Learning",
      label: "Ingénieur en Intelligence Artificielle / Machine Learning",
    },
    {
      value: "Spécialiste du Développement Durable",
      label: "Spécialiste du Développement Durable",
    },
    {
      value: "Ingénieur en Énergie Renouvelable",
      label: "Ingénieur en Énergie Renouvelable",
    },
    {
      value: "Responsable Logistique / Transport",
      label: "Responsable Logistique / Transport",
    },
    {
      value: "Journaliste / Professionnel des Médias",
      label: "Journaliste / Professionnel des Médias",
    },
    { value: "Créateur de Contenu", label: "Créateur de Contenu" },
  ];
  const genreOptions = [
    { value: "H", label: "Homme" },
    { value: "F", label: "Femme" },
  ];

  const { data = [], isLoading: loadingEtablissements } =
    useGetAllEtablissementsQuery();

  // Format data for React Select
  const etablissements = data?.map((option) => ({
    value: option.id,
    label: option?.user?.name,
  }));

  return (
    <div className="auth-form p-2">
      <form onSubmit={handleSubmit(inscriptionEtudiant)}>
        <div className="text-center mt-5">
          <img src={logo} alt="" className="taille-img mb-4" />
          <TitleH2>Inscription Etudiant</TitleH2>
          <p>
            Développez votre avenir professionnel avec notre plateforme de
            gestion de stage, connectant les talents émergents aux opportunités
            qui propulsent les carrières.
          </p>

          <p className="mt-3 text-center">
            Vous avez deja un compte{" "}
            <span
              onClick={() =>
                navigate(LOGIN_PATH, { state: { from: location?.state?.from } })
              }
              className="link"
            >
              connectez-vous ici
            </span>{" "}
          </p>
        </div>
        {/* <div className="col-md-6"> */}
        <InputForm
          placeholder="Nom Complet*"
          type="text"
          register={{ ...register("name") }}
          htmlFor="name"
          error={errors.name?.message}
        />
        <InputForm
          placeholder="Email*"
          type="email"
          register={{ ...register("email") }}
          htmlFor="email"
          error={errors.email?.message}
        />
        <InputForm
          placeholder="Adresse*"
          type="text"
          register={{ ...register("location") }}
          htmlFor="location"
          error={errors.location?.message}
        />
        <InputForm
          placeholder="Mot de passe*"
          type="password"
          register={{ ...register("password") }}
          htmlFor="password"
          error={errors.password?.message}
        />
        <InputForm
          placeholder="Téléphone*"
          type="phone"
          register={{ ...register("phone") }}
          htmlFor="phone"
          error={errors.phone?.message}
        />

        <div className="d-flex align-center">
          <div className="w-75">
            <Controller
              name="etablissement_id"
              control={control}
              defaultValue={null}
              render={({ field: { onChange } }) => (
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid black",
                      fontSize: "12px",
                      color: "#000000",
                      //   fontWeight: "bold",
                      height: "50px",
                      outline: "none",
                    }),
                  }}
                  options={etablissements}
                  onChange={(item) => onChange(item.value)}
                  className="w-100 input-form border-bottom-0 ps-0"
                  isSearchable
                  isLoading={loadingEtablissements}
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Établissements (Ex: ISI, ISEP, UCAD, UNCHK...)"
                />
              )}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "-8px",
                padding: "0 10px",
                color: "red",
              }}
            >
              {errors.etablissement_id?.message}
            </p>
          </div>

          <div className="w-25">
            <Controller
              name="genre"
              control={control}
              defaultValue={""}
              render={({ field: { onChange } }) => (
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid black",
                      fontSize: "12px",
                      color: "#000000",
                      //   fontWeight: "bold",
                      height: "50px",
                      outline: "none",
                    }),
                  }}
                  options={genreOptions}
                  onChange={(item) => onChange(item.value)}
                  className="w-100 input-form border-bottom-0 pe-0"
                  isSearchable
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Genre"
                />
              )}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "-8px",
                padding: "0 10px",
                color: "red",
              }}
            >
              {errors.genre?.message}
            </p>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className="w-50">
            <Controller
              name="niveau_etude"
              control={control}
              defaultValue={""}
              render={({ field: { onChange } }) => (
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid black",
                      fontSize: "12px",
                      color: "#000000",
                      // fontWeight: "bold",
                      height: "50px",
                      outline: "none",
                    }),
                  }}
                  options={levelOptions}
                  onChange={(item) => onChange(item.value)}
                  className="w-100 input-form border-bottom-0 ps-0"
                  isSearchable
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Niveau d'étude"
                />
              )}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "-8px",
                padding: "0 10px",
                color: "red",
              }}
            >
              {errors.niveau_etude?.message}
            </p>
          </div>

          <div className="w-50">
            <Controller
              name="nb_an_experiences"
              control={control}
              defaultValue={""}
              render={({ field: { onChange } }) => (
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid black",
                      fontSize: "12px",
                      color: "#000000",
                      // fontWeight: "bold",
                      height: "50px",
                      outline: "none",
                    }),
                  }}
                  options={experienceOptions}
                  onChange={(item) => onChange(item.value)}
                  className="w-100 input-form border-bottom-0 pe-0"
                  isSearchable
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Niveau d'expérience"
                />
              )}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "-8px",
                padding: "0 10px",
                color: "red",
              }}
            >
              {errors.nb_an_experiences?.message}
            </p>
          </div>
        </div>

        <Controller
          name="fonction"
          control={control}
          defaultValue={""}
          render={({ field: { onChange } }) => (
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: "1px solid black",
                  fontSize: "12px",
                  color: "#000000",
                  //   fontWeight: "bold",
                  height: "50px",
                  outline: "none",
                }),
              }}
              options={functionOptions}
              onChange={(item) => onChange(item.value)}
              className="w-100 input-form border-bottom-0 px-0"
              isSearchable
              noOptionsMessage={() => "Pas d'options"}
              placeholder="Fonction (Ex: développeur frontend, Etudiant en commerce..)"
            />
          )}
        />
        <p
          style={{
            fontSize: "12px",
            marginTop: "-8px",
            padding: "0 10px",
            color: "red",
          }}
        >
          {errors.fonction?.message}
        </p>

        <div className=" mt-3">
          <Button
            type="submit"
            className="btn-add w-100 py-2"
            // disabled={email?.length === 0 || password?.length === 0}
          >
            {isLoading ? "Inscription en cours..." : "Valider"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegisterEtudiant;
