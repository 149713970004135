import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { object, ref, string } from "yup";
import InputForm from "../../components/shared/InputForm";

import ButtonLoading from "../../components/ButtonLoading";
import { useResetPasswordMutation } from "../../redux/slices/authSlice/resetPasswordSlice";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";

const ResetPassword = () => {
  // State
  const [searchParams] = useSearchParams();

  const formSchema = object().shape({
    password: string()
      .required("Mot de passe obligatoire")
      .min(8, "Password length should be at least 8 characters"),
    password_confirmation: string()
      .required("Confirmation du mot de passe obligatoire")
      .oneOf([ref("password")], "Les mots de passe ne correspondent pas"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm(formOptions);

  // Get url params
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const navigate = useNavigate();

  // Mutation
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const submit = (values) => {
    resetPassword(values)
      .unwrap()
      .then((res) => {
        navigate("/login");
        toastAlertSuccess(res.message);
      })
      .catch((err) => {
        toastAlertError(err.data.message);
        reset();
      });
  };

  return (
    <div className="auth-form p-2 mt-5">
      <div className="text-center mt-5 mb-3">
        <Link to={"/login"}>
          <span className="logo-pane">
            <span className="logo-green">Bakeli </span>
            <span className="logo-orange">Carriere</span>
          </span>
          <h1 style={{ fontSize: "20px", fontWeight: 700, lineHeight: "30px" }}>
            Réinitialisation mot de passe
          </h1>
        </Link>
      </div>
      <form
        onSubmit={handleSubmit((data) => {
          data.token = token;
          data.email = email;
          submit(data);
        })}
      >
        <InputForm
          placeholder="Nouveau mot de passe"
          type="password"
          register={{ ...register("password") }}
          htmlFor="password"
          required
          error={errors.password?.message}
        />
        <InputForm
          placeholder="Confirmer le mot de passe"
          type="password"
          register={{
            ...register("password_confirmation"),
            validate: (value) => {
              const { password } = getValues();
              return password === value || "Passwords should match!";
            },
          }}
          htmlFor="password"
          // required
          error={errors.password_confirmation?.message}
        />
        <div className="mt-3">
          <ButtonLoading
            loading={isLoading}
            text="Envoyer"
            // disabled={email?.length === 0 || password?.length === 0 || loading}
            className="btn-apply p-2"
          />
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
