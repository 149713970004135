import React from "react";
import { onError } from "../utils/onError";
// import "./Entreprises.css";

const OffresCardEtablissement = ({
  titre,
  type,
  datePublication,
  location,
  image,
  entreprise
}) => {
  return (
    (
      <div style={{ boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2)' }} className="row card flex-row border-0 my-4 mx-auto bg-white rounded offresCard bg-white py-4 px-2">
        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-12 mb-sm-3 mb-3">
          <img
            className="card-img-left "
            src={image} alt=""
            onError={onError}
          />
        </div>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 card-body py-0 m-0 ms-xl-5">
          <p className="card-title">{titre}</p>
          {/* <div className="d-flex align-center justify-content-between">
          <span class="badge text-bg-dark">Primary</span>
        </div> */}
          <h2 className="card-text d-flex align-items-center fw-bold">{entreprise}</h2>
          <div className="row">
            <div className="col-xl-10 col-lg-10 col-md-9 mb-2">
              <span className="fs-6">{`${type} | ${location} | Publiée le ${datePublication}`}</span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default OffresCardEtablissement;
