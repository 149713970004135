import React from 'react'
import { NavLink } from 'react-router-dom'
import ResourceDataMap from '../../../components/shared/ResourceDataMap'
import { entrepriseLinks } from '../../../routes/sidebarLinks'

const Sidebar = () => {
    return (
        <nav
            style={{
                height: '80vh',
                position: 'sticky',
                top: 20,
                borderRadius: '10px'
            }}
            className='bg-white d-flex flex-column p-3'
        >
            <ResourceDataMap
                resourceItem={({ linkItem }) => <NavLink
                    className='sidebar-entreprise d-flex align-items-center gap-2 px-3'
                    to={linkItem.to}
                    end
                >
                    <linkItem.icon size={20} />   <span className='fs-6 mt-1'>{linkItem.label}</span>
                </NavLink>}
                resourceData={entrepriseLinks}
                resourceName="linkItem"
            />
            {/* <NavLink className='sidebar-entrprise' to="">Acceuil</NavLink>
            <NavLink className='sidebar-entrprise' to="">Candidatures</NavLink>
            <NavLink className='sidebar-entrprise' to="">Offres</NavLink>
            <NavLink className='sidebar-entrprise' to={ENTREPRISE_ENTITIES_PAGE_PATH}>Etablissements</NavLink> */}
        </nav>
    )
}

export default Sidebar