import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../../layouts/Navbar/Navbar";
import { entrepriseRoutes } from "../../routes/routes";
// import { NavLink } from 'react-bootstrap';
import BreadCrumps from "../landingPage/components/shared/BreadCrump";
import Sidebar from "./components/Sidebar";

const EntrepriseHomePage = () => {
  return (
    <div className="mb-5">
      <Navbar />
      <div
        style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}
        className="container-fluid mt-0"
      >
        <div className="row pt-3">
          <div className="col-2">
            <Sidebar />
          </div>
          <div className="col-10">
            <BreadCrumps />
            <Routes>
              {entrepriseRoutes.map((route, index) => {
                if (route.children) {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    >
                      {route.children.map((chilRoute) => (
                        <Route
                          key={chilRoute.path}
                          path={chilRoute.path}
                          element={chilRoute.element}
                        />
                      ))}
                    </Route>
                  );
                }
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                );
              })}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrepriseHomePage;
