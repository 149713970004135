import { Navigate, Outlet } from "react-router-dom";
import {
  ENTREPRISE_HOME_PAGE_PATH,
  ETABLISSEMENT_HOME_PAGE_PATH,
  ETUDIANT_HOME_PAGE_PATH,
  LOGIN_PATH,
} from "../constants/routes_path";
import Header from "../pages/landingPage/components/Header";

export const ProtectedRoutes = () => {
  let auth = localStorage.getItem("access_token");
  return auth ? <Outlet /> : <Navigate to={LOGIN_PATH} />;
};

export const CheckAuthStatus = () => {
  let auth = localStorage.getItem("access_token");
  let user = JSON.parse(localStorage.getItem("user"));

  return auth && user?.type === "etudiant" ? (
    <Navigate to={ETUDIANT_HOME_PAGE_PATH} replace />
  ) : auth && user?.type === "etablissement" ? (
    <Navigate to={ETABLISSEMENT_HOME_PAGE_PATH} replace />
    ) : auth && user?.type === "entreprise" ? (
      <Navigate to={ENTREPRISE_HOME_PAGE_PATH} replace />
      ) : (
    <Outlet />
  );
};

export const CheckLandinPageAuthStatus = () => {
  let auth = localStorage.getItem("access_token");
  let user = JSON.parse(localStorage.getItem("user"));

  return auth && user?.type === "etudiant" ? (
    <Navigate to={ETUDIANT_HOME_PAGE_PATH} replace />
  ) : auth && user?.type === "etablissement" ? (
    <Navigate to={ETABLISSEMENT_HOME_PAGE_PATH} replace />
  ) : auth && user?.type === "entreprise" ? (
    <Navigate to={ENTREPRISE_HOME_PAGE_PATH} replace />
  ) : (
    <>
      <Header />
      <Outlet />
    </>
  );
};