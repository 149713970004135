import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useUserId } from "../hooks/useUserId";
import { useModifierMotivationEtudiantMutation } from "../redux/slices/etudiants/motivationSlice";
// import { FiDelete } from 'react-icons/fi';
// import { BiTrash } from 'react-icons/bi';

const EditMotivation = ({ motivation, cancel }) => {
  const { userId } = useUserId();
  const formSchema = Yup.object().shape({
    motivations: Yup.string().required("Obligatoire"),
  });
  // const [deleteMotivation] = useSupprimerMotivationEtudiantMutation()
  const [editEtudiantMotivation] = useModifierMotivationEtudiantMutation();
  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    formState: errors,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      motivations: motivation?.motivations,
    },
    values: {
      motivations: motivation?.motivations,
    },
  });
  const [loading, setLoading] = useState(false);

  // const deleteMotivationFunc = () => {
  //     deleteMotivation(motivation.id)
  //         .then((res) => {
  //             cancel()
  //         })
  //         .finally(() => {
  //             setLoading(false)
  //             cancel()
  //         })
  // }

  const submit = (data) => {
    setLoading(true);
    data.user_id = userId;
    editEtudiantMotivation({ motivation_id: motivation.id, payload: data })
      .then((res) => {
        if (res.status) {
          cancel();
        }
      })
      .finally(() => {
        setLoading(false);
        cancel();
      });
  };

  return (
    <div className="mb-4 white-bg px-2 py-3 mx-auto formation-card">
      <form onSubmit={handleSubmit(submit)}>
        <textarea
          rows={6}
          {...register("motivations")}
          className={`form-control ${errors.motivations && "is-invalid"}`}
          type="text"
          placeholder="Motivation"
        />
        <div className="d-flex gap-2 mt-3">
          {/* <button onClick={deleteMotivationFunc} type="button" className='btn btn-danger'>
                        <BiTrash />
                    </button> */}
          {/* <ButtonLoading loading={loading} text="Ajouter" className="btn-success" /> */}
          <button type="submit" className="btn btn-success">
            {!loading ? (
              <span>Enregistrer</span>
            ) : (
              <div
                style={{ width: "20px", height: "20px" }}
                className="spinner-border ms-3"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditMotivation;
