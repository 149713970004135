import { cloneElement, useDeferredValue, useRef, useState } from "react";
import { GridList } from "react-flexible-list";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import SearchFilter from "../../../components/SearchFilter";
import TitleH2 from "../../../components/shared/TitleH2";
import Wysiwyg from "../../../components/shared/Wysiwyg";
import {
  ETUDIANT_HOME_PAGE_PATH,
  LANDINGPAGE_OFFRES_PATH,
  OFFRES_PATH,
} from "../../../constants/routes_path";
import { setOffreId } from "../../../redux/reducers/offreReducer";
import {
  useGetAllOffresQuery,
  useGetOffreByIdQuery,
} from "../../../redux/slices/offres/offresSlice";
import { SpinnerLoaderSearch } from "../../../utils/spinnersLoader";
import FadeTransition from "../components/Fadetransition";
import LandingBtn from "../components/LandingBtn";
import LandingPageEntrepriseBanniere from "../components/LandingPageEntrepriseBanniere";
import OffreCardItem from "../components/OffreCardItem";
import ProgressLoading from "../components/shared/ProgressLoading";

const JobListsPane = styled.div`
  flex: 1;
  padding: 20px 0.5rem 5rem;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-padding-top: 50%;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
  }
`;
const CompanyJobDescriptionPane = styled.div`
  // width: 100%
  // flex:2;
`;
const OffreBodyPane = styled.div`
  padding: 1.5rem 15px;

  @media screen and (max-width: 992px) {
    padding: 10px;
  }
`;
const OffreTitlePane = styled.h2`
  margin: 20px 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  color: #0d0140;

  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
`;
const OffreDescriptionPane = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
  padding: 30px 0 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
  }
`;
const ActionsPane = styled.div`
  padding: 0px 20px;
  max-width: 300px;
  border-left: 1px solid rgba(20, 40, 50, 0.4);
`;
const ParagraphePane = styled.div`
  // all: revert !important;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  margin: 20px 0;
`;

const LandingPageDetailsOffre = () => {
  const { offre_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchItem, setSearchItem] = useState("");
  const deferedSearchItem = useDeferredValue(searchItem);

  const clickedElementRef = useRef(null);

  const { offreId } = useSelector((state) => state.offres);

  const {
    data: offres,
    isLoading: isOffresLoading,
    isFetching,
    // refetch,
  } = useGetAllOffresQuery(deferedSearchItem);

  const {
    data: detailsOffre = {
      data: {
        titre: "",
        description: "",
        entreprise: {
          user: { name: "" },
          secteur: "",
          effectif: 0,
        },
      },
    },
    isLoading,
    // refetch,
    isFetching: isDetailsOffreFetching,
  } = useGetOffreByIdQuery(offreId || Number(offre_id));

  const navigateToDetails = ({ offre_id, refId }) => {
    dispatch(setOffreId(offre_id));
    // document.getElementById(refId).scrollIntoView()
    return navigate(LANDINGPAGE_OFFRES_PATH + "/" + offre_id, {
      state: { data: offre_id },
    });
  };

  const ClonedOffreCardItem = ({ offre }) =>
    cloneElement(<OffreCardItem ref={clickedElementRef} offre={offre} />, {
      navigateToDetails,
    });

  const resetStates = () => {
    setSearchItem("");
  };

  // useEffect(() => {
  //     clickedElementRef?.current?.scrollToView()
  // })

  return (
    <FadeTransition
      // key={detailsOffre?.data.titre}
      in={true}
    >
      <div className="">
        <div className="row">
          <div className="col-lg-4 col-12 order-lg-2 order-2 p-0 p-lg-2">
            <div className="mb-2">
              <SearchFilter
                inputState={searchItem}
                inputSetState={setSearchItem}
                resetState={resetStates}
              />
              <div className="mt-3">
                {searchItem && (
                  <TitleH2>
                    {!isFetching ? offres?.count : "..."} Résultats pour{" "}
                    <strong style={{ color: "#009688" }}>{searchItem}</strong>
                  </TitleH2>
                )}
              </div>
            </div>
            <JobListsPane>
              {isOffresLoading ? (
                <SpinnerLoaderSearch />
              ) : (
                <GridList
                  resourceData={offres?.data}
                  resourceItem={ClonedOffreCardItem}
                  resourceName="offre"
                  cardWidth={300}
                  emptyComponent={() => <span>Pas d'offres</span>}
                />
              )}
            </JobListsPane>
          </div>
          <CompanyJobDescriptionPane
            key={detailsOffre?.data?.description}
            className="col-lg-8 col-12 order-lg-2 order-1 p-0"
          >
            {(!isDetailsOffreFetching || !isOffresLoading) && (
              <LandingPageEntrepriseBanniere
                key={detailsOffre?.data?.description}
                isLoading={isLoading}
                name={detailsOffre?.data?.entreprise.user.name}
                effectif={detailsOffre?.data?.entreprise.effectif}
                secteur={detailsOffre?.data?.entreprise.secteur}
                banniere={`${process.env.REACT_APP_BASE_URL}entreprisesBannieres/${detailsOffre?.data?.entreprise?.banniere}`}
              />
            )}
            <OffreBodyPane>
              <OffreTitlePane>
                {isDetailsOffreFetching || isOffresLoading ? (
                  <ProgressLoading />
                ) : (
                  <FadeTransition key={detailsOffre?.data?.titre} in={true}>
                    {detailsOffre?.data?.titre}
                  </FadeTransition>
                )}
              </OffreTitlePane>
              <div className="row px-0">
                <OffreDescriptionPane className="col-lg-9 col-12 order-lg-1 order-2">
                  <ParagraphePane>
                    {isDetailsOffreFetching || isOffresLoading ? (
                      <ProgressLoading mt="mt-4" />
                    ) : (
                      <FadeTransition
                        key={detailsOffre?.data?.description}
                        in={true}
                      >
                        <Wysiwyg contenu={detailsOffre?.data?.description} />
                      </FadeTransition>
                    )}
                  </ParagraphePane>
                </OffreDescriptionPane>
                <ActionsPane className="col-lg-3 col-12 order-lg-2 order-1 p-lg-2 p-2">
                  {new Date(detailsOffre?.data?.date_expiration) >
                  new Date() ? (
                    <LandingBtn
                      onClick={() =>
                        navigate("/login", {
                          state: {
                            from: offreId
                              ? ETUDIANT_HOME_PAGE_PATH +
                                "" +
                                OFFRES_PATH +
                                "/" +
                                offreId
                              : ETUDIANT_HOME_PAGE_PATH +
                                "" +
                                OFFRES_PATH +
                                "/" +
                                offre_id,
                          },
                        })
                      }
                      className="bg-color-green rounded py-lg-2 px-lg-3 px-2 fs-5 w-100"
                    >
                      Postulez
                    </LandingBtn>
                  ) : (
                    <LandingBtn
                      type="button"
                      disabled
                      className="bg-color-green rounded py-lg-2 px-lg-3 px-2 fs-5 w-100 opacity-25 pe-none"
                    >
                      Postulez
                    </LandingBtn>
                  )}
                  <div className="mt-2">
                    <p className="fs-5 fw-bold mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        width={18}
                        height={18}
                        className="me-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                        />
                      </svg>
                      {detailsOffre?.data?.offre_type}
                    </p>
                    {!detailsOffre?.data?.date_expiration ? (
                      <span className="text-warning fs-6">
                        {" "}
                        Pas de date d'expiration specifiée
                      </span>
                    ) : new Date(detailsOffre?.data?.date_expiration) <
                      new Date() ? (
                      <span className="d-flex align-items-center fw-bold text-danger fs-6">
                        Offre expirée{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          width={18}
                          height={18}
                          className="ms-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                          />
                        </svg>
                      </span>
                    ) : (
                      <p className="text-success">
                        Disponible jusqu'au{" "}
                        {new Date(
                          detailsOffre?.data?.date_expiration
                        ).toLocaleString("fr-Fr", {
                          dateStyle: "short",
                        })}
                      </p>
                    )}
                  </div>
                  {/* <button
                                        className='btn btn-primary p-2 px-4 text-center'
                                    >
                                        Postuler
                                    </button> */}
                </ActionsPane>
              </div>
            </OffreBodyPane>
          </CompanyJobDescriptionPane>
        </div>
      </div>
    </FadeTransition>
  );
};

export default LandingPageDetailsOffre;
