import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import ButtonLoading from "../../components/ButtonLoading";
import InputForm from "../../components/shared/InputForm";
import { useSendPasswordResetMutation } from "../../redux/slices/authSlice/resetPasswordSlice";
import { toastAlertError, toastAlertInfo } from "../../utils/toastAlerts";

const ForgotPassword = () => {
  // state
  const [loading, setLoading] = useState(false);

  // Schema validation
  const formSchema = object().shape({
    email: string().email("Email obligatoire"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  // FUNC
  const navigate = useNavigate();

  // Mutations
  const [sendPasswordResetLink] = useSendPasswordResetMutation();

  // Form func
  const submit = (values) => {
    setLoading(true);

    sendPasswordResetLink(values)
      .unwrap()
      .then((res) => {
        setLoading(false);
        navigate("/login");
        toastAlertInfo(res.message);
      })
      .catch((err) => {
        setLoading(false);
        toastAlertError(err.data.message);
      });
  };

  return (
    <div className="auth-form p-2 mt-5">
      <div className="text-center mt-5 mb-3">
        <Link to={"/login"}>
          <span className="logo-pane">
            <span className="logo-green">Bakeli </span>
            <span className="logo-orange">Carriere</span>
          </span>
          <h1 style={{ fontSize: "20px", fontWeight: 700, lineHeight: "30px" }}>
            Réinitialisation mot de passe
          </h1>
        </Link>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <InputForm
          placeholder="Email"
          type="email"
          register={{ ...register("email") }}
          htmlFor="email"
          required
          error={errors.email?.message}
        />
        <div className="mt-3">
          <ButtonLoading
            loading={loading}
            text="Envoyer"
            // disabled={email?.length === 0 || password?.length === 0 || loading}
            className="btn-apply p-2"
          />
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
