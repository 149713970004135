import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { MdEdit, MdOutlineLocalOffer } from "react-icons/md";
import Wysiwyg from "../../../components/shared/Wysiwyg";
import { useGetAllEtablissementsQuery } from "../../../redux/slices/etablissements/etablissementsSlice";
import {
  useApplyToOfferMutation,
  useApplyWithoutAccountMutation,
} from "../../../redux/slices/offres/offresSlice";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";
import { truncateString } from "../../../utils/truncateString";
import LandingBtn from "../../landingPage/components/LandingBtn";
import CreateUserForm from "./CreateUserForm";
import TypeBadge from "./TypeBadge";

const EntrepriseOffreCard = ({
  offre,
  navigateToCandidatureDetails,
  className,
  editOffre,
}) => {
  const [show, setShow] = useState(false);

  // get user
  const user = JSON.parse(localStorage.getItem("user"));

  const { data = [], isLoading: loadingEtablissements } =
    useGetAllEtablissementsQuery();

  // Format data for React Select
  const etablissements = data?.map((option) => ({
    value: option.id,
    label: option?.user?.name,
  }));

  // const [applyToOffer, { isLoading: isApplying }] = useApplyToOfferMutation();

  const [applyWithoutAccount, { isLoading: isApplying }] =
    useApplyWithoutAccountMutation();

  // Async function for the apply offer mutation
  //   const applyToJob = async ({ e, user_id, offre_id, entreprise_id }) => {
  //     e.preventDefault();
  //     console.log("okkkkk", offre_id);

  //     await applyToOffer({
  //       user_id: user_id,
  //       offre_id: offre_id,
  //       entreprise_id,
  //     })
  //       .unwrap()
  //       .then((res) => {
  //         res?.success === true && toastAlertSuccess(res?.message);
  //       })
  //       .catch((err) => {
  //         toastAlertError(err.data.message);
  //       });
  //   };

  const createUser = async (data) => {
    await applyWithoutAccount({ offre_id: offre?.id, data: data })
      .unwrap()
      .then((res) => {
        // response true
        // console.log("success", res);
        setShow(false);
        res?.success === true && toastAlertSuccess(res?.message);
      })
      .catch((err) => {
        // Catch error
        console.error("error", err);
        setShow(false);
        toastAlertError(err.data.message);
      });
  };

  return (
    <div
      onClick={() =>
        localStorage.getItem("access_token") &&
        navigateToCandidatureDetails(offre)
      }
      className={`${className ?? "col-sm-6 col-12 mt-2"}  align-items-center`}
      style={{ minHeight: "250px", cursor: "pointer" }}
    >
      {/* Create user and apply if has no account */}
      <CreateUserForm
        show={show}
        close={() => setShow(false)}
        createUser={createUser}
        etablissements={etablissements}
        loadingEtablissements={loadingEtablissements}
        isApplying={isApplying}
      />
      <div
        style={{
          backgroundColor: "white",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          height: "100%",
        }}
        className="align-items-center gap-2 p-3 "
      >
        <div className="d-flex justify-content-between align-items-center">
          <div
            style={
              {
                // height: '100%'
              }
            }
            className="pe-sm-2"
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "rgba(15,20,25,0.3)",
                color: "white",
                borderRadius: "50px",
                boxShadow:
                  "0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2)",
              }}
            >
              <MdOutlineLocalOffer size={20} />
            </div>
          </div>
          {editOffre ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                editOffre(offre);
              }}
              className="btn btn-edit p-0 px-2 border"
              type="button"
            >
              <MdEdit size={15} />
            </button>
          ) : null}
        </div>
        <div className="col-sm col-12 ps-sm-0 mt-2 h-100">
          <div>
            <h2
              style={{ lineHeight: "26px", fontSize: "16px" }}
              className="mb-1"
            >
              {offre.titre}
              <TypeBadge text={offre.offre_type} bgCOlor="#51C6B1" />
            </h2>
            <p className="fs-6 mt-2">
              <Wysiwyg contenu={truncateString(offre.description, 200)} />
            </p>
          </div>
          <p
            style={{ color: "#717173", fontSize: "13px", lineHeight: "24px" }}
            className="fs-6 m-0 mt-3"
          >
            <FaUserAlt />
            <span className="ms-1 fw-bold">
              Total candidats :
              <span className="ms-1">{offre?.candidats?.length}</span>
            </span>
            <div className="mt-2">
              {!offre?.date_expiration ? (
                <span className="text-warning">
                  {" "}
                  Pas de date d'expiration specifiée
                </span>
              ) : new Date(offre?.date_expiration) < new Date() ? (
                <span className="fw-bold text-danger">Offre expirée 🚫</span>
              ) : (
                <p className="text-success">
                  Disponible jusqu'au{" "}
                  {new Date(offre?.date_expiration).toLocaleString("fr-Fr", {
                    dateStyle: "short",
                  })}
                </p>
              )}
            </div>
          </p>
          {user?.type !== "entreprise" &&
            (new Date(offre?.date_expiration) > new Date() ? (
              <LandingBtn
                //   onClick={(event) =>
                //     applyToJob({
                //       e: event,
                //       user_id: user?.id,
                //       offre_id: offreId ? offreId : offre_id,
                //       entreprise_id: detailsOffre?.data?.entreprise.id,
                //     })
                //   }
                onClick={() => setShow(true)}
                className="bg-color-green rounded py-2 px-3 fs-5 w-100"
              >
                Postuler
              </LandingBtn>
            ) : (
              <LandingBtn
                type="button"
                disabled
                className="bg-color-green rounded py-lg-2 px-lg-3 px-2 fs-5 w-100 opacity-25 pe-none"
              >
                Postulez
              </LandingBtn>
            ))}
        </div>
      </div>
    </div>
  );
};

export default EntrepriseOffreCard;
