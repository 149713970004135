import { configureStore } from "@reduxjs/toolkit";

import candidatures from "./reducers/candidatureReducer";
import certifications from "./reducers/certificationReducer";
import etudiants from './reducers/etudiantReducer';
import offres from './reducers/offreReducer';
import offresCandidatures from './reducers/offresCandidature';
import projects from './reducers/projetReducer';
import { apiSlice } from "./slices/apiSlice/apiSlice";
import authReducer from "./slices/authSlice/authSlice";

export const store = configureStore({

  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
      projects,
      certifications,
      offresCandidatures,
      offres,
      etudiants,
      candidatures
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== "production",
});
