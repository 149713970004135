import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CustomModal from "../../../components/CustomModal";
import Button from "../../../components/shared/Button";
import InputForm from "../../../components/shared/InputForm";
import TextArea from "../../../components/shared/TextArea";
import { LANDINGPAGE_OFFRES_PATH } from "../../../constants/routes_path";
import { useContactUsMutation } from "../../../redux/slices/contactUs/contactUsSlice";
import { toastAlertSuccess } from "../../../utils/toastAlerts";
import entreprise from "../assets/img/entreprise.png";
import etablissement from "../assets/img/etablissement.png";
import marieme from "../assets/img/marieme.png";
import postuler from "../assets/img/postuler.png";
import frame1 from "../assets/img/Frame 14.png"
import frame2 from "../assets/img/Frame 15.png"
import frame3 from "../assets/img/Frame 16.png"
import frame4 from "../assets/img/Frame 17.png"
import frame5 from "../assets/img/Frame 18.png"
import frame6 from "../assets/img/Frame 19.png"
import frame7 from "../assets/img/Frame 20.png"
import frame8 from "../assets/img/Frame 21.png"
import frame9 from "../assets/img/Frame 22.png"
import frame10 from "../assets/img/Frame 23.png"
import LandingPageContainer from "../components/LandingPageContainer";
import Section from "../components/Section";
import SectionH2 from "../components/SectionH2";
import SectionParagrapheTitle from "../components/SectionParagrapheTitle";
import Slider from 'react-slick';
import   "../assets/css/sliderlogo.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const Partners = () => {
  // Modal state

  // Navigate
  const navigate = useNavigate();

  const sliderSettings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };
 

  

  return (
    <Section className="partners_container">
      <LandingPageContainer>
        <SectionH2>Ils nous font confiance</SectionH2>
        <SectionParagrapheTitle>
        Rejoignez notre réseau de partenaires et façonnez l'avenir professionnel des étudiants en offrant des opportunités de stage enrichissantes. Ensemble, nous contribuons au développement des talents de demain.
        </SectionParagrapheTitle>

      
        <div className="container mt-4">
        <Slider {...sliderSettings}>
        <div className='slide'><a href="#"><img src={frame1} alt="Logo 1" /></a></div>
        <div className='slide'><a href="#"><img src={frame2} alt="Logo 2" /></a></div>
        <div className='slide'><a href="#"><img src={frame3} alt="Logo 3" /></a></div>
        <div className='slide'><a href="#"><img src={frame4} alt="Logo 4" /></a></div>
        <div className='slide'><a href="#"><img src={frame5} alt="Logo 5" /></a></div>
        <div className='slide'><a href="#"><img src={frame6} alt="Logo 6" /></a></div>
        <div className='slide'><a href="#"><img src={frame7} alt="Logo 7" /></a></div>
        <div className='slide'><a href="#"><img src={frame8} alt="Logo 7" /></a></div>
        <div className='slide'><a href="#"><img src={frame9} alt="Logo 7" /></a></div>
        <div className='slide'><a href="#"><img src={frame10} alt="Logo 7" /></a></div>

     
</Slider>   
 </div>
      </LandingPageContainer>
      
    </Section>
  );
};

export default Partners;
