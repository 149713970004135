import { useEffect, useRef, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { ImLinkedin } from "react-icons/im";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  ENTREPRISE_HOME_PAGE_PATH,
  ETABLISSEMENT_HOME_PAGE_PATH,
  ETUDIANT_HOME_PAGE_PATH,
  REGISTER_ETABLISSEMENT_PATH,
  REGISTER_ETUDIANT_PATH,
} from "../../constants/routes_path";
// import logo from "../../image/logo.png";
import axios from "axios";
import ButtonLoading from "../../components/ButtonLoading";
import { useLoginMutation } from "../../redux/slices/authSlice/authApiSlice";
import { setCredentials } from "../../redux/slices/authSlice/authSlice";
import { toastAlertError } from "../../utils/toastAlerts";
import "./login.css";
// import TitleH2 from "../../components/shared/TitleH2";

const Login = () => {
  // Initial states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginUrl, setLoginUrl] = useState(null);

  const location = useLocation();
  // Refs
  const userRef = useRef();
  const errRef = useRef();
  const initialized = useRef(false);

  const navigate = useNavigate();

  const [login] = useLoginMutation();
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     userRef?.current?.focus();
  //     //   Call google auth endpoint to get auth url
  //     if (!initialized.current) {
  //       initialized.current = true;
  //       fetch(process.env.REACT_APP_API_URL + "auth", {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       })
  //         .then((response) => {
  //           if (response.ok) {
  //             return response.json();
  //           }
  //           throw new Error("Something went wrong!");
  //         })
  //         .then((data) => {
  //           setLoginUrl(data.url);
  //         })
  //         .catch((error) => console.error(error));
  //     }
  //   }, []);

  useEffect(() => {
    const fetchToken = async (code) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}auth/linkedin/callback?code=${code}`
        );
        console.log("Response from LinkedIn auth callback", response);

        // localStorage.setItem("token", response.data.token);
        // Redirigez vers la page principale de votre application ou où vous en avez besoin
        // window.location.href = "/home";
      } catch (error) {
        console.error("Error handling LinkedIn callback", error);
      }
    };

    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      fetchToken(code);
    }
  }, [location]);

  const handleLogin = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}auth/linkedin`
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error redirecting to LinkedIn", error);
    }
  };

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const redirectFunction = (data) => {
    return (
      data?.type === "etudiant" && navigate(ETUDIANT_HOME_PAGE_PATH),
      data?.type === "etablissement" && navigate(ETABLISSEMENT_HOME_PAGE_PATH),
      data?.type === "entreprise" && navigate(ENTREPRISE_HOME_PAGE_PATH)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const userData = await login({ email, password }).unwrap();
      localStorage.setItem("access_token", userData?.token);

      localStorage.setItem(
        "user",
        JSON.stringify({
          ...userData?.user,
          etablissement: userData?.etablissement,
        })
      );
      userData?.user?.type === "etablissement" &&
        localStorage.setItem(
          "etablissementInfos",
          JSON.stringify(userData?.etablissement[0])
        );
      dispatch(setCredentials(userData));
      setEmail("");
      setPassword("");
      // !*************** userData **********************
      if (location?.state?.from) {
        if (userData?.user?.type === "etudiant") {
          return navigate(location?.state?.from);
        }
        // return toastAlertError("Oups, on dirait que que vous n'êtes pas autorise a postuler")
        return redirectFunction(userData?.user);
      }
      redirectFunction(userData?.user);
    } catch (error) {
      if (!error?.status) {
        setErrMsg("Auccune reponse du serveur");
      } else if (error?.status === 401) {
        setErrMsg(error?.data?.message);
        toastAlertError(error?.data?.message);
      } else {
        setErrMsg("Echec connexion ");
        toastAlertError("Echec connexion");
      }
      errRef?.current?.focus();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-form p-2 mt-5">
      <form onSubmit={handleSubmit}>
        <div className="text-center mt-5 mb-3">
          {/* <img src={logo} alt="" className="taille-img mb-3" /> */}
          <Link to={"/"}>
            <h1
              style={{
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
                lineHeight: "30px",
              }}
            >
              Bienvenue sur 🤝 <br />
              <span className="logo-pane">
                <span className="logo-green">Bakeli </span>
                <span className="logo-orange">Carriere</span>
              </span>
            </h1>
          </Link>
          <p style={{ fontFamily: "ProximaNovaBold" }}>Connectez-vous</p>
        </div>
        <input
          className="input-form"
          placeholder="Email"
          type="text"
          autoFocus
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <input
          className="input-form"
          placeholder="Mot de passe"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link to={"/forgot-password"}>
          <p className="text-end fw-bold" style={{ color: "#009688" }}>
            Mot de passe oublié ?
          </p>
        </Link>
        <div className="mt-3">
          <ButtonLoading
            loading={loading}
            text="Connecter"
            disabled={email?.length === 0 || password?.length === 0 || loading}
            className="btn-apply p-2"
          />
        </div>
        <p className="mt-3 text-center">
          Inscrivez vous en tant qu'{" "}
          <span
            onClick={() =>
              navigate(REGISTER_ETUDIANT_PATH, {
                state: { from: location?.state?.from },
              })
            }
            className="link"
          >
            étudiant
          </span>{" "}
        </p>
        {/* <Link
          style={{ fontWeight: "500", textDecoration: "underline" }}
          className="text-end  fs-6 "
          to={"/"}
        >
          Retour à l'accueil
        </Link> */}
        <div className="d-flex align-items-center justify-content-center px-1 mt-4">
          <button
            onClick={handleLogin}
            className="btn text-white border-0 d-flex align-items-center"
            style={{
              backgroundColor: "#0076b2",
              fontFamily: "ProximaNovaBold",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 128"
              width={30}
              height={30}
              className="me-2"
            >
              <path
                fill="#0076b2"
                d="M116 3H12a8.91 8.91 0 00-9 8.8v104.42a8.91 8.91 0 009 8.78h104a8.93 8.93 0 009-8.81V11.77A8.93 8.93 0 00116 3z"
              />
              <path
                fill="#fff"
                d="M21.06 48.73h18.11V107H21.06zm9.06-29a10.5 10.5 0 11-10.5 10.49 10.5 10.5 0 0110.5-10.49M50.53 48.73h17.36v8h.24c2.42-4.58 8.32-9.41 17.13-9.41C103.6 47.28 107 59.35 107 75v32H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87 7.36-10.87 15V107H50.53z"
              />
            </svg>
            Connexion avec LinkedIn
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
