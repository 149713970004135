import React from 'react'
import { SpinnerLoaderSearch } from '../../utils/spinnersLoader'
import ResourceDataMap from './ResourceDataMap'

const ResourceDataMapperWithEmptyMessage = ({
    isLoading,
    resourceItem: ResourceItem,
    resourceName,
    resourceData = [],
    emptyMessage
}) => {
    return (
        <>
            {
                isLoading ? <SpinnerLoaderSearch /> :
                    resourceData.length ? <ResourceDataMap
                        resourceItem={ResourceItem}
                        resourceName={resourceName}
                        resourceData={resourceData}
                    /> : <p>{emptyMessage}</p>
            }
        </>
    )
}

export default ResourceDataMapperWithEmptyMessage