import React from 'react'
import { monthYearFormatter } from '../../../utils/timeFormatters';
import { MdEdit } from 'react-icons/md';

const ExperienceCardItem = ({ experience, editExperience }) => {
    const { poste_occupe, name, date_debut, date_fin } = experience
    return (
        <div className="row mb-3 white-bg px-2 py-3 mx-auto">
            <div className="col-xl-9 col-lg-8 col-md-8 col-sm-7">
                <span
                    className={`badge rounded-pill text-center ${poste_occupe?.toLowerCase()}`}
                >
                    {poste_occupe}
                </span>
                <h6 className="m-0">{name}</h6>
                <p>{experience?.nom_entreprise}</p>
                <p>{`${monthYearFormatter(date_debut)} - ${date_fin !== null
                    ? monthYearFormatter(date_fin)
                    : "aujourd'hui"
                    }`}</p>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 d-flex align-items-start justify-content-end mt-2 mt-sm-0 mt-xl-0 mt-lg-0 mt-md-0">
                {
                    editExperience && <button
                    className="btn btn-edit"
                    onClick={() => editExperience(experience)}
                >
                    <MdEdit /> Modifier
                </button>
                }

            </div>
        </div>
    )
}

export default ExperienceCardItem