import React from 'react'
import { Modal } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery';

const Gallery = ({ show, close, images, name, description, children, title }) => {
    return (
        <Modal
            id="contained-modal"
            show={show}
            onHide={() => {
                close()
                // reset()
            }}
            // size={size}
            aria-labelledby=""
            centered
        // className=''
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Gallerie {title}
                </Modal.Title>
            </Modal.Header>
            <div className='container'>
                <div className='row' id="contained-modal-parent">
                    <div className='col-md-7 order-md-1 order-2 col-12'>
                        <Modal.Body>
                            <ImageGallery items={images} />
                        </Modal.Body>
                    </div>
                    <div className='col-md-5 order-md-2 order-1 col-12 mt-5 border-left pb-5'>
                        <h1 className='fs-5'>{name}</h1>
                        <p className='border-bottom pt-2 fs-6'>
                            {description}
                        </p>
                        <div className='d-flex gap-2'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Gallery