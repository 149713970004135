import React from 'react';
// import ResourceDataMap from './ResourceDataMap'
// import { ErrorMessage } from '@hookform/error-message';

const Select = ({ label, htmlFor, register, msgError, required, selectOptions, emptyMessage, ...props }) => {
    return (
      <>
        <label htmlFor={htmlFor} className={`${required && "required"}`}>
          {label}
        </label>
        <select
          required={required}
          id={htmlFor}
          {...register}
          className="form-select mb-2"
          {...props}
        >
          {selectOptions.length ? (
            <option value="">Selectionner</option>
          ) : (
            <option>{emptyMessage}</option>
          )}
          {selectOptions.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
          {/* <ResourceDataMap
                    resourceItem={
                        ({ option: { value, label } }) => <option value={value}>{label}</option>
                    }
                    resourceName="option"
                    resourceData={selectOptions}
                /> */}
          {/* <ErrorMessage errors={errors} name="singleErrorInput" /> */}
          {msgError && <span className="fs-6 text-danger">{msgError}</span>}
        </select>
      </>
    );
}

export default Select