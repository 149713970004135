import React from 'react'

const SocialMediaIcon = ({ children, className }) => {
    return (
        <div className={`social_media_icon ${className}`}>
            {children}
        </div>
    )
}

export default SocialMediaIcon