import React, { cloneElement } from "react";
import styled from "styled-components";
import GridListe from "../../../components/shared/GridListe";
import PaginatedItems from "../../../components/shared/PaginationItems";
import ResourceDataMap from "../../../components/shared/ResourceDataMap";
import { rootStyles } from "../../../styles/globalCss";
import EvenementCardItem from "../components/EvenementCardItem";

const EventCard = styled.div`
  background-color: ${rootStyles.color.primary};
  padding: ${rootStyles.padding.btnPadding};
  border-radius: ${rootStyles.radius.card};
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const CardText = styled.p`
  font-size: ${rootStyles.fonts.fontSize};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
`;
const CardItem = styled.div`
  background-color: ${rootStyles.color.primary};
  box-shadow: ${rootStyles.shadow.card};
  padding: 10px;
  border-radius: ${rootStyles.radius.card};
  max-width: 100px;
  width: 100px;
  text-align: center;
`;
const SpanText = styled.span`
  font-size: ${rootStyles.fonts.fontSize};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SpanNumber = styled.span`
  font-size: 25px;
  color: ${rootStyles.color.secondary};
  font-weight: ${rootStyles.fonts.h2FontWeight};
  display: block;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  overflow-y: auto;
  padding: 20px 5px;
`;

// !liste des evenements
const RenderEvents = ({ currentItems, isLoadingEvenements }) => {
  const navigateToDetails = (evenement) => {
    return console.log(evenement);
  };

  const ClonedEvenementCardItem = ({ evenement }) =>
    cloneElement(<EvenementCardItem evenement={evenement} />, {
      navigateToDetails,
    });

  return (
    <GridListe
      //   isLoading={isLoadingEvenements}
      resourceData={currentItems}
      resourceName="evenement"
      resourceItem={ClonedEvenementCardItem}
      emptyMessage="Pas encore d'événements"
    />
  );
};

const LandingPageEvenements = () => {
  const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <PaginatedItems itemsPerPage={10} ressourceData={data}>
      <RenderEvents
      //   isLoadingEvenements={
      //     isEvenementsAvenirLoading || isEvenementsRecentLoading
      //   }
      />
    </PaginatedItems>
  );
};

export default LandingPageEvenements;
