import {
  ADD_ETUDIANTS_API_ROUTE,
  ETUDIANT_DETAILS_API_ROUTE,
  GET_ALL_ETABLISSEMENTS,
  GET_ETUDIANTS_BY_ETABLISSEMENT_API_ROUTE,
  KPI_ETABLISSEMENT_API_ROUTE,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const etablissementsSlice = apiSlice.injectEndpoints({
  tagTypes: ["Etablissements"],
  endpoints: (builder) => ({
    getAllEtablissements: builder.query({
      query: () => `${GET_ALL_ETABLISSEMENTS}`,
      providesTags: ["Etablissements"],
      transformResponse: (response) => response.data.reverse(),
    }),
    getEtudiantsByEtablissement: builder.query({
      query: (id_etablissement) =>
        `${GET_ETUDIANTS_BY_ETABLISSEMENT_API_ROUTE}/${id_etablissement}`,
      providesTags: ["Etablissements"],
      transformResponse: (response) => response.data.reverse(),
    }),
    addEtudiants: builder.mutation({
      query: (payload) => ({
        url: ADD_ETUDIANTS_API_ROUTE,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Etablissements"],
    }),
    getEtablissementKpis: builder.query({
      query: (etablissement_id) =>
        `${KPI_ETABLISSEMENT_API_ROUTE}${etablissement_id}`,
      providesTags: ["ETABLISSEMENTKPIs"],
    }),
    getEtudiantInfo: builder.query({
      query: (user_id) => `${ETUDIANT_DETAILS_API_ROUTE}${user_id}`,
      providesTags: ["ETUDIANTS_DETAILS"],
    }),
  }),
});

export const {
  useAddEtudiantsMutation,
  useGetEtudiantsByEtablissementQuery,
  useGetEtablissementKpisQuery,
  useGetEtudiantInfoQuery,
  useGetAllEtablissementsQuery,
} = etablissementsSlice;
