import React from 'react'
import { useMemo } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import CandidatureCardItem from '../../components/CandidatureCardItem'
import ResourceDataMap from '../../components/shared/ResourceDataMap'
import { OFFRES_PATH } from '../../constants/routes_path'
import { useEtablissementId } from '../../hooks/useEtablissementId'
import { useGetEtablissementKpisQuery } from '../../redux/slices/etablissements/etablissementsSlice'
import { etablissementRoutes } from '../../routes/routes'

const EtablissementDash = () => {
    const { etablissementId } = useEtablissementId()
    const { data = [] } = useGetEtablissementKpisQuery(etablissementId)
    const { nbrEntreprise, nbrEtudiant, nbrOffre } = data

    const dashboardKpis = useMemo(() => {
        return [
            { text: "Nombre d'étudiants", number: nbrEtudiant, className: "col-sm-4 col-12 p-0 py-2 py-sm-0 hover-1", to: '' },
            { text: "Nombre d'offres", number: nbrOffre, className: "col-sm-4 col-12 p-0 py-2 py-sm-0 px-sm-3 hover-1", to: OFFRES_PATH },
            { text: "Nombre d'entreprises", number: nbrEntreprise, className: "col-sm-4 col-12 p-0 py-2 py-sm-0 hover-1", to: 'entreprises' },
        ]
    }, [nbrEntreprise, nbrEtudiant, nbrOffre])

    return (
        <>
            <div className='row p-0'>
                <ResourceDataMap
                    resourceItem={
                        ({ kpi }) => {
                            return <>
                                <Link to={kpi.to} className={kpi.className}>
                                    <CandidatureCardItem text={kpi.text} number={kpi.number} />
                                </Link>
                            </>
                        }}
                    resourceName="kpi"
                    resourceData={dashboardKpis}
                />
            </div>
            <div className='mt-3'>
                <Routes>
                    {
                        etablissementRoutes.map((route, index) => {
                            if (route.children) {
                                return <Route key={index} path={route.path} element={route.element} >
                                    {
                                        route.children.map(chilRoute => (
                                            <Route key={chilRoute.path} path={chilRoute.path} element={chilRoute.element} />
                                        ))
                                    }
                                </Route>
                            }
                            return <Route key={index} path={route.path} element={route.element} />
                        })
                    }
                </Routes>
            </div>
        </>
    )
}

export default EtablissementDash