import React from 'react'
import { HiPlus } from 'react-icons/hi';
import { SpinnerLoaderSearch } from '../utils/spinnersLoader';
import Button from './shared/Button';

const ResourceDataProfil = ({
    isLoading,
    resourceData = [],
    children,
    title,
    onAdd,
    accordion,
    id
}) => {
    const [mapPane, emptyPane] = children
    return (
        <div className='mb-2'>
            {
                accordion ? isLoading ? <div className="d-flex align-items-center justify-content-center white-bg loader_heigth">
                    <SpinnerLoaderSearch />
                </div> : <div className='my-4'>

                    {
                        resourceData?.length !== 0 ? <div className="accordion" id="accordionExample">

                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id={title + 'Heading'}>
                                    <button
                                        className="accordion-button p-2 px-3 justify-content-between"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#${id ? `One` + id : title}`}
                                        aria-expanded="true"
                                        aria-controls={`${id ? 'One' + id : title}`}>
                                        <h4
                                            className=" fs-5 m-0 mb-1"
                                        >{title}</h4>
                                    </button>
                                </h2>
                                <div id={`${id ? 'One' + id : title}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={title + 'heading'}
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <div className='text-end mb-2'>
                                            {
                                                (resourceData?.length && onAdd) ? <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        onAdd()
                                                    }}
                                                    className="btn-edit m-0 mb-1 border align-self-end" >
                                                    <HiPlus size={30} />
                                                </Button> : null
                                            }
                                        </div>
                                        {mapPane}
                                    </div>
                                </div>
                            </div>
                        </div> : <>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h4 className="fw-bold fs-5 m-0 mb-1">{title}</h4>
                                {
                                    resourceData?.length && onAdd ? <Button className="btn-edit" onClick={onAdd}>
                                        <HiPlus size={30} />
                                    </Button> : null
                                }
                            </div>
                            {emptyPane}
                        </>
                    }
                </div> :
                    <>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h4 className="fw-bold fs-5 m-0 mb-1">{title}</h4>
                            {
                                onAdd ? <Button className="btn-edit" onClick={onAdd}>
                                    <HiPlus size={30} />
                                </Button> : null
                            }
                        </div>
                        {isLoading ? (
                            <div className="d-flex align-items-center justify-content-center white-bg loader_heigth">
                                <SpinnerLoaderSearch />
                            </div>
                        ) : resourceData?.length !== 0 ? (
                            <>
                                {mapPane}
                            </>
                        ) : (
                            <>
                                {emptyPane}
                            </>
                        )}
                    </>
            }

        </div>
    )
}

export default ResourceDataProfil