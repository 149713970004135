import { cloneElement, useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import {
  HiOutlineAcademicCap,
  HiOutlineMail,
  HiShieldCheck,
} from "react-icons/hi";
// import { MdEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import AddMotivation from "../../components/AddMotivation";
// import Button from "../../components/shared/Button";
import CardOnEmptyProfilData from "../../components/CardOnEmptyProfilData";
import EditMotivation from "../../components/EditMotivation";
import FormationListItem from "../../components/FormationListItem";
import MotivationItem from "../../components/MotivationItem";
import ResourceDataProfil from "../../components/ResourceDataProfil";
import ResourceDataMap from "../../components/shared/ResourceDataMap";
import { useUserId } from "../../hooks/useUserId";
import { setSelectedProject } from "../../redux/reducers/projetReducer";
import {
  useGetEtudiantCvQuery,
  useGetEtudiantExperiencesQuery,
  useGetProfileCompletionRateQuery,
} from "../../redux/slices/etudiants/etudiantsSlice";
import { useGetEtudiantFormationsQuery } from "../../redux/slices/etudiants/formationSlice";
import { useGetEtudiantMotivationQuery } from "../../redux/slices/etudiants/motivationSlice";
import { useGetEtudiantProjetsQuery } from "../../redux/slices/etudiants/projetSlice";
// import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
// import { monthYearFormatter } from "../../utils/timeFormatters";
import { FaBuilding } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { setSelectedCertification } from "../../redux/reducers/certificationReducer";
import { useGetEtudiantCertificationsQuery } from "../../redux/slices/etudiants/certificationSlice";
import AddExperienceModal from "./AddExperienceModal";
import AddFormationModal from "./AddFormationModal";
import CvInfoItem from "./CvInfoItem";
import CvModal from "./CvModal";
import EditCvModal from "./EditCvModal";
import EditExperienceModal from "./EditExperienceModal";
import EditFormationModal from "./EditFormationModal";
import EditProfileModal from "./EditProfileModal";
import AddCertification from "./certification/AddCertification";
import CertificationGallery from "./certification/CertificationGallery";
import EditCertificaton from "./certification/EditCertificaton";
import CertificationListItem from "./certification/components/CertificationListItem";
import ExperienceCardItem from "./experience/ExperienceCardItem";
import AddProjetModal from "./projet/AddProjetModal";
import EditAddProjetModal from "./projet/EditAddProjetModal";
import ProjectImagesDetails from "./projet/ProjectImagesDetails";
import ProjetListItem from "./projet/components/ProjetListItem";

// !--------------------------- User infos section ----------------------------------
const UserInfo = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  // Initial states
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  // User infos
  const [userInfosData, setUserInfosData] = useState({
    name: "",
    phone: "",
    location: "",
    nb_an_experiences: 0,
    niveau_etude: "",
    fonction: "",
    genre: "",
    etablissement_id: "",
    //   id: null,
  });

  // Set globlal states
  const setUserInfoStates = (data) => {
    setUserInfosData({
      name: data?.name,
      phone: data?.phone,
      location: data?.location,
      nb_an_experiences: data?.etudiant?.nb_an_experiences,
      niveau_etude: data?.etudiant?.niveau_etude,
      fonction: data?.etudiant?.fonction,
      id: data?.id,
      etablissement_id: data?.etudiant?.etablissement_id,
      genre: data?.genre,
    });
  };

  const resetUserInfos = () => {
    setUserInfosData({
      name: "",
      phone: "",
      location: "",
      nb_an_experiences: 0,
      niveau_etude: "",
      fonction: "",
      id: null,
    });
  };

  const editUserInfos = (userData) => {
    setUserInfoStates(userData);
    setShowEditUserModal(true);
  };

  const { data: completionRate } = useGetProfileCompletionRateQuery(user?.id);

  function isLinkedInAvatar(str) {
    try {
      const newUrl = new URL(str);
      return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    } catch (err) {
      return false;
    }
  }

  return (
    <div className="white-bg px-2 py-3 mx-auto">
      <EditProfileModal
        show={showEditUserModal}
        close={() => {
          resetUserInfos();
          setShowEditUserModal(false);
        }}
        userInfos={userInfosData}
      />
      <div className="d-flex align-items-center justify-content-center">
        <img
          src={
            isLinkedInAvatar(user?.avatar)
              ? user?.avatar
              : user?.avatar !== null
              ? `${process.env.REACT_APP_BASE_TWO}${user?.avatar}`
              : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBmaWxsPSIjRjZGNkY5IiBkPSJNMCAwaDEyOHYxMjhIMHoiLz48cGF0aCBmaWxsPSIjQkRCREMwIiBkPSJNMTYgMTE2YzAtMTYuNTY5IDEzLjQzMS0zMCAzMC0zMGgzNmMxNi41NjkgMCAzMCAxMy40MzEgMzAgMzB2MjQuODM3YzAgMTYuNTY5LTEzLjQzMSAzMC0zMCAzMEg0NmMtMTYuNTY5IDAtMzAtMTMuNDMxLTMwLTMwVjExNlptNzYtNjRjMCAxNS40NjQtMTIuNTM2IDI4LTI4IDI4UzM2IDY3LjQ2NCAzNiA1MnMxMi41MzYtMjggMjgtMjggMjggMTIuNTM2IDI4IDI4WiIvPjwvc3ZnPgo="
          }
          alt="Candidat"
          //   onError={
          //     "https://img.myloview.com/stickers/default-avatar-profile-icon-vector-social-media-user-image-700-205124837.jpg"
          //   }
          className="rounded-circle img-fluid"
          style={{ width: "80px", height: "80px" }}
        />
      </div>
      <h2 className="text-center mt-2 profil-name">{user?.name}</h2>
      <p className=" text-center mx-1 mb-3 fw-bold">
        {user?.etudiant?.fonction ?? "Étudiant"}
      </p>
      <div className="px-2 profil-body">
        <p className="mx-1 mb-1">
          <HiOutlineMail /> {user?.email}
        </p>

        <p className="mx-1 mb-1">
          <BsFillTelephoneFill /> {user?.phone ?? "Numéro non définit"}
        </p>
        <p className="mx-1 mb-1">
          <HiShieldCheck />{" "}
          {user?.etudiant?.nb_an_experiences
            ? `${user?.etudiant?.nb_an_experiences}  années
          d'expérience`
            : "Experiences non définit"}
        </p>
        <p className="mx-1 mb-1">
          <FaBuilding />{" "}
          {user?.etudiant?.nomEtablissement ?? "Pas d'établissement"}
        </p>
        <p className="mx-1 mb-1">
          <HiOutlineAcademicCap />{" "}
          {user?.etudiant?.niveau_etude ?? "Niveau d'étude non définit"}
        </p>
        <p className="mx-1 mb-1">
          <FiMapPin /> {user?.location ?? "Adresse non définit"}
        </p>
        {/* <p>
          <IoMdSchool size={20} /> {user?.etablissement ? user?.etablissement : "Pas ecore d'école"}
        </p> */}
      </div>
      <button
        className="btn btn-edit d-block w-100 d-flex align-items-center justify-content-center"
        onClick={() => editUserInfos(user)}
      >
        <MdEdit className="mx-1" /> Modifier
      </button>

      <div className="d-flex flex-column mt-3">
        <p>
          Profil renseigné à{" "}
          <span className="fw-bold fs-6">
            {completionRate?.data?.profile_completion_rate}%
          </span>
        </p>
        <progress
          id="file"
          value={completionRate?.data?.profile_completion_rate}
          max="100"
        >
          {completionRate?.data?.profile_completion_rate}
        </progress>
      </div>
    </div>
  );
};

//!------------------------Complementary infos-----------------------------

const ComplementaryInfos = () => {
  const [showCvModal, setShowCvModal] = useState(false);
  const [showEditCvModal, setShowEditCvModal] = useState(false);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showFormationModal, setShowFormationModal] = useState(false);
  const [showEditFormationModal, setShowEditFormationModal] = useState(false);
  const { userId } = useUserId();
  const [showEditProjet, setShowEditProjet] = useState(false);
  const [showEditCertificationModal, setShowEditCertificationModal] =
    useState(false);
  const [projectToEdit, setProjectToEdit] = useState({});
  const [certificationToEdit, setCertificationToEdit] = useState({});
  const [showAddProjetModal, setShowAddProjetModal] = useState(false);
  const [showAddCertificationModal, setShowAddCertificationModal] =
    useState(false);
  const [showImageProject, setShowImageProject] = useState(false);
  const [showCertificationProject, setShowCertificationProject] =
    useState(false);

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = localStorage.getItem("access_token");

  // Cv File state
  const [cvFile, setCvFile] = useState(null);

  const handleShowCvModal = () => {
    return setShowCvModal(true);
  };

  const handleShowExperienceModal = () => {
    return setShowExperienceModal(true);
  };

  const handleShowFormationModal = () => {
    return setShowFormationModal(true);
  };

  const handleShowEditExperienceModal = (data) => {
    setShowEditExperienceModal(true);
  };

  const handleShowEditFormationModal = (data) => {
    setShowEditFormationModal(true);
  };

  // use get etudiant cv query
  const {
    data: userCv = {},
    isLoading: loadingCv,
    refetch,
  } = useGetEtudiantCvQuery(user?.id);

  // use get etudiant experiences query
  const {
    data: userExperiences,
    isLoading: loadingExperiences,
    refetch: handleRefetchExperience,
  } = useGetEtudiantExperiencesQuery(user?.id);

  //! use get etudiant cv query
  const { data: userFormations, isLoading: loadingFormations } =
    useGetEtudiantFormationsQuery(user?.id);

  // ! get etudiant projets
  const {
    data: projets,
    isLoading: isProjetLoading,
    refetch: refetchProject,
  } = useGetEtudiantProjetsQuery(userId);

  // ! get etudiant certifications
  const {
    data: certifications = { data: [] },
    refetch: refetchCertifications,
    isLoading: isCertificationLoading,
  } = useGetEtudiantCertificationsQuery(userId);

  // Get Profile completion rate
  const { refetch: refetchCompletionRate } = useGetProfileCompletionRateQuery(
    user?.id
  );

  function handleRefetchOne() {
    // force re-fetches the data
    refetch();
    refetchCompletionRate();
  }

  function handleRefetchExperiences() {
    // force re-fetches the data
    handleRefetchExperience();
    refetchCompletionRate();
  }

  const [experience, setExperience] = useState({
    name: "",
    nomEntreprise: "",
    posteOccupe: "",
    dateDebut: "",
    dateFin: "",
    description: "",
    id: null,
  });

  const [formation, setFormation] = useState({
    name: "",
    etablissement: "",
    dateDebut: "",
    dateFin: "",
    id: null,
  });

  const setStates = (data) => {
    setExperience({
      name: data?.name,
      nomEntreprise: data?.nom_entreprise,
      posteOccupe: data?.poste_occupe,
      dateDebut: data?.date_debut,
      dateFin: data?.date_fin,
      description: data?.description,
      id: data?.id,
    });
  };

  const setFormationStates = (data) => {
    setFormation({
      name: data?.name,
      etablissement: data?.etablissement,
      dateDebut: data?.date_debut,
      dateFin: data?.date_fin,
      id: data?.id,
    });
  };

  // !edit formation
  const editFormation = (formation) => {
    setFormationStates(formation);
    handleShowEditFormationModal(formation);
  };

  // !edit projet
  const editProjet = (projet) => {
    setProjectToEdit(projet);
    setShowEditProjet(true);
  };

  // ! edit certification
  const editCertification = (certification) => {
    setCertificationToEdit(certification);
    setShowEditCertificationModal(true);
  };

  // !open edit cv
  const handleShowEditCvModal = () => {
    setShowEditCvModal(true);
  };
  const handleShowAddProjet = () => {
    return setShowAddProjetModal(true);
  };

  // !open & close certification
  const openAddCertificationModal = () => {
    setShowAddCertificationModal(true);
  };
  // const closeAddCertificationModal = () => {
  //   setShowAddCertificationModal(true)
  // }

  // !open project images
  const openImages = (selectedProject) => {
    dispatch(setSelectedProject(selectedProject));
    setShowImageProject(true);
  };

  // !open project images
  const openCertificationImages = (selectedCertification) => {
    dispatch(setSelectedCertification(selectedCertification));
    setShowCertificationProject(true);
  };

  const editCv = () => {
    handleShowEditCvModal();
    setIsEditing(true);
    userCv?.length !== 0 &&
      setCvFile(`${process.env.REACT_APP_BASE_URL}resume/${userCv?.fichier}`);
  };

  const editExperience = (experience) => {
    setStates(experience);
    handleShowEditExperienceModal(experience);
  };

  // ! on clone les composants pour pouvoir passer des props supplementaires tellesque "editFormation", "editCv", etc
  const ClonedFormationListItem = ({ formation }) =>
    cloneElement(<FormationListItem formation={formation} />, {
      editFormation,
    });
  const ClonedCvInfoProjet = ({ userCv }) =>
    cloneElement(<CvInfoItem userCv={userCv} />, { onClick: editCv });
  const ClonedProjetListItem = ({ projet }) =>
    cloneElement(<ProjetListItem projet={projet} />, {
      editProjet,
      openImages,
    });
  const ClonedCertificationListItem = ({ certification }) =>
    cloneElement(<CertificationListItem certification={certification} />, {
      editCertification,
      openCertificationImages,
    });
  const ClonedExperienceCardItem = ({ experience }) =>
    cloneElement(<ExperienceCardItem experience={experience} />, {
      editExperience,
    });

  return (
    <>
      <CvModal
        cvFile={cvFile}
        setCvFile={setCvFile}
        cvId={userCv?.id}
        show={showCvModal}
        close={() => {
          setShowCvModal(false);
          setCvFile(null);
          setIsEditing(false);
        }}
        isEditing={isEditing}
        accessToken={accessToken}
        user={user}
        refetch={() => handleRefetchOne()}
        setIsEditing={() => setIsEditing(false)}
      />
      <EditCvModal
        setCvFile={setCvFile}
        cvId={userCv?.id}
        userCv={userCv}
        show={showEditCvModal}
        close={() => {
          setShowEditCvModal(false);
          setCvFile(null);
          setIsEditing(false);
        }}
        isEditing={isEditing}
        accessToken={accessToken}
        user={user}
        refetch={() => handleRefetchOne()}
        setIsEditing={() => setIsEditing(false)}
      />

      {/* formation */}
      <AddFormationModal
        show={showFormationModal}
        close={() => setShowFormationModal(false)}
        userId={user?.id}
        refetch={() => handleRefetchExperiences()}
      />
      <EditFormationModal
        show={showEditFormationModal}
        close={() => setShowEditFormationModal(false)}
        // refetch={() => handleRefetchExperiences()}
        setFormation={setFormation}
        formation={formation}
      />
      {/* experience */}
      <AddExperienceModal
        show={showExperienceModal}
        close={() => setShowExperienceModal(false)}
        userId={user?.id}
        refetch={() => handleRefetchExperiences()}
      />
      <EditExperienceModal
        show={showEditExperienceModal}
        close={() => setShowEditExperienceModal(false)}
        refetch={() => handleRefetchExperiences()}
        setExperience={setExperience}
        experience={experience}
      />

      {/* project */}
      <AddProjetModal
        show={showAddProjetModal}
        close={() => setShowAddProjetModal(false)}
        refetchProject={refetchProject}
      />
      <EditAddProjetModal
        projet={projectToEdit}
        show={showEditProjet}
        refetchProject={refetchProject}
        close={() => setShowEditProjet(false)}
      />

      {/* certifications */}
      <AddCertification
        show={showAddCertificationModal}
        close={() => setShowAddCertificationModal(false)}
        refetchCertifications={refetchCertifications}
      />
      <EditCertificaton
        refetchCertifications={refetchCertifications}
        certification={certificationToEdit}
        show={showEditCertificationModal}
        close={() => setShowEditCertificationModal(false)}
      />

      {/* +++++++++++++++++++++++++++++++++++++++++ CV ++++++++++++++++++++++++++++++++++++++ */}
      <ResourceDataProfil
        title="Mon CV"
        isLoading={loadingCv}
        // resourceData={Object.entries(userCv)}
        resourceData={userCv}
      >
        <ResourceDataMap
          // resourceData={Object.entries(userCv)}
          resourceData={userCv}
          resourceName="userCv"
          resourceItem={ClonedCvInfoProjet}
        />
        <CardOnEmptyProfilData
          titleOnEmpty="Ajouter mon CV"
          textOnEmpty="Ajouter votre CV vous permet de postuler plus rapidement aux offres
          et est nécessaire pour être à l'écoute d'opportunités."
          addBtnText="Ajouter mon CV"
          onClick={handleShowCvModal}
        />
      </ResourceDataProfil>

      {/* +++++++++++++++++++++++++++++++++++++++++ FORMATIONS ++++++++++++++++++++++++++++++++++++++ */}
      <ResourceDataProfil
        accordion
        onAdd={handleShowFormationModal}
        title="Formations"
        isLoading={loadingFormations}
        resourceData={userFormations?.data}
      >
        <ResourceDataMap
          resourceData={userFormations?.data}
          resourceName="formation"
          resourceItem={ClonedFormationListItem}
          // resourceItem={FormationListItem}
        />
        <CardOnEmptyProfilData
          titleOnEmpty="Ajouter les étapes de votre parcours"
          textOnEmpty="Un stage ? Une expérience de travail ? etc."
          addBtnText="Ajouter"
          onClick={handleShowFormationModal}
        />
      </ResourceDataProfil>

      {/* +++++++++++++++++++++++++++++++++++++++++ PROJETS ++++++++++++++++++++++++++++++++++++++ */}
      <ProjectImagesDetails
        show={showImageProject}
        close={() => setShowImageProject(false)}
      />
      <ResourceDataProfil
        accordion
        onAdd={handleShowAddProjet}
        title="Projets"
        isLoading={isProjetLoading}
        resourceData={projets?.data}
      >
        <ResourceDataMap
          resourceData={projets?.data}
          resourceName="projet"
          resourceItem={ClonedProjetListItem}
          // resourceItem={FormationListItem}
        />
        <CardOnEmptyProfilData
          titleOnEmpty="Les projets sont le meilleur moyen de vous démarquer"
          textOnEmpty="Montrer aux entreprises ce que vous savez faire"
          addBtnText="Ajouter"
          onClick={handleShowAddProjet}
        />
      </ResourceDataProfil>

      {/* +++++++++++++++++++++++++++++++++++++++++ CERTIFICATIONS ++++++++++++++++++++++++++++++++++++++ */}
      <CertificationGallery
        show={showCertificationProject}
        close={() => setShowCertificationProject(false)}
      />
      <ResourceDataProfil
        accordion
        onAdd={openAddCertificationModal}
        title="Certifications"
        isLoading={isCertificationLoading}
        resourceData={certifications.data}
      >
        <ResourceDataMap
          resourceData={certifications.data}
          resourceName="certification"
          resourceItem={ClonedCertificationListItem}
          // resourceItem={FormationListItem}
        />
        <CardOnEmptyProfilData
          titleOnEmpty="Démarquez-vous avec vos certificatons"
          textOnEmpty="Montrerz vos certificatons"
          addBtnText="Ajouter"
          onClick={openAddCertificationModal}
        />
      </ResourceDataProfil>

      {/* +++++++++++++++++++++++++++++++++++++++++ EXPERIENCES ++++++++++++++++++++++++++++++++++++++ */}
      <ResourceDataProfil
        accordion
        onAdd={handleShowExperienceModal}
        title="Expériences"
        isLoading={loadingExperiences}
        resourceData={userExperiences?.data}
      >
        <ResourceDataMap
          resourceData={userExperiences?.data}
          resourceName="experience"
          resourceItem={ClonedExperienceCardItem}
        />
        <CardOnEmptyProfilData
          titleOnEmpty="Ajouter les étapes de votre parcours"
          textOnEmpty="Un stage ? Une expérience de travail ? etc."
          addBtnText="Ajouter"
          onClick={handleShowExperienceModal}
        />
      </ResourceDataProfil>

      {/* <div className="d-flex align-items-center justify-content-between m-2">
        <h4 className="fw-bold">Experiences</h4>
        {userExperiences?.count >= 1 && (
          <Button className="btn-add" onClick={handleShowExperienceModal}>
            <HiPlus />
          </Button>
        )}
      </div>

      {loadingExperiences ? (
        <div className="d-flex align-items-center justify-content-center white-bg loader_heigth">
          <SpinnerLoaderSearch />
        </div>
      ) : userExperiences?.count >= 1 ? (
        userExperiences?.data?.map((experience, index) => (
          <div className="row mb-3 white-bg px-2 py-3 mx-auto" key={index}>
            <div className="col-xl-9 col-lg-8 col-md-8 col-sm-7">
              <span
                className={`badge rounded-pill text-center ${experience?.poste_occupe?.toLowerCase()}`}
              >
                {experience?.poste_occupe}
              </span>
              <h6 className="m-0">{experience?.name}</h6>
              <p>{experience?.nom_entreprise}</p>
              <p>{`${monthYearFormatter(experience?.date_debut)} - ${experience?.date_fin !== null
                ? monthYearFormatter(experience?.date_fin)
                : "aujourd'hui"
                }`}</p>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 d-flex align-items-start justify-content-end mt-2 mt-sm-0 mt-xl-0 mt-lg-0 mt-md-0">
              <button
                className="btn btn-edit"
                onClick={() => {
                  setStates(experience);
                  handleShowEditExperienceModal(experience);
                }}
              >
                <MdEdit /> Modifier
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="experiences-sections text-center white-bg px-5 py-3">
          <h6 className="fw-bold">Ajouter les étapes de votre parcours</h6>
          <p className="my-3">Un stage ? Une expérience de travail ? etc.</p>
          <Button className="btn-add" onClick={handleShowExperienceModal}>
            <HiPlus /> Ajouter une experience
          </Button>
        </div>
      )} */}
    </>
  );
};

// !-------------------------------------------------------------------------------------------------------------
const Profile = () => {
  const { userId } = useUserId();
  const [addMotivation, setAddMotivation] = useState(false);
  const [isEditMotivation, setIsEditMotivation] = useState();
  // ! get etudiant motivation
  const { data: motivations, isLoading: isMotivationLoading } =
    useGetEtudiantMotivationQuery(userId);

  const editMotivation = (motivation) => {
    setIsEditMotivation(true);
  };

  const ResourceMotivationItem = ({ motivation }) =>
    cloneElement(<MotivationItem motivation={motivation} />, {
      editMotivation: editMotivation,
    });

  return (
    // <Main>
    <div>
      {/* <Navbar /> */}
      <div className="container-lg mt-3 p-1 h-100">
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-5 col-12 mb-md-3 mb-3 mx-auto ">
            <UserInfo />

            {/* +++++++++++++++++++++++++++++++++++++++++ MOTIVATIONS ++++++++++++++++++++++++++++++++++++++ */}
            <div className="mt-4">
              <ResourceDataProfil
                // accordion
                onAdd={null}
                title="Motivations"
                isLoading={isMotivationLoading}
                resourceData={motivations?.data}
              >
                {!isEditMotivation ? (
                  <ResourceDataMap
                    resourceData={motivations?.data}
                    resourceName="motivation"
                    resourceItem={ResourceMotivationItem}
                  />
                ) : (
                  <EditMotivation
                    motivation={motivations?.data[0]}
                    cancel={() => {
                      setIsEditMotivation(false);
                      setAddMotivation(false);
                    }}
                  />
                )}

                {addMotivation ? (
                  <AddMotivation cancel={() => setAddMotivation(false)} />
                ) : (
                  <CardOnEmptyProfilData
                    titleOnEmpty="Ajouter votre motivation"
                    textOnEmpty="Quelle sont vos motivations ? Les entreprises veulent vous connaitre"
                    addBtnText="Ajouter une motivation"
                    onClick={() => setAddMotivation(true)}
                  />
                )}
              </ResourceDataProfil>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7 col-md-7 col-12 mb-3 ps-xl-4">
            <ComplementaryInfos />
          </div>
        </div>
      </div>
    </div>
    // </Main>
  );
};

export default Profile;
