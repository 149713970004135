import React from 'react'
import DernieresOffresCardItem from './DernieresOffresCardItem'
import ResourceDataMap from './shared/ResourceDataMap'

/**
 * @param {currentItems:array} param0 
 * @returns 
 */

// ! ResourceDataMap permet de mapper n'importe quelle liste
// ! DerniersOffresDataMapper permet de mapper les offres
// ! DernieresOffresCardItem est le cardItem qui affiche une offre

const DerniersOffresDataMapper = ({ currentItems }) => {
    return (
        <>
            <ResourceDataMap
                resourceItem={DernieresOffresCardItem}
                resourceName="offre"
                resourceData={currentItems}
            />
        </>
    )
}

export default DerniersOffresDataMapper