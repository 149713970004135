import React, { useState } from 'react'
import EntreprisesCards from './components/EntreprisesCards'
import CandidatureSpontane from './components/CandidatureSpontane'
// import { Link } from 'react-router-dom';

const EntrepriseDataMap = ({ currentItems }) => {
    const [openCandidatureSpontaneeModal, setopenCandidatureSpontaneeModal] = useState(false)
    const [selectedEntrepriseId, setSelectedEntrepriseId] = useState(null)
    const openCandidatureSpontaneModal = (entreprise_id) => {
        setSelectedEntrepriseId(entreprise_id)
        setopenCandidatureSpontaneeModal(true)
    }
    return (
        <>
            {
                openCandidatureSpontaneeModal && <CandidatureSpontane
                    show={openCandidatureSpontaneeModal}
                    close={() => setopenCandidatureSpontaneeModal(false)}
                    entreprise_id={selectedEntrepriseId}
                />
            }
            <div className="row-entreprise gap-4">
                {currentItems.map((item, index) => (
                    <EntreprisesCards
                        openCandidatureSpontaneModal={openCandidatureSpontaneModal}
                        key={index}
                        description={item?.description}
                        name={item?.user?.name}
                        id={item?.id}
                        secteur={item?.secteur}
                        banner={`${process.env.REACT_APP_BASE_URL}entreprisesBannieres/${item?.banniere}`}
                        logo={`${process.env.REACT_APP_BASE_URL}entreprisesLogos/${item?.logo}`}
                    />
                ))}
            </div>
        </>

    )
}

export default EntrepriseDataMap