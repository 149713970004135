import React from "react";
import { MdEdit } from "react-icons/md";
import { monthYearFormatter } from "../utils/timeFormatters";
// import { IoSchoolSharp } from 'react-icons/io'
import { FaSchool } from "react-icons/fa";

// !editFormation vient du composant cloner
const FormationListItem = ({ formation, editFormation }) => {
  const {
    name: formationName,
    etablissement,
    date_debut,
    date_fin,
  } = formation;
  return (
    <div className="row mb-4 white-bg px-2 py-3 mx-auto formation-card">
      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-7">
        <div className="d-flex gap-2">
          <div className="school-square d-flex justify-content-center align-items-center rounded">
            <FaSchool color="white" size={30} />
          </div>
          <div>
            <h3>{etablissement}</h3>
            <p className="m-0 fs-6">{formationName}</p>
            <p style={{}} className="mt-4 date-time">{`${monthYearFormatter(
              date_debut
            )} - ${
              date_fin !== null ? monthYearFormatter(date_fin) : "aujourd'hui"
            }`}</p>
          </div>
        </div>
      </div>

      {editFormation && (
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 d-flex align-items-start justify-content-end mt-2 mt-sm-0 mt-xl-0 mt-lg-0 mt-md-0">
          <button
            className="btn btn-edit"
            onClick={() => editFormation(formation)}
          >
            <MdEdit size={18} />
          </button>
        </div>
      )}
    </div>
  );
};

export default FormationListItem;
