import React from "react";

const TypeBadge = ({ text, bgCOlor, onClick }) => {
  return (
    <span
      style={{
        borderRadius: "3px",
        backgroundColor: bgCOlor,
        fontSize: "13px",
        lineHeight: "14px",
        fontWeight: "bold",
      }}
      className="p-1 text-light ms-3"
      onClick={onClick}
    >
      {text}
    </span>
  );
};

export default TypeBadge;
