import React from 'react';
// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts';
import { useGetEntrepriseKpiQuery } from '../../../redux/slices/entreprises/entreprisesSlice';
import { useEntreprise } from '../../../hooks/useEntreprise';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
// var CanvasJSReact = require('@canvasjs/react-charts');
 
// var CanvasJS = CanvasJSReact.CanvasJS;

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const GraphEntreprise = () => {
    const { entreprise } = useEntreprise()

    const { data } = useGetEntrepriseKpiQuery({ id_entreprise: entreprise?.id })

    const experiencesData =data?.candidaturesbyExperiences .map(item => ({
        labels: item?.nb_an_experiences?.toString()+' années', // Convert to string if necessary
        total: item?.total
    }))
    const labelsp = experiencesData?.map(item => item.labels);
    const totalsp = experiencesData?.map(item => item.total);
    const pieexperience = {
        labels: labelsp,
        datasets: [
          {
            label: '# of Candidats',
            data: totalsp,
            backgroundColor: [
              '#43a047 ',
              '#fb8c00',
              '#d81b60 ',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              '#43a047 ',
              '#fb8c00',
              '#d81b60 ',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
    const niveauData =data?.candidaturesbyNiveau?.map(item => ({
        labels: item?.niveau_etude?.toString(), // Convert to string if necessary
        total: item?.total
    }))
    const labels = niveauData?.map(item => item.labels);
    const totals = niveauData?.map(item => item.total);
    // Chart Js
     const pieniveau = {
        labels: labels,
        datasets: [
          {
            label: '# of Candidats',
            data: totals,
            backgroundColor: [
                '#43a047 ',
                '#fb8c00',
                '#d81b60 ',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                '#43a047 ',
                '#fb8c00',
                '#d81b60 ',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };

  
       
    
		return (
		<div>
            <div className='row mt-3'>
                <div className='col-md-6'>
                <Pie data={pieexperience} />

        </div>
            <div className='col-md-6'>
            <Pie data={pieniveau} />

			</div>
            </div>
        </div>
		);
    }

export default GraphEntreprise;     