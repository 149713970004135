import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonLoading from "../../../components/ButtonLoading";
import CustomModal from "../../../components/CustomModal";
import Input from "../../../components/shared/Input";
import TextArea from "../../../components/shared/TextArea";
import { useEntreprise } from "../../../hooks/useEntreprise";
import { useEditMutation } from "../../../redux/slices/entreprises/domainesSlice";
import { toastAlertError, toastAlertSuccess } from "../../../utils/toastAlerts";

const EditEntrepriseDomaine = ({ show, close, selectedDomaine }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [editEntrepriseDomaine] = useEditMutation();
  const { entreprise } = useEntreprise();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...selectedDomaine,
    },
    values: {
      ...selectedDomaine,
    },
  });
  const editEntrepriseDomaines = (data) => {
    data = { ...data, entreprise_id: entreprise.id };
    setIsAdding(true);
    editEntrepriseDomaine({ data, domaine_id: selectedDomaine.id })
      .then((res) => {
        if (res.data.success) {
          toastAlertSuccess("Domaine modifié");
          close();
        }
      })
      .catch((err) => {
        toastAlertError("Use erreur est survenue lors de l'ajout");
      })
      .finally(() => {
        setIsAdding(false);
      });
  };
  return (
    <CustomModal
      modalTitle="Ajoutez un domaine"
      show={show}
      handleClose={() => {
        close();
        reset();
      }}
    >
      <form onSubmit={handleSubmit(editEntrepriseDomaines)}>
        <Input
          type="text"
          placeholder="Ex: Design"
          label="Nom domaine"
          register={{ ...register("name", { required: "Champ obligatoire" }) }}
          error={errors.name?.message}
          required
          htmlFor="name"
          minLength="3"
          maxLength="90"
          name="name"
        />
        <TextArea
          minLength="10"
          maxLength="500"
          placeholder="Description"
          label="Description"
          htmlFor="description"
          register={{
            ...register("description", { required: "Champ obligatoire" }),
          }}
          error={errors.description?.message}
        />
        <ButtonLoading
          loading={isAdding}
          text="Ajouter"
          className="btn-apply w-100 mt-3"
        />
      </form>
    </CustomModal>
  );
};

export default EditEntrepriseDomaine;
