import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = (props) => {
  const { show, handleClose, size, modalTitle, centered = true } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ fontSize: "18px" }}
          id="contained-modal-title-vcenter"
        >
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default CustomModal;
