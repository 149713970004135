import DOMPurify from 'dompurify'
import React from 'react'

const Wysiwyg = ({ contenu }) => {
    const sanitizedData = (contenu) => ({
        __html: DOMPurify.sanitize(contenu)
    })
    return (
        <div style={{ fontWeight: '400' }} dangerouslySetInnerHTML={sanitizedData(contenu)}></div>
    )
}

export default Wysiwyg