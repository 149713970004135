import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import { PersistGate } from 'redux-persist/integration/react';
import App from "./App";
import "./fonts/ProximaNova/ProximaNova-Regular.ttf";
import { store } from "./redux/store";
import "./styles/styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //   <React.StrictMode>
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </PersistGate> */}
  </Provider>
  //   </React.StrictMode>
);
