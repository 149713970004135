import React from "react";
import styled from "styled-components";
import ResourceDataMap from "./ResourceDataMap";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  overflow-y: auto;
  padding: 20px 5px;
`;

const GridListe = ({
  isLoading,
  resourceItem: ResourceItem,
  resourceName,
  resourceData = [],
  emptyMessage,
}) => {
  return (
    <>
      {resourceData.length ? (
        <Container>
          <ResourceDataMap
            resourceData={resourceData}
            resourceItem={ResourceItem}
            resourceName={resourceName}
          />
        </Container>
      ) : (
        <p>{emptyMessage}</p>
      )}
    </>
  );
};

export default GridListe;
