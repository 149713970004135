import React from "react";
import PaginatedItems from "../../components/shared/PaginationItems";
import TitleH2 from "../../components/shared/TitleH2";
import { useGetAllEtablissementsQuery } from "../../redux/slices/etablissements/etablissementsSlice";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
import FadeTransition from "../landingPage/components/Fadetransition";
import EtablissementDataMapLanding from "./EtablissementDataMapLanding";

const ListeEtablissements = () => {
  const { data: etablissements, isLoading: loadingEtablissements } =
    useGetAllEtablissementsQuery();
  return (
    <FadeTransition in={true}>
      {loadingEtablissements ? (
        <div className="d-flex align-items-center justify-content-center mt-2">
          <SpinnerLoaderSearch />
        </div>
      ) : etablissements?.length >= 1 ? (
        <div className="mt-3">
          <TitleH2>Liste des etablissements</TitleH2>
          <PaginatedItems itemsPerPage={15} ressourceData={etablissements}>
            <EtablissementDataMapLanding />
          </PaginatedItems>
        </div>
      ) : (
        <p className="text-center">
          Aucun etablissements disponible pour le moment.
        </p>
      )}
    </FadeTransition>
  );
};

export default ListeEtablissements;
