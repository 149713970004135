import React from 'react'
import { BiTrash } from 'react-icons/bi';
// import { MdEdit } from 'react-icons/md';
// import { useDispatch, useSelector } from 'react-redux';
// import { setCurrentDomaineId } from '../../../redux/reducers/candidatureReducer';

const EntrepriseDomaineCardItem = ({ domaine, editDomaine, deleteDomaine, setCurrentDomainesFunc }) => {
    const { name, description } = domaine
    return (
        <div
            // style={{ cursor: 'pointer', backgroundColor: currentDomaineId === id ? '#cfd6d4b7' : null }}
            // onClick={() => {
            //     dispatch(setCurrentDomaineId(id))
            //     setCurrentDomainesFunc(candidats)
            // }}
            title={description}
            className='p-3 card fs-6 fw-bold d-flex justify-content-between flex-row-reverse align-items-center'
        >
            <div className='text-end'>
                <button
                    className="btn btn-edit me-1"
                    onClick={(e) => {
                        e.stopPropagation()
                        deleteDomaine(domaine)
                    }}
                >
                    <BiTrash color='red' size={15} />
                </button>
                {/* <button
                    className="btn btn-edit"
                    onClick={(e) => {
                        e.stopPropagation()
                        editDomaine(domaine)
                    }}
                >
                    <MdEdit size={15} />
                </button> */}
            </div>
            <p className='fw-bold mt-2'>{name}</p>
        </div>
    )
}

export default EntrepriseDomaineCardItem