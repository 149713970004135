import React, { cloneElement } from "react";
import { MdOutlineLocalOffer, MdOutlinePeopleOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoaderSpinner from "../../components/LoaderSpinner";
import ResourceDataProfil from "../../components/ResourceDataProfil";
import NavigateBack from "../../components/shared/NavigateBack";
import ResourceDataMapperWithEmptyMessage from "../../components/shared/ResourceDataMapperWithEmptyMessage";
import { DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH } from "../../constants/routes_path";
import { setSelectedOffreCandidature } from "../../redux/reducers/offresCandidature";
import { useGetEntrepriseDetailsQuery } from "../../redux/slices/entreprises/entreprisesSlice";
import CandidatsListe from "./components/CandidatsListe";
import EntrepriseOffreCard from "./components/EntrepriseOffreCard";

const DetailsEntreprise = () => {
  const { id } = useParams();
  const { data: entreprise, isLoading } = useGetEntrepriseDetailsQuery({
    id_entreprise: id,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToCandidatureDetails = (offre) => {
    dispatch(setSelectedOffreCandidature(offre));
    // !redirection
    navigate(DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH);
  };

  const banniere = `${process.env.REACT_APP_BASE_URL}entreprisesBannieres/${entreprise?.data[0]?.banniere}`;
  const name = entreprise?.data[0].user.name;
  const secteur = entreprise?.data[0].secteur;
  const effectif = entreprise?.data[0].effectif;
  const offres = entreprise?.data[0].offres;
  const candidats = offres
    ?.map((offre) => {
      return {
        offreId: offre.id,
        titre: offre.titre,
        candidats: offre.candidats,
      };
    })
    .reverse();

  // linear-gradient(rgba(200, 200, 200, 0), rgba(255, 255, 255, 0), rgba(200, 200, 200, 0.8), rgb(180, 180, 180)),

  const ClonedEntrepriseOffreCard = ({ offre }) =>
    cloneElement(<EntrepriseOffreCard offre={offre} className="col-12 p-0" />, {
      navigateToCandidatureDetails,
    });

  return (
    <div className="bg-white p-2 p-sm-3 mb-5">
      <div className="mb-2">
        <NavigateBack />
      </div>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgb(255, 255, 255)), url(${banniere})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "50vh",
        }}
        className="shadow-sm"
      >
        <div
          style={{ color: "#00172d" }}
          className=" p-3 d-flex flex-column justify-content-end h-100"
        >
          <h1 className="fs-2">
            {!isLoading ? name ?? "" : <LoaderSpinner />}
          </h1>
          {effectif ? (
            <p className="d-flex align-items-center gap-1">
              <MdOutlinePeopleOutline size={25} />
              <span className="fw-bold">{effectif} Employés</span>
            </p>
          ) : null}

          {secteur ? (
            <p>
              <MdOutlineLocalOffer size={20} />
              <span className="fw-bold">{secteur}</span>
            </p>
          ) : null}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6 col-12 pt-3">
          <h3 className="fs-5 fw-bold">Liste offres</h3>
          <div
            style={{ height: "70vh", overflow: "auto" }}
            className="pe-sm-3 pe-2 py-4"
          >
            <ResourceDataMapperWithEmptyMessage
              isLoading={isLoading}
              resourceItem={ClonedEntrepriseOffreCard}
              resourceName="offre"
              resourceData={offres}
              emptyMessage="Pas encore d'offres"
            />
          </div>
        </div>
        <div className="col-md-6 col-12 pt-3">
          <h3 className="fs-5 fw-bold">Candidats</h3>
          {/* <ResourceDataProfil /> */}
          {candidats?.map((candidat) => (
            <ResourceDataProfil
              key={candidat.offreId}
              accordion
              title={candidat.titre}
              id={candidat.offreId}
              resourceData={[...candidat.candidats]}
            >
              <CandidatsListe
                className="col-12"
                ressourceData={[...candidat.candidats]}
              />
              <p>Pas de candidat</p>
            </ResourceDataProfil>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailsEntreprise;
